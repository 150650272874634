import React, { Component } from "react";
import { Link } from "react-router-dom";
import {CreateUser} from "./CreateClient";
// import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import {Form,  Col} from 'react-bootstrap';

import ReactTable from "react-table";
import "react-table/react-table.css";
import "../css/rrr.css";
import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import config from "../config";
import getUsers, {getSubscriptionPlans, getData} from "./SharedFunctions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LeftMenu,{PageHeader} from '../components/leftmenu';
import AddCircle from '@material-ui/icons/AddCircle';
import MUIDataTable from "mui-datatables";
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import PropTypes from 'prop-types';
import { Button, MenuItem, TextField} from '@material-ui/core';

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: red[800],
    },
  },
  menu: {
    width: 300,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    backgroundColor: "white"
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: "#f7f7f7"
  },
  table: {
    marginTop: theme.spacing.unit * 2,
    // width: "90%"
  },  
  title: {
    color: "#00956e",
    marginTop: theme.spacing(2),
    fontWeight:"fontWeightBold",
    fontFamily: "AvenirNextLTPro",
    height: "24px",
    fontSize: "14px",
  }, 
  title_grey: {
    color:grey[400],
    fontWeight:"fontWeightLight",
    marginTop: theme.spacing(2),
    marginRight: "40px",
    fontFamily: "Ebrima",
    fontSize: "14px",
    fontStyle:"italic",
  }, 
  submit: {
    //margin: theme.spacing(3, 0, 2),
    backgroundColor:"#1782c5",
    marginTop: "18px", 
    height:"53px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
});




class Admin extends Component {
	
	
	constructor(props) {
    super(props); 
    const temp = {...props}

		this.state = {
      isLoading: true,
      hideForm : true,
      selected : -1,
      isSRCLUser: props.isSRCLUser,
      clientid: props.clientid,
      //clientColor: this.props.siteConfig.srclColor,

      email: "",
      firstname: "",
      lastname: "",
      emailInvalid: false,

      newUser: null,
      userCreated: false,

      usersDataSource : [],
      pkgDataSource : [],
			dataSource: [{clientid:0, clientname:"loading"}],
			search: '',
			direction : {
				clientname: 'asc'
			}
		}
    
    //console.log(temp)
	}



	async componentDidMount() {
    
      await getUsers(-1,"")
      .then(json => this.setState({ usersDataSource: json[0], isLoading: false}));

      await getSubscriptionPlans()
      .then(json => this.setState({ pkgDataSource: json[0], isLoading: false}));
 
      window.scrollTo(0, 0);
	}

  handleClick = event => {
    event.preventDefault();
    this.setState({
      hideForm : false,
      firstname:'',
      lastname:'',
      email:'',
      emailInvalid:false
    });
  }
  handleCancel = event => {
    this.setState({
      hideForm : true
    });
  }

  validateForm = event => {
    return (
      this.state.email.length > 0 &&
      this.state.firstname.length > 0 &&
      this.state.lastname.length > 0 
    );
  }

  
	handleChange = event => {
		this.setState({
		  [event.target.id]: event.target.value
		});
	}


	//method to handle signUp submit button
	handleSubmit = async event => {
    //handleSubmit = event => {
    event.preventDefault();

    if(!new RegExp(/\S+@\S+\.\S+/).test(this.state.email))
    {
      this.setState({emailInvalid: true})
      return
    }

        //this.setState({ isLoading: true });
    const username = this.state.email.toLowerCase();

    try {
      const newUser =  await Auth.signUp({
        username: username,
        email:username,
        password: config.TEMP_PWD,
        attributes: {
          name: this.state.firstname +  ' ' + this.state.lastname
        }
      });

      //success

      //create DB user
    const body = {
        "procname" : "procUserInsert",
        "paramcount": "15", 
        "param1": username, //username
        "param2" : "-1", //clientid ext
        "param3" : this.state.firstname, 
        "param4" : this.state.lastname, 
        "param5" : "", //addr1
        "param6" : "", //addr2
        "param7" : "", //city
        "param8" : "", //st
        "param9" : "", //zip
        "param10" : "US", //country
        "param11" : "", //ph
        "param12" : "", //altph
        "param13" : "0", //isprimary,
        "param14" : "1", //isactive
        "param15" : "IN" //pwdstatus
        }
     
      await getData(body)
      .then(this.setState({hideForm : true, userCreated: true}))
      .then(await getUsers(-1,""));

      //alert("User " + this.state.email + " has been created.  **CHANGE THIS ALERT TO A TOAST**");
      toast.success("User " + username + " has been created.", { position: toast.POSITION.TOP_RIGHT });
    } 
		catch (e) {
      //console.log('error' + JSON.stringify(e));
      toast.error("Error creating user " + username + ".  " + e.message, { position: toast.POSITION.TOP_RIGHT });
      //alert("Error creating user " + this.state.email + ".  " + e.message + ".  **CHANGE THIS ALERT TO A TOAST**");
		}
		this.setState({ isLoading: false });
  }
  

 	render() {
    const { isLoading} = this.state;
    let {usersDataSource,pkgDataSource} = this.state;

    const { classes } = this.props;

    const columnsUser = [
      {
        label: 'Name',
        name: 'FullName',
        options: {
          //filter: true,
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },
          customFilterListOptions: { render: v => `Name: ${v}` },                                    
        },
      },
      {
        label: 'Email',
        name: 'UserName',
        options: {
          sortDirection: "asc",
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },     
          customFilterListOptions: { render: v => `Email: ${v}` },                 
      },
    },
      {
        label: 'Active',
        name: 'Active',
        options: {
          filter: false,
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log(tableMeta)
            return (
              <div>{(value === 'Active'? 'YES': 'NO')}</div>
            );              
        },               
        },
      },
      {
        label: 'Status',
        name: 'PwdStatus',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },     
          customFilterListOptions: { render: v => `Status: ${v}` }, 
          filterOptions: {
            names: ['Confirmed','Invited'],
            logic(status, filterVal) {
              const show =
                (filterVal[0]==='Confirmed' && status ==='CN' ) ||
                (filterVal[0]==='Invited' && status === 'IN' );
              return !show;                
            },                
          },                                
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log(tableMeta)
            return (
              <div>{(value === 'IN'? 'Invited': 'Confirmed')}</div>
            );              
        },           
        },
      },   
      {
        label: 'Primary',
        name: 'IsPrimary',
        options: {
          filter: false,
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log(tableMeta)
            return (
              <div>{(value === 1? 'YES': 'NO')}</div>
            );              
        },           
        },
      },                
    ];    

    const options =  {
      filter: true,
      filterType: 'multiselect',
      responsive: 'stacked', //scroll, 'scrollMaxHeight'
      page: 0,
      rowsPerPage: 5,
      rowsPerPageOptions:  [5, 10, 20, 25],
      //searchText: this.state.searchText,
      // searchOpen: true,
      //search:true,
      searchPlaceholder: 'Search...',
      selectableRows: false,
      disableToolbarSelect: true,
      print: false,
      download: false,
      viewColumns: false,
      customToolbar: null,
    }    
      
    const optionPkg = {...options}
    optionPkg.search=false;
    optionPkg.filter=false;

        const columnsPkg = [
          {
            label: 'Name',
            name: 'PlanName',
            options: {
              //filter: true,
              setCellHeaderProps: (value) => {
                return {
                    style: {fontWeight: "bold"}
                };
              },                                    
            },
          },
          {
            label: 'Max Messages',
            name: 'MaxMessages',
            options: {
              sortDirection: "asc",
              setCellHeaderProps: (value) => {
                return {
                    style: {fontWeight: "bold"}
                };
              },                
          },
        },
        {
          label: 'Price',
          name: 'Price',
          options: {
            sortDirection: "asc",
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            },                
        },
      },        
          {
            label: 'Overage Price Per Msg',
            name: 'OveragePricePerMsg',
            options: {
              setCellHeaderProps: (value) => {
                return {
                    style: {fontWeight: "bold"}
                };
              },                
              customBodyRender: (value, tableMeta, updateValue) => {
                //console.log(tableMeta)
                return (
                  <div>{('$' + value)}</div>
                );              
            },               
            },
          },
        ];          

		return (
      
      <div style={{width:"100%", borderStyle:"solid", borderColor:this.props.siteConfig.srclColor, borderWidth:"0px"}}>

        <LeftMenu selected="admin" confirmationUnsaved_LM={(tmp) => event => {return true}} clientid={this.state.clientid} props={this.props}/>

        <div className="MainInnerWrapperDiv">
        <PageHeader title="Admin" username={this.props.username}/>       

              <div style={{float:"left", width : "70%", paddingLeft:"30px" }}>
                <label class="subtitle_page">Sedgwick Users</label>
                <p/>
                <Link to = {{pathname: "admin",  state: {clientid: this.state.clientid}}} onClick={this.handleClick} style={{display: 'flex',fontSize:"15px",alignItems: 'center'}} >
                        <AddCircle style={{color: "#1782c5",paddingRight:"3px" }}/>Add Additional User</Link> <p/>                   
                {(!this.state.hideForm) && userForm(this,classes)}
                <MUIDataTable className={classes.table} data={usersDataSource} columns={columnsUser} options={options} />
              </div>
            
              <div style={{float:"left", width : "65%", paddingLeft:"30px"}}>
                <label class="subtitle_page">Packages</label>
                <p/>
                <MUIDataTable className={classes.table} data={pkgDataSource} columns={columnsPkg} options={optionPkg} />
              </div>
        </div>
     </div>

		);
	  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Admin);


const  userForm = (caller,classes) => 
<form className={classes.container} onSubmit={caller.handleSubmit} noValidate autoComplete="off">
<div style={{width:"90%", backgroundColor: "#f7f7f7;"}}>
    <label class="title_add_section">New SRCL User</label>
    </div> 
    <div>
      <TextField
      id="firstname"
      label="First Name"
      required
      value={caller.state.firstname}
      onChange={caller.handleChange}
      className={classes.textField}
      margin="normal"
      placeholder="[first name]"
      variant="outlined"
      InputLabelProps={{shrink: true}}
      />
      <TextField
      id="lastname"
      label="Last Name"
      required
      value={caller.state.lastname}
      onChange={caller.handleChange}
      className={classes.textField}
      margin="normal"
      placeholder="[last name]"
      variant="outlined"
      InputLabelProps={{shrink: true}}
      />
      <TextField
        id="email"
        label="Email"
        required
        error={caller.state.emailInvalid}
        helperText={caller.state.emailInvalid ?'Invalid email format':''}  
        className={classes.textField}
        value={caller.state.email}
        onChange={caller.handleChange}
        style={{width:"220px"}}
        margin="normal"
        placeholder="[email]"
        variant="outlined"
        InputLabelProps={{shrink: true}}
      />

      <Button
          //type="submit"
          style={{backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"}}
          variant="outlined" //color="secondary"     
          className={classes.submit}
          alignItems="flex-end"
          onClick = {caller.handleCancel}      
      >
        Cancel
      </Button>
      <Button
          type="submit"
          alignItems="flex-end"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!caller.validateForm()}
        >
        Add
      </Button>     
</div>      
</form>