import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function AuthenticatedRoute({ component: C, props: appProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        appProps.isAuthenticated
          ? (
              appProps.isSRCLUser//clientid === -1
              ? <C {...props} {...appProps} />
              : <Redirect to='unauthorized' />
            )
          : appProps.forcePasswordReset ? <Redirect to={`/changepassword`} /> : <Redirect to={`/login`} />}
    />
  );
}