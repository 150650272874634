import React, { Component } from "react";
import { Link, BrowserRouter as Router, Route } from "react-router-dom";
//import {Form, FormControl, FormGroup, ColHelpBlock,ControlLabel} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
//import "./CreateUser.css";
import "../css/rrr.css";
import { Auth } from "aws-amplify";
import AWS from 'aws-sdk';
import config from "../config";
import ReactTable from "react-table";
import ReactTooltip from 'react-tooltip';
import "react-table/react-table.css";
import queryString from 'query-string';
import { container } from "@aws-amplify/ui";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Switch from "react-switch";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ManageClient.css";
import getUsers, {getData} from "./SharedFunctions";
import Select from 'react-select';
import { join } from "path";
import Modal from 'react-awesome-modal';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import LeftMenu,{PageHeader} from '../components/leftmenu';
import AddCircle from '@material-ui/icons/AddCircle';
import Save from '@material-ui/icons/Save';
import Refresh from '@material-ui/icons/Refresh';
import MUIDataTable from "mui-datatables";
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import PropTypes from 'prop-types';
import { Button, MenuItem, TextField} from '@material-ui/core';
// import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { s3Get } from "../libs/awsLib";

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: red[800],
    },
  },
  menu: {
    width: 300,
  },
  textField: {
    //marginLeft: theme.spacing(1),
    //marginRight: theme.spacing(1),
    width: 305,
    //backgroundColor: "white"
    color:"black"
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: "#f7f7f7"
  },
  table: {
    marginTop: theme.spacing.unit * 2,
    // width: "90%"
  },  
  title: {
    color: "#00956e",
    marginTop: theme.spacing(2),
    fontWeight:"fontWeightBold",
    fontFamily: "AvenirNextLTPro",
    height: "24px",
    fontSize: "14px",
  }, 
  title_grey: {
    color:grey[400],
    fontWeight:"fontWeightLight",
    marginTop: theme.spacing(2),
    marginRight: "40px",
    fontFamily: "Ebrima",
    fontSize: "14px",
    fontStyle:"italic",
  }, 
  submit: {
    //margin: theme.spacing(3, 0, 2),
    backgroundColor:"#1782c5",
    marginTop: "18px", 
    height:"45px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
});

class Contacts extends Component {
  
  constructor(props) {
    super(props);

    const prevList = 0;

    this.state = {
      checked: false,
      isLoading: false,
      hidePreview : 'none',
      isPlanFormLoading : false,
      dataSource: [],
      attrSource:[],
      listsDataSource: [],
      previewDataSource: [],
      plan: "",
      logo: null,
      clientid: null,
      logoURN:'0',
      visible : false,
      text: "my hair",
      attr1:"",
      attr2:"",
      attr3:"",
      attr4:"",
      attr5:"",
      refnum:"",
      listName:"",
      hasChanged:false                        
    }
       //   this.handleChange = this.handleChange.bind(this);
           
}

openModal() {
  this.setState({
      visible : true
  });
}

closeModal =async ()=> {
  //save Attributes
  //alert(this.state.text);
  await this.getClientlanguages()
  .then(()=>  
  this.setState({visible : false}))
  
} 

// goToCreateNewList =() => {
//   this.props.history.push({pathname: 'CreateContactList',state: {clientid: this.state.clientid}});
// }

handleChange = event => {

  this.setState({
    [event.target.id]: event.target.value,
    hasChanged:true
  });
}

updateAttributs=async ()=> {


  let body = {
    "procname" : "procClientLanguageSave",
    "paramcount": "9", 
    "param1": this.state.clientid,
    "param2": 'en',    
    "param3": this.state.refnum,
    "param4": this.state.attr1,    
    "param5": this.state.attr2,
    "param6": this.state.attr3,    
    "param7": this.state.attr4,
    "param8": this.state.attr5,    
    "param9": this.props.username
  }


  await getData(body)
  .then(()=>this.closeModal());

}

handleSwitchChange = (checked,event,id) => {
  let listname = this.state.listsDataSource.filter(r=> {return r.ListID === parseInt(id)})[0].ListName;

  confirmAlert({
    message : `Are you sure you want to ${!checked ? 'de' : ''}activate List ${listname} ?`,
    buttons: [
      {
        label: 'Cancel',
      },
      {
        label: 'Yes',
        onClick: () => this.updateList(id, checked, listname)
      }
    ]
  });  
}

updateList = async (id, checked,listname) =>{
 
  let {listsDataSource} = this.state

  let body = {
    "procname" : "procListUpdateStatus",
    "paramcount": "4", 
    "param1": id,
    "param2": this.props.username,    
    "param3": checked ? 'A' : 'D',
    "param4": 'NUL'
  }

  await getData(body)
  .then(e=> {
    listsDataSource.filter(r=> {return r.ListID === parseInt(id)})[0].ListStatus= checked ? 'Active' : 'Deactivated'
    this.setState({listsDataSource});
    toast.success(`Contact list ${listname} is ${!checked ? 'de' : ''}activated`, {position: toast.POSITION.TOP_RIGHT})
  });    

  //this.state.usersDataSource.filter(u=>{return u.UserName === id;}).IsActive.data[0] = !checked ? 0 : 1
}

deleteList = listID => e => {

 // let listID = e.target.id
  let obj = this.state.listsDataSource.filter(r=> {return r.ListID === parseInt(listID)})[0];

  if(obj.EventName !== null)
  {
    toast.error(`Contact list ${obj.ListName} has been used on the campaign ${obj.EventName} and cannot be deleted `, {
      position: toast.POSITION.TOP_RIGHT})   
    return
  }
  
  //alert(' delete list ' + listID);

  confirmAlert({
    message : `Are you sure you want to delete list ${obj.ListName} ?`,
    buttons: [
      {
        label: 'Cancel',
      },
      {
        label: 'Yes',
        onClick: async () => {
          let body = {
            "procname" : "procListDelete",
            "paramcount": "2", 
            "param1": listID,
            "param2": this.props.username
          }
        
          await getData(body)
          .then(await this.getContactLists())
          .then(()=>toast.info(`Contact list ${obj.ListName} has been deleted `, {
            position: toast.POSITION.TOP_RIGHT})   )
        }
      }
    ]
  });  
}

previewList = listID => async e => {
  //tdProps.onClick();
//debugger;
  this.setState({hidePreview : 'none', previewDataSource: []});
  
  //alert(' preview list ' + e.target.id );

  if (parseInt(this.prevList) === parseInt(listID))
  {
   //document.getElementById(e.target.id).src = require('../images/round_remove_circle_black_18dp.png');
    this.prevList = 0;
    return;
  }
  

  this.prevList = listID;

//procContactSelect
let body = {
  "procname" : "procContactSelect",
  "paramcount": "2", 
  "param1": listID,
  "param2": true //set limit of 25
}
const response = await getData(body)
.then(json => this.setState({ previewDataSource: json[0]}))
.then(()=>{
  //debugger;
    if(this.state.previewDataSource.length > 0)
    {
      this.setState({hidePreview : 'block',listName:this.state.previewDataSource[0].ListName})
    }
})
}


downloadFullList = flistID => async e => {
//debugger;
  //const flistID = e.target.id;
  let obj = []

  let body = {
    "procname" : "procContactSelect",
    "paramcount": "2", 
    "param1": flistID,
    "param2": false //get the entire list
  }

  const response = await getData(body)
  .then(json => obj = json[0])
  .then(()=>{
    //debugger;
    //let obj = [...this.state.errorsDataSource]
    let csvContent =''

    if(obj !== null)
    {
      csvContent += Object.keys(obj[0]) + ("\n") 
      obj.forEach(e => {
        csvContent += Object.values(e) + ("\n")       //  "\t" or &nbsp; to KEEP leading zeros
       }); 

       const element = document.createElement("a");
       const file = new Blob([csvContent.toString("utf8")], {type: 'text/plain; charset=UTF-8'});//new Blob([csvContent], {type: 'text/plain'});
       element.href = URL.createObjectURL(file);
       element.download = "List"+ flistID + "_FullFile.csv";
       document.body.appendChild(element);
       element.click();
    }
  })
 ;  

}

downloadUploadedErrors = listID => async e => {

  // console.log(e.target)
  //alert(' download UploadedErrors list ' + e.currentTarget.htmlFor);
 // let listID = e.currentTarget.htmlFor;

  let body = {
    "procname" : "procContactStagingSelectFailed",
    "paramcount": "1", 
    "param1": listID
  }

  const response = await getData(body)
  .then(json => this.setState({ errorsDataSource: json[0]}))
  .then(()=>{
    //debugger;
    let obj = [...this.state.errorsDataSource]
    let csvContent =''

    if(obj !== null)
    {
      obj.forEach(e => {
        csvContent += Object.values(e) + ("\n")       //  "\t" or &nbsp; to KEEP leading zeros
       }); 

       const element = document.createElement("a");
       const file = new Blob([csvContent.toString("utf8")], {type: 'text/plain; charset=UTF-8'});//new Blob([csvContent], {type: 'text/plain'});
       element.href = URL.createObjectURL(file);
       element.download = "List"+ listID + "_ErrorFile.csv";
       document.body.appendChild(element);
       element.click();
    }
  })
 ;  

}

async handleDownloadClick() {
  //   alert('clicked');

   const template = await s3Get(config.s3.ContactTemplateFile);
   const element = document.createElement("a");
   const file = new Blob([template.Body.toString("utf8")], {type: 'text/plain; charset=UTF-8'});
   element.href = URL.createObjectURL(file);
   element.download = "ContactListTemplate.csv";
   document.body.appendChild(element);
   element.click();

 }


 async refreshView (){
  await this.getContactLists()
 }
 
	//method to handle signUp submit button
	async componentDidMount() {

    // console.log('props');
    // console.log(this.props);
    //debugger;
    if(this.props.location.state!==undefined)
      this.state.clientid = this.props.location.state.clientid;
    else
      {
        this.state.clientid = this.props.clientid;
    
        if(this.state.clientid === -1)
          {
            this.props.history.push({pathname: './clients',state: {clientid: -1, email: this.props.username}});
            return;
          }

      } 



    let body = {
        "procname" : "procClientSelect",
        "paramcount": "2", 
        "param1": this.state.clientid,
        "param2": ''
    }
    await getData(body)
    .then(json => this.setState({dataSource: json[0], clientName:json[0][0].ClientName, logoURN: json[0][0].LogoURN.length > 0 ? json[0][0].LogoURN : '0'}))
    .then(await this.getContactLists())
    ;
    
    await this.getClientlanguages()
  }


  async getClientlanguages(){

    let body = {
      "procname" : "procClientLanguageSelect",
      "paramcount": "1", 
      "param1": this.state.clientid
    }
    await getData(body)
    .then(json => this.setState({attrSource: json[0]}))
    .then(()=>{
      this.state.attrSource.length > 0 ?
        this.setState({refnum : this.state.attrSource[0].refnum,attr1 : this.state.attrSource[0].attr1,attr2 : this.state.attrSource[0].attr2,attr3 : this.state.attrSource[0].attr3,attr4 : this.state.attrSource[0].attr4,attr5 : this.state.attrSource[0].attr5})
       :  this.setState({attrSource : null})
    });

  }

  async getContactLists(){

    let body = {
      "procname" : "procListSelect",
      "paramcount": "3", 
      "param1": this.state.clientid,
      "param2": '',
      "param3": 0
    }

    await getData(body)
    .then(json => this.setState({ listsDataSource: json[0]}));
  }  


  render() {

    const {dataSource,listsDataSource,previewDataSource} = this.state;

    const { classes } = this.props;

     if(dataSource.length === 0)
      return <div />

    //const newLocal = 16;

    const columns = [
      {//0
        label : "ListFailureReasonID",
        name : "ListFailureReasonID",
        options: {
          display:false,
          filter: false, 
        }
      },    
      {//1
        label : "ListFailureReason",
        name : "ListFailureReason",
        options: {
          display:false,
          filter: false, 
        }
      },
      {//2
        label : "ListID",
        name : "ListID",
        options: {
          display:false,
          filter: false, 
        }
      },          
      {//3
        label: 'Date Uploaded',
        name: 'CreatedOn',
        options: {
          sortDirection: "desc",
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          //filterType:"select",
          customFilterListOptions: { render: v => `Date Uploaded: ${v}` },             
        },
      },
      {//4
        label: 'List Name',
        name: 'ListName',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `List: ${v}` },
        },
      },          
      {//5
        label: 'Intended Delivery Type',
        name: 'ListMessageType',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `List Delivery Type: ${v}` },
        },
      },          
      {//6
        label: 'Status',
        name: 'ListStatus',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },  
         // filterType:"select",         
          customFilterListOptions: { render: v => `Status: ${v}` },//chip display
          customBodyRender: (value, tableMeta, updateValue) => {
            //  console.log(value)
            //  console.log(tableMeta)
            return (
                <span>
                {value !== 'Failed' ? value :
                value === 'Failed' && tableMeta.rowData[0]!==null && tableMeta.rowData[0]!== "ROW"?
                tableMeta.rowData[1] :
                  <b>{value}</b>}
              </span>                       
            );              
        },          
        },
      },          
      {//7
        label: '# of Contacts',
        name: 'Total',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `Contacts: ${v}` },
        },
      },       
      {//8
        label: '# with Mobile',
        name: 'HasPhone',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `Mobile: ${v}` },
        },
      },       
      {//9
        label: '# Unsubscribed',
        name: 'Unsubscribed',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `Unsubscribed: ${v}` },
          //customFilterListOptions: { render: v => `Unsubscribed: ${tableMeta.rowData[5]==='Sms' ? 'NA' : v}` },
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log(tableMeta)
            return (          
              tableMeta.rowData[5]==='Sms' ? 'NA' : value
              );              
          },   
        },
      },  
      // {//10
      //   label: '# Opted In',
      //   name: 'OptedIn',
      //   options: {
      //     setCellHeaderProps: (value) => {
      //       return {
      //           style: {fontWeight: "bold"}
      //       };
      //     },                
      //     customFilterListOptions: { render: v => `OptedIn: ${v}` },
      //   },
      // },  
      {//11
        label: '# Opted Out',
        name: 'OptedOut',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `OptedOut: ${v}` },
          //customFilterListOptions: { render: v => `OptedOut: ${tableMeta.rowData[5]==='Email' ? 'NA' : v}` },
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log(tableMeta)
            return (          
              tableMeta.rowData[5]==='Email' ? 'NA' : value
              );              
          },   
        },
      },  
      {//12
        label: 'Last Campaign Used',
        name: 'EventName',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `Campaign: ${v}` },
        },
      },          
      {//13
        label: 'Errors',
        name: 'FailedCount',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },  
          customFilterListOptions: { render: v => `Errors: ${v}` },  
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log(tableMeta)
            return (
              value!== null?
              <Badge badgeContent={value} max={10000} color="secondary">
              <Save style={{width:"28px", height:"28px",  color: "#1782c5"}} onClick={this.downloadUploadedErrors(tableMeta.rowData[2])}/>
              </Badge>   : <span/>           
              );              
            },                  
          },                
        },     
        {//14
          label: 'Full List',
          name: 'ListID',
          options: {
            filter: false, 
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            },  
            // customFilterListOptions: { render: v => `Errors: ${v}` },  
            customBodyRender: (value, tableMeta, updateValue) => {
              //console.log(tableMeta)
              return (  
                tableMeta.rowData[6] === 'Active' || tableMeta.rowData[6] === 'Deactivated'?
                <Save style={{width:"28px", height:"28px",  color: "#1782c5"}} onClick={this.downloadFullList(value)}/> :
                <span/>           
                );              
              },                  
            },                
          },     
          {//15
            label: 'Delete List',
            name: 'ListID',
            options: {
              filter: false, 
              setCellHeaderProps: (value) => {
                return {
                    style: {fontWeight: "bold"}
                };
              },  
              // customFilterListOptions: { render: v => `Errors: ${v}` },  
              customBodyRender: (value, tableMeta, updateValue) => {
                //console.log(tableMeta)
                return (          
                  tableMeta.rowData[6]  === 'Pending'?
                  <span/> :
                  tableMeta.rowData[11] !==null ?
                  <Switch height={16} width={35} id={value} onChange={this.handleSwitchChange} checked={tableMeta.rowData[6] === 'Active' ? true : false} /> :
                  <Delete style={{width:"25px", height:"25px",  color: "#1782c5"}} onClick={this.deleteList(value)}/>              
                  );              
                },                  
              },                
            },                                                        
            {//16
              label: 'Preview',
              name: 'ListID',
              options: {
                filter: false, 
                setCellHeaderProps: (value) => {
                  return {
                      style: {fontWeight: "bold"}
                  };
                },  
                // customFilterListOptions: { render: v => `Errors: ${v}` },  
                customBodyRender: (value, tableMeta, updateValue) => {
                  //console.log(tableMeta)
                  return (   
                    tableMeta.rowData[6] === 'Active' || tableMeta.rowData[6] === 'Deactivated'?
                    <RemoveRedEye style={{width:"28px", height:"28px",  color: "#1782c5"}} onClick={this.previewList(value)}/> :
                    <span/>            
                    );              
                  },                  
                },                
              },     
    ];    

    const options = {
      filter: false,
      filterType: 'multiselect',
      responsive: 'stacked', //scroll, 'scrollMaxHeight'
      page: 0,
      rowsPerPage: 5,
      rowsPerPageOptions:  [5, 10, 20, 25],
      searchText: this.state.searchText,
      searchPlaceholder: 'Search...',
      //selectableRows: false,
      selectableRows: 'single',
      //selectableRowsOnClick: true,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        this.handleOptionChange(listsDataSource[rowsSelected[0].dataIndex].ListID)
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
      },         
      textLabels: {
        body: {
          noMatch: "No uploaded lists available",
        },   
      },    
      disableToolbarSelect: true,
      print: false,
      download: false,
      viewColumns: false,
      customToolbar: null,
    }

    const subOptions = options
    subOptions.selectableRows = false;
    subOptions.filter = true
    subOptions.filterType = 'multiselect'
    subOptions.rowsPerPage= 25
    subOptions.responsive= 'stacked' //scroll, 'scrollMaxHeight'

    const subColumns = [
      {
        label : "Email",
        name : "Email",
        options: {
          filter: false, 
        }
      }, 
      {
        label : "Country Code",
        name : "ISO2CountryCode",
        options: {
          filter: false, 
        }
      }, 
      {
        label : "Contact Name",
        name : "ContactName",
        options: {
        //  filter: false, 
        }
      },    
      {
        label : "Mobile ICC",
        name : "CellPhoneICC",
        options: {
          filter: false, 
        }
      },      
      {
        label : "Mobile",
        name : "CellPhone",
        options: {
          filter: false, 
        }
      },      
      {
        label : "Land Line ICC",
        name : "LandLinePhoneICC",
        options: {
          filter: false, 
        }
      },  
      {
        label : "Land Line",
        name : "LandLinePhone",
        options: {
          filter: false, 
        }
      },  
      {
        label : "Customer Reference",
        name : "CustomerRefNumber",
        options: {
          //filter: false, 
        }
      }, 
      {
        label :  "Flex 1",
        name : "ContactAttribute1",
        options: {
         // filter: false, 
        }
      }, 
      {
        label : "Flex 2",
        name : "ContactAttribute2",
        options: {
          //filter: false, 
        }
      }, 
      {
        label : "Flex 3",
        name : "ContactAttribute3",
        options: {
         // filter: false, 
        }
      },   
      {
        label : "Flex 4",
        name : "ContactAttribute4",
        options: {
          //filter: false, 
        }
      },     
      {
        label : "Flex 5",
        name : "ContactAttribute5",
        options: {
          //filter: false, 
        }
      },                                                    
    ]


    return (

     
      <div style={{width:"100%", borderStyle:"solid", borderColor:this.props.siteConfig.srclColor, borderWidth:"0px"}}>

        <LeftMenu selected="contacts" confirmationUnsaved_LM={(tmp) => event => {return true}} clientid={this.state.clientid} props={this.props}/>

        <div className="MainInnerWrapperDiv">
        <PageHeader title="Contacts" username={this.props.username} clientname={this.props.isSRCLUser?this.state.clientName:''}/>          
           <div style={{float:"left", width : "90%", paddingLeft:"30px", paddingTop:"20px" }}>
              <div style={{float:"left", paddingRight:"15px" }}>
                  <Link to = {{pathname: "CreateContactList",  state: {clientid: this.state.clientid}}} style={{display: 'flex',fontSize:"15px",alignItems: 'center'}} >
                        <AddCircle style={{color: "#1782c5",paddingRight:"3px" }}/>Create Contact List</Link>
              </div>
              <div style={{float:"left", paddingRight:"15px"}}>
                  <Link to = {{pathname: "Contacts",  state: {clientid: this.state.clientid}}} onClick={this.handleDownloadClick} style={{display: 'flex',fontSize:"15px",alignItems: 'center'}} >
                        <AddCircle style={{color: "#1782c5",paddingRight:"3px" }}/>Download Template</Link>
              </div>
              <div style={{float:"left"}}>
                  <Link to = {{pathname: "Contacts",  state: {clientid: this.state.clientid}}} onClick={() => this.openModal()} style={{display: 'flex',fontSize:"15px",alignItems: 'center'}} >
                        <AddCircle style={{color: "#1782c5",paddingRight:"3px" }}/>Attributes (client level)</Link>
              </div>
              <div style={{"padding-right":"2%", paddingBottom: "10px", float:"right"}}>
                  <OverlayTrigger placement="right" delay={{ show: 150, hide: 300 }} overlay={<Tooltip>Refresh list status</Tooltip>}>
                  <IconButton color="primary" aria-label="add to shopping cart">
                    <Refresh onClick={() => this.getContactLists()}/>
                  </IconButton>   
                  </OverlayTrigger>               
              </div>       
              <br/>
              <MUIDataTable className={classes.table} data={listsDataSource} columns={columns} options={options} />
             
           </div>  
           <br/>
           <div  style={{"font-size" : "10px", "width" : "90%", paddingLeft:"30px", display: this.state.hidePreview }}>
             {/* , display: }}> , , borderStyle:"solid"borderColor:"blue",borderWidth:"1px",  display: 'display*/}
                        {/* <p> */}
                         {/* <h3> Preview for List <b>{this.state.listName}</b> (ID {this.prevList})</h3> */}
                         {/* <div style={{float:"left", width:"70%", marginTop:"10px"}}> */}
                            <label class="Product_Header" style={{width:"35%",marginTop:"50px"}} >List : {this.state.listName} (ID {this.prevList})</label>
                            {/* <Save style={{width:"28px", height:"28px",  color: "#1782c5", marginTop:"10px"}} onClick={this.downloadFullList(this.prevList)}/> */}
                         {/* </div> */}
                        <p/>
                        <div style={{clear:"left","width" : "100%"}} >
                        <MUIDataTable className={classes.table} data={previewDataSource} columns={subColumns} options={subOptions} />
                        </div>
           </div>            
          {/* </div>   */}
        </div> 

        <div>
        <Dialog maxWidth={120} open={this.state.visible} onClose={() => this.closeModal()} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" class="Account" style={{marginLeft:"24px", marginBottom:"20px", fontWeight:"bold"}}>Contact Attributes</DialogTitle>
          <DialogContent>
            <DialogContentText class="subtitle_page">
              Attributes
            </DialogContentText>
            {/* <TextField
                id="refnum"
                label="Client Ref Number"
                //required
                //fullWidth
                value={this.state.refnum}
                onChange={this.handleChange}  
                className={classes.textField}
                margin="normal"
                //helperText="hello"
                variant="outlined"
                placeholder="[ref number value]"
                InputLabelProps={{shrink: true}}
                />
                <p/> */}
              <TextField
                id="attr1"
                label="Attribute #1"
                //required
                value={this.state.attr1}
                onChange={this.handleChange}  
                className={classes.textField}
                margin="normal"
                //helperText="hello"
                variant="outlined"
                placeholder="[attribute value]"
                InputLabelProps={{shrink: true}}
                />    
                <p/>
              <TextField
                id="attr2"
                label="Attribute #2"
                //required
                value={this.state.attr2}
                onChange={this.handleChange}  
                className={classes.textField}
                margin="normal"
                //helperText="hello"
                variant="outlined"
                placeholder="[attribute value]"
                InputLabelProps={{shrink: true}}
                />  
                <p/>
              <TextField
                id="attr3"
                label="Attribute #3"
                //required
                value={this.state.attr3}
                onChange={this.handleChange}  
                className={classes.textField}
                margin="normal"
                //helperText="hello"
                variant="outlined"
                placeholder="[attribute value]"
                InputLabelProps={{shrink: true}}
                />  
                <p/>
              <TextField
                id="attr4"
                label="Attribute #4"
                //required
                value={this.state.attr4}
                onChange={this.handleChange}  
                className={classes.textField}
                margin="normal"
                //helperText="hello"
                variant="outlined"
                placeholder="[attribute value]"
                InputLabelProps={{shrink: true}}
                />  
                <p/>
              <TextField
                id="attr5"
                label="Attribute #5"
                //required
                value={this.state.attr5}
                onChange={this.handleChange}  
                className={classes.textField}
                margin="normal"
                //helperText="hello"
                variant="outlined"
                placeholder="[attribute value]"
                InputLabelProps={{shrink: true}}
                />                                                                      
      </DialogContent>
      <DialogActions>
        <Button
            style={{backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"}}
            variant="outlined" //color="secondary"     
            className={classes.submit}
            alignItems="flex-end"
            onClick={() => this.closeModal()}
        >
          Cancel
        </Button>              
        <Button
          alignItems="flex-end"
          variant="contained"
          color="primary"
          onClick={this.updateAttributs}
          className={classes.submit}
        >
        Save
      </Button>      
      </DialogActions>
    </Dialog>
  </div>

      </div> 
    );
  }
}

Contacts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contacts);