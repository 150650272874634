import { LinkContainer } from "react-router-bootstrap";
import React, { Component, Fragment} from "react";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import { RefreshCurrentAuthUser } from "./libs/AuthService";
import config from "./config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getData} from "./containers/SharedFunctions";

import "./App.css";
import "./css/rrr.css";

import LeftMenu from './components/leftmenu';

import Login from "./containers/Login";
//import { config } from "aws-sdk";

class App extends Component {
	constructor(props) {
		super(props);

		 this.state = {
		  isAuthenticated: false,
		  isAuthenticating: true,
		  clientid: null,
		  isSRCLUser:false,
		  campaignid: null,
		  clientSource: [],
		  logoURN:'',
		  clientColor:'',
		  login: '',
		  forcePasswordReset: false,
		  clientName: null
		};

		//global.userType = "";
		// global.clientid = 0;
		// global.email = "";
		//global.loginStatus = "";
	} //end of constructor
	

	async componentDidMount() {
		try {
			if(!this.state.isAuthenticated || (this.state.clientid === "" || this.state.clientid === 0)) {
				const authUser = await RefreshCurrentAuthUser();

				//console.log("Refreshed Authenticated User", authUser);

				if(authUser.passwordStatus === "IN") {
					this.setState({forcePasswordReset: true});
					this.props.history.push('/changepassword');
				} else {
					this.userHasAuthenticated(true, authUser.clientId, authUser.username);

					if(this.props.location.state!==undefined)
					{
						this.handleStateChange(this.props.location.state.clientid) //get clientid from history
						if(this.props.location.state.clientid!=-1)
							this.getClient(this.props.location.state.clientid)	
					}
				}
			}
		}
		catch(e) {
			//TODO:  What to do when we recieve an error here?  Display a toast, redirect to login page, etc...?
			//console.log(e);
		}

		this.setState({ isAuthenticating: false });
	} 

	async getClient (clientid)  {
		let body= {
			"procname" : "procClientSelect",
			"paramcount": "2", 
			"param1": clientid,
			"param2" : ""
		  }

		await getData(body)
		.then(json => {
		  this.setState({ clientSource: json[0],
						//clientColor:json[0][0].ClientColor,
						//logoURN: json[0][0].LogoURN.length > 0 ? json[0][0].LogoURN : '0',
						clientName: json[0][0].ClientName})
		  //console.log('props refresh');
		 // console.log(this.props);
		})				
	}

	userHasAuthenticated = (authenticated, inclientid, inlogin) => {
		this.setState({ 
			isAuthenticated: authenticated, 
			clientid: (inclientid > 0 ? inclientid : -1), 
			isSRCLUser: inclientid <= 0,
			login: inlogin,
			forcePasswordReset: false
		});
	  }

	setForcePasswordReset = () => {
		this.setState({ forcePasswordReset: true });
	}

	handleStateChange = (clientid,campaignid) =>{
		//debugger;
		 //console.log('newClientID' + clientid)
		 if(clientid!==undefined && clientid!==null)
		 {
			 this.setState({clientid: clientid})
			 if(clientid!=-1)
			 	this.getClient(clientid)
		 }

		 if(campaignid!==undefined && campaignid!=null)
			 this.setState({campaignid: campaignid});

		 //console.log(this.state.clientid,this.state.campaignid)
	  }


	handleLogout = async event => {
		await Auth.signOut();
		this.userHasAuthenticated(false, null, null);
		this.props.history.push("/login");
	}

	handleClientSourceChange = (source) =>{
		if(source!==undefined)		
			//this.setState({clientSource: source})
			this.setState({ clientSource: source,clientColor:source[0].ClientColor,  logoURN: source[0].LogoURN.length > 0 ? source[0].LogoURN : '0'})

	}

	getPropsAttrs(){
		
		const childProps = {
			isAuthenticated: this.state.isAuthenticated,
			userHasAuthenticated: this.userHasAuthenticated,
			//userHasAuthenticated : this.userHasAuthenticated.bind(this),
			clientid: this.state.clientid,
			clientColor: this.state.clientColor,
			clientName: this.state.clientName,
			isSRCLUser: this.state.isSRCLUser,
			campaignid: this.state.campaignid,
			email: this.state.login,
			isActive : true,
			isClientActive : true,
			username : this.state.login,
			clientSource: this.state.clientSource,
			logoURN: this.state.logoURN,
			siteConfig:{
				height:config.site_height,
				srclColor: config.SDWK_Color, //config.SRCL_Color,
				bucket: config.s3.BUCKET
			},
			handleStateChange : this.handleStateChange.bind(this),
			//handleCampaignIDChange : this.handleCampaignIDChange.bind(this),
			handleLogout :  this.handleLogout.bind(this),
			handleClientSourceChange: this.handleClientSourceChange.bind(this),
			forcePasswordReset: this.state.forcePasswordReset,
			setForcePasswordReset: this.setForcePasswordReset.bind(this)
		};
	return childProps;
	}

	render()	{
		return (
		!this.state.isAuthenticating &&
		<div style={{float:"center", width:"100%", minWidth:"110px"}}>
			<ToastContainer autoClose={10000} />
			<Routes childProps={this.getPropsAttrs()} />
		</div>	
		)
	}
}

export default withRouter(App);


