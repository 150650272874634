import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import "./Faq.css";
import LeftMenu,{PageHeader} from '../components/leftmenu';
import {getData} from "./SharedFunctions";

class Faq extends React.Component {
    constructor(props) {
        super(props);

        this.state = {          
            faqs: []
        }
    }

	async componentDidMount() {

        let body= {
            "procname" : "procFAQSelect",
            "paramcount": "0"
          }

          await getData(body)
          .then(json => {this.setState({ faqs: json[0], isLoading: false})
          })

          window.scrollTo(0, 0);
      }

    render() {
        const {faqs} = this.state

        return (
            <div style={{width:"100%", borderStyle:"solid", borderColor:this.props.siteConfig.srclColor, borderWidth:"0px"}}>

            <LeftMenu selected="faq" confirmationUnsaved_LM={(tmp) => event => {return true}} clientid={this.props.clientid} props={this.props}/>
    
            <div className="MainInnerWrapperDiv">
            <PageHeader title="FAQs" username={this.props.username}/>       
    
              <div style={{float:"left", width : "90%", paddingLeft:"30px" }}>
                <Accordion>

                    {faqs.map(faq => {return (
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={faq.FaqID} style={{backgroundColor:"#f9f9f9"}}>
                                <b>{faq.Question}</b>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={faq.FaqID}>
                                <Card.Body>
                                    {faq.Answer}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        
                    )})}
                    
                </Accordion>
                </div>
        </div>
     </div>
        );
    }
}

export default Faq;