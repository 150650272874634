import React from "react";
import { API, Auth } from 'aws-amplify';
import AWS from "aws-sdk";
import queryString from 'query-string';
import {ProgressBar} from 'react-bootstrap';
//import "./Dashboard.css";
import config from '../config';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.isAuthenticated = props.isAuthenticated;
        this.dashboardContainerRef = React.createRef();

        this.dashboardId = this.props.match.params.dashboardId;
        this.sheetId = this.props.match.params.sheetId;

        this.state = {
            dashboardLoadingProgress: 0,
        };
    }

    async componentDidMount() {
        const queryParams = queryString.parse(this.props.location.search);
        AWS.config.update({region: config.cognito.REGION});
        const user = await Auth.currentAuthenticatedUser();
        this.setState({dashboardLoadingProgress: 25});
        const userSession = user.signInUserSession;

        const cognitoIdentity = new AWS.CognitoIdentity();

        const getIdParams = {
            IdentityPoolId: config.cognito.IDENTITY_POOL_ID, 
            Logins: {[config.cognito.IDP_LOGIN_ID]: userSession.idToken.jwtToken} 
        };

        cognitoIdentity.getId(getIdParams, async (err, data) => {
            if (err) {
                console.log('Error obtaining Cognito ID.', err, data);
            } else {
                this.setState({dashboardLoadingProgress: 50});
                data.Logins = {[config.cognito.IDP_LOGIN_ID]: userSession.idToken.jwtToken};

                cognitoIdentity.getOpenIdToken(data, async (err, token) => {
                    if (err) {
                        console.log('Error obtaining authentication token.');
                    } else {
                        this.setState({dashboardLoadingProgress: 75});
                        
                        const dashboardId = this.dashboardId;
                        const openIdToken = token.Token;
                        const authenticated = true;
                        const sessionName = userSession.idToken.payload.email;
                        const resetDisabled = false;
                        const undoRedoDisabled = false;

                        const urlParams = "?dashboardId=" + dashboardId + "&openIdToken="+ openIdToken + "&authenticated=" + authenticated + "&sessionName=" + sessionName + "&resetDisabled=" + resetDisabled + "&undoRedoDisabled=" + undoRedoDisabled;
                    
                        const dashboardUrl = await API.get("getDashboardEmbedURL", "/RRRGetQuickSightDashboardUrl" + urlParams);
                        this.setState({dashboardLoadingProgress: 100});
                        
                        var finalUrl = dashboardUrl.EmbedUrl;
                        // console.log("embedded dashboard url", dashboardUrl.EmbedUrl);

                        if(this.sheetId) {
                            const urlSplit = finalUrl.split('?');
                            finalUrl = urlSplit[0] + '/sheets/' + this.sheetId + '?' + urlSplit[1];
                        }
                        
                        // console.log("finalurl", finalUrl);

                        const encodedUrl = encodeURIComponent(finalUrl);
                        const encodedExtraParams = encodeURIComponent(JSON.stringify(queryParams));

                        window.location.href = "/dashboard.html?launchUrl=" + encodedUrl + "&extraParams=" + encodedExtraParams;
                    }
                });
            }
        });
    }

    render() {
        return (
            <div>Initializing Dashboard...<ProgressBar striped now={this.state.dashboardLoadingProgress} label={`${this.state.dashboardLoadingProgress}%`}/></div>
        );
    }
}

export default Dashboard;