import React, { Component, UIComponent } from "react";
import { Link, BrowserRouter as Router, Route } from "react-router-dom";
import config from "../config";
import LeftMenu, {PageHeader} from '../components/leftmenu';
import Wiz from '../components/wiz';
import "../css/rrr.css";
import {getData} from "./SharedFunctions";
import CampaignContact from "../containers/CampaignContact";
import CampaignEmail from "../containers/CampaignEmail";
import CampaignSms from "../containers/CampaignSms";
import CampaignResponse from "../containers/CampaignResponse";
import CampaignResponseP from "../containers/CampaignResponseP";
import CampaignResponseQ from "../containers/CampaignResponseQ";
import CampaignSchedule from "../containers/CampaignSchedule";
import {CampaignReview} from "../containers/CampaignReview";
import {toast } from 'react-toastify';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { s3Get } from "../libs/awsLib";

export default class CampaignNew extends Component {

  constructor(props) {
    super(props);

    //debugger;
    //props.handleStateChange = props.handleStateChange.bind(this)
    this.updateCampaignState = this.updateCampaignState.bind(this)
    this.updateCampaignStateIdOnly = this.updateCampaignStateIdOnly.bind(this)
    //this._next = this._next.bind(this)
    this._prev = this._prev.bind(this)
    this.goto1 = this.goto1.bind(this)
    this.goto2 = this.goto2.bind(this)
    this.goto2_5 = this.goto2_5.bind(this)
    this.goto3 = this.goto3.bind(this)
    this.goto4 = this.goto4.bind(this)
    this.goto5 = this.goto5.bind(this)
    this.goto6 = this.goto6.bind(this)
    this.goto7 = this.goto7.bind(this)
    this.updateDirtyProductFlag = this.updateDirtyProductFlag.bind(this)
    this.confirmationUnsaved_LM = this.confirmationUnsaved_LM.bind((this))
    //below will be invoked by CanpaignContact on create campaign
    //this.handleEventIdChange = this.handleEventIdChange.bind(this);
    this.fwl=[]

    this.state = {
      isLoading: false,
      currentstep: 0,//props.location.state.stepid===undefined? 0 : props.location.state.stepid,
      clientid: props.location.state!== undefined ?  props.location.state.clientid : null,
      eventid: 0,
      clientName: '',
      login: props.login,
      cstatus: null,
      logoURN:"",
      campaign:{
          clientid: 0, //TODO 0
          campaignid: 0,
          campaignname: '',
          listid: 0,
          notificationsubject: '',
          notificationbody: '',
          emailfrom: [],
          webhdrmsg: '',
          webdetailmsg: '',
          webfootermsg: '',
          recallhotline: '',
          remedytext: '',
          campaigndescription: '',
          products: [],
          schedules: [],
          launchstatus: '',
          launchstatusdesc: '',
          PURL: '',
          hasnoresponse:0,
          logoURN:'',
          siteColor:'',
          liststatusid: '',
          smsmessage: '',
          allowedmessagetype: '',
          fwl:this.fwl,
          emailLogoURN:'',
          emailHeaderColor:''
        },
      isproductdirty:false
    };

  }


  updateDirtyProductFlag(flag) {
    this.setState({isproductdirty:flag})
  }

confirmationUnsaved = (step) => {

  confirmAlert({
    message : 'You have unsaved changes that will be lost. Click `Cancel` and `Next` to save',
    buttons: [
      {
        label: 'Cancel',
      },
      {
        label: 'Discard Changes',
        onClick: () => {this.setState({currentstep: step}); this.getCampaign();}
      }
    ]
  });  
}
//left menu confirmation
confirmationUnsaved_LM =(step) => event => {
  if(!this.state.isproductdirty) return

   event.preventDefault()
   confirmAlert({
      message : 'You have unsaved changes that will be lost. Click `Cancel` and `Next` to save',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Discard Changes',
          onClick: () => {
            switch(step)
            {
            case 'campaigns':
              this.props.history.push({pathname: 'Campaigns', state: {clientid: this.state.clientid}}) 
              break;
            case 'account':
              this.props.history.push({pathname: 'ManageClient', state: {clientid: this.state.clientid}})           
              break;
            case 'contacts':
              this.props.history.push({pathname: 'Contacts', state: {clientid: this.state.clientid}})       
              break;     
            case 'admin':
              this.props.history.push({pathname: 'Admin', state: {clientid: this.state.clientid}})   
              break;  
            case 'changepassword':
              this.props.history.push({pathname: 'changepassword', state: {clientid: this.state.clientid}})     
              break;             
            case 'faq':
              this.props.history.push({pathname: 'Faq', state: {clientid: this.state.clientid}})     
              break;    
            default: return       
            }
          }
        }
      ]
    });     
}


  goto1(message='') { 
    let {isproductdirty} = this.state
    if(isproductdirty) {
      this.confirmationUnsaved(1)
      return
    }
    else
    {
    this.flyToaster(message)
    this.setState({currentstep: 1}); this.getCampaign();  
    }
  }
  async goto2(message='') { 
    let {isproductdirty} = this.state
    if(isproductdirty) {
      this.confirmationUnsaved(2)
      return
    }
    else
    {
    await this.getCampaign()
    .then (()=>{
      this.flyToaster(message)
      this.setState({currentstep: 2})
      })
    }
  }
  async goto2_5(message='') { 
    let {isproductdirty} = this.state
    if(isproductdirty) {
      this.confirmationUnsaved(2.5)
      return
    }
    else
    {
    await this.getCampaign()
    .then (()=>{    
      this.flyToaster(message)
      this.setState({currentstep: 2.5})
      })
    }
  }
  goto3(message='') { 
    let {isproductdirty} = this.state
    if(isproductdirty) {
      this.confirmationUnsaved(3)
      return
    }
    else
    {
    this.flyToaster(message)
    this.setState({currentstep: 3}); this.getCampaign();  
    }
  }
  goto4(message='',ignoreFlag=false) { 
    let {isproductdirty} = this.state
    // alert(ignoreFlag)
    if(isproductdirty && !ignoreFlag) {
      this.confirmationUnsaved(4)
      return
    }
    else
    {
      isproductdirty =false;
      this.flyToaster(message)
      this.setState({currentstep: 4}); this.getCampaign();  
    }
  }
  goto5(message='',ignoreFlag=false) { 
    let {isproductdirty} = this.state
    if(isproductdirty && !ignoreFlag) {
      this.confirmationUnsaved(5)
      return
    }
    else
    {
    isproductdirty =false;
    this.flyToaster(message)
    this.setState({currentstep: 5}); this.getCampaign();  
  }
  }
  goto6(message='',ignoreFlag=false) { 
    //debugger;
    let {isproductdirty} = this.state
    if(isproductdirty && !ignoreFlag) {
      this.confirmationUnsaved(6)
      return
    }
    else
    {
    this.flyToaster(message)
    this.setState({currentstep: 6}); this.getCampaign();  
  }
}

  async goto7(message='') { 
    let {isproductdirty} = this.state
    if(isproductdirty) {
      this.confirmationUnsaved(7)
      return
    }
    else
    {
    await this.getCampaign()
    .then (()=>{    
      this.flyToaster(message)
      this.setState({currentstep: 7})
      })
    }
  }

  flyToaster(message='')
  {
    if(message.length>0)
      toast.info(message, {position: toast.POSITION.TOP_RIGHT})
  }
    
  _prev() {
    let {currentstep,isproductdirty} = this.state
    // If the current step is 2 or 3, then subtract one on "previous" button click
    if(currentstep==6 && this.state.campaign.hasnoresponse==1)
      currentstep = 3   
    else if(currentstep==3 && this.state.campaign.allowedmessagetype==='Sms')
      currentstep = 2.5
    else if(currentstep==2.5 && this.state.campaign.allowedmessagetype==='Sms')
      currentstep = 1            
    else
      currentstep = currentstep <= 1? 1: currentstep==2.5?2:currentstep - 1


    if(isproductdirty) {
      this.confirmationUnsaved(currentstep)
      return
    }

    this.setState({currentstep});
    this.getCampaign();
  }


  get previousButton(){
    //debugger;
    let currentStep = this.state.currentstep;
    let campaignId  = this.state.campaign.campaignid

    if(currentStep > 1){  
      return (
        <label class="back_link" style={{marginLeft:"78%", bottom:"34px",position:"fixed"}}>
        <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid, campaignid:campaignId}}} 
            onClick={this._prev} style={{display: 'flex',alignItems: 'center'}} > 
              <NavigateBefore style={{color: "#1782c5",paddingRight:"3px" }}/>Back
          </Link>    
        </label>        
      )
    }
    // ...else render nothing
    return null;
}

  updateCampaignState = (updatedCampaign) => {
		this.setState({ campaign: updatedCampaign});
  }

  updateCampaignStateIdOnly = (campaignId) => {
    //debugger;
    this.setState({eventid: campaignId});
    this.getCampaign();
  }
  
    
    componentWillMount() {

      if(this.props.location.state !== undefined)
      {
          this.setState({
            eventid: this.props.location.state.campaignid,//selectedeventid, 
            clientid: this.props.location.state.clientid,
            // currentstep: this.props.location.state.stepid===undefined? 0 : this.props.location.state.stepid,
            campaign:{
              campaignid:this.props.location.state.campaignid,//selectedeventid, 
              clientid:this.props.location.state.clientid
            }
          }
          );

        }
    }

async componentDidMount() {

  if(this.props.location.state === undefined)
  {
    if(!this.props.isSRCLUser) {
      this.props.history.push({pathname: 'Campaigns',state: {clientid: this.props.clientid}});
    } else {
      this.props.history.push({
        pathname: './clients',
        state: {clientid: -1, email: this.props.username}
      });

    }
    return;
  }

  this.state.clientid = this.props.location.state.clientid;

  let body= {
    "procname" : "procClientSelect",
    "paramcount": "2", 
    "param1": this.state.clientid,
    "param2" : ""
  }
 
   await getData(body)
   .then(json => this.setState({ dataSource: json[0], clientName:json[0][0].ClientName, clientColor:json[0][0].ClientColor,logoURN: json[0][0].LogoURN.length > 0 ? json[0][0].LogoURN : '0', isLoading: true}));

  

  if(this.state.eventid !== undefined && this.state.eventid > 0)
    await this.getCampaign();
  
  if(this.state.currentstep === 0)
  {
    this.setState({currentstep:1});

    if(this.state.cstatus === 'LN' || this.state.cstatus === 'CN')
      this.setState({currentstep:7});
  }

  window.scrollTo(0, 0);
}

async getFWList(){//forbidden words for content filtering goes in parent props fwl
  var {fwl} = this.state.campaign
  if(fwl=== undefined || fwl.length===0)
  {
    const fw = await s3Get(config.s3.ForbiddenWordsList);
    this.fwl = fw.Body.toString().split(",");
  }
}

async getCampaign (){
  if(this.state.eventid === undefined ) return;

  let body= {
    procname: "procEventSelect_v1_4",
    paramcount: 2,
    param1: this.state.clientid,
    param2: this.state.eventid
  }

  let isEventPending = false;
  let is1Done, is2Done, is3Done, is4Done, is5Done = false;

  await this.getFWList()
  .then(async ()=>{
  await getData(body)
  .then(json => {
    //console.log("campaingnew.js campaingjson: ", json);

    isEventPending = json[0][0].LaunchStatusID === 'PE';
    is1Done = json[0][0].EventID && json[0][0].EventID >= 0 && (json[0][0].LaunchStatusID === 'PE' && json[0][0].ListStatusID !== 'A' ? 0 : json[0][0].ListID) > 0 ? true : false;
    is2Done = json[0][0].AllowedMessageType!=='Sms' ? (json[0][0].NotificationSubject && json[0][0].NotificationMessage && json[0][0].NotificationSubject.length > 0 && json[0][0].NotificationMessage.length > 0 ? true : false) : true
              && json[0][0].AllowedMessageType!=='Emil' ? (json[0][0].SmsMessage && json[0][0].SmsMessage.length > 0 ? true : false) : true
    is3Done = json[0][0].HasNoResponse ? true : (json[0][0].WebHeaderMsg && json[0][0].WebHeaderMsg.length > 0 && json[0][0].WebDetailedMsg && json[0][0].WebDetailedMsg.length > 0 ? true : false);
    //is4Done = json[0][0].HasNoResponse ? true : (json[0][0].WebHeaderMsg > 0 && json[0][0].WebDetailedMsg > 0 && json[0][0].SiteColor > 0 && json[0][0].LogoURN > 0 ? true : false);
    //is5Done = json[0][0].HasNoResponse ? true : (json[0][0].WebHeaderMsg > 0 && json[0][0].WebDetailedMsg > 0 && json[0][0].SiteColor > 0 && json[0][0].LogoURN > 0 ? true : false);

    this.setState({
      campaignname:json[0][0].EventName,
      campaign: {
        clientid: json[0][0].ClientID,
        campaignid: json[0][0].EventID,
        campaignname: json[0][0].EventName,
        listid: json[0][0].LaunchStatusID === 'PE' && json[0][0].ListStatusID !== 'A' ? 0 : json[0][0].ListID,
        listname: json[0][0].ListName,
        notificationsubject: json[0][0].NotificationSubject,
        notificationbody: json[0][0].NotificationMessage,
        launchstatus: json[0][0].LaunchStatusID,
        launchstatusdesc: json[0][0].LaunchStatus,
        webhdrmsg: json[0][0].WebHeaderMsg,
        webdetailmsg: json[0][0].WebDetailedMsg,
        webfootermsg: json[0][0].WebFooterMessage,
        recallhotline: json[0][0].RecallHotline,
        total: json[0][0].Total,
        unsubscribed: json[0][0].Unsubscribed,
        outedOut: json[0][0].OptedOut,
        PURL: json[0][0].PURL,
        logoURN: json[0][0].LogoURN,
        siteColor: json[0][0].SiteColor,
        email: json[0][0].Email === null? '' : json[0][0].Email, // selected FROM email for the campaign
        emailfrom: json[1], // an attay of configured FROM emails per client
        hasnoresponse: json[0][0].HasNoResponse,
        hasnoemail: json[0][0].HasNoEmail,
        hasnosms: json[0][0].HasNoSms,
        liststatusid: json[0][0].ListStatusID,
        smsmessage: json[0][0].SmsMessage,
        allowedmessagetype: json[0][0].AllowedMessageType,
        fwl:this.fwl,
        campaigntypecode: json[0][0].EventTypeCode,
        campaigntypeconfirmedby: json[0][0].EventTypeConfirmedBy,
        campaigntypeconfirmedon: json[0][0].EventTypeConfirmedOn,
        emailHeaderColor: json[0][0].EmailHeaderColor,
        emailLogoURN: json[0][0].EmailLogoURN
      },
      cstatus: json[0][0].LaunchStatusID,
      isLoading: false,
      isproductdirty:false
    })

  });
}
);


  // console.log("campaingnew.js is3Done after calc: ", is3Done);
  if(isEventPending) {
    this.setState({
      isDone1: is1Done,
      isDone2: is2Done,
      isDone3: is3Done,
      isDone4: is4Done,
      isDone5: is5Done,
      isDone7: false
    });

    let scheduleRequestBody = {
      "procname" : "procEventMessagingSetupSelect",
      "paramcount": "1", 
      "param1": this.state.eventid
    }
  
    await getData(scheduleRequestBody)
    .then(json => {
      this.setState({isDone6: json && json[0] && json[0].length > 0});
    });

  } else {
    this.setState({
      isDone1: true,
      isDone2: true,
      isDone3: true,
      isDone4: true,
      isDone5: true,
      isDone6: true,
      isDone7: true
    });
  }
  
}
  render() {
    
    //const {dataSource, clientColor} = this.state; 
    // console.log("in cnew:");   
    // console.log(this.state.campaign);
    
    return (
      <div>
      <LeftMenu selected="campaigns" confirmationUnsaved_LM={this.confirmationUnsaved_LM}  clientid={this.state.clientid} isproductdirty={this.state.isproductdirty} props={this.props}/>

      <div className="MainInnerWrapperDiv">
      <PageHeader title={!this.state.campaign.campaignid ? "Campaign" : (!this.state.campaign.campaignname? '' : this.state.campaign.campaignname)} username={this.props.username} clientname={this.props.isSRCLUser?this.state.clientName:''}/>

          <div style={{float:"left",  paddingLeft:"30px", width : "95%"}}>
          {this.renderWizard(this.state.currentstep)}
          </div>      
      </div>  
    </div>
    );
  }

  renderWizard(step){
    //debugger;
    switch(step) {
      case 1: 
        return (
          <div style={{"float":"left", "width" : "100%", "margin":"1px", marginTop:"10px",
                    "border-style":"solid", "border-color":"white"}}>
            <Wiz stepid="1" clientid={this.state.clientid} hasnoresponse={this.state.campaign.hasnoresponse} isproductdirty={this.state.isproductdirty} campaignid={this.state.campaign.campaignid} 
                allowedmessagetype={this.state.campaign.allowedmessagetype} 
                goto1={this.goto1} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} goto4={this.goto4} goto5={this.goto5} goto6={this.goto6} goto7={this.goto7} 
                props={this.props} isDone1={this.state.isDone1} isDone2={this.state.isDone2} isDone3={this.state.isDone3} isDone4={this.state.isDone4} isDone5={this.state.isDone5} isDone6={this.state.isDone6} isDone7={this.state.isDone7} />
            <p>&nbsp;</p>
            <CampaignContact updateDirtyProductFlag={this.updateDirtyProductFlag} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} parentCampaign={this.state.campaign} updateCampaignStateIdOnly={this.updateCampaignStateIdOnly} parentProps={this.props}/>
          </div>
        )
      case 2:
        if(this.state.campaign.allowedmessagetype!=="Sms")
        return (
          <div style={{"float":"left", "width" : "100%", "margin":"1px", marginTop:"10px",
                    "border-style":"solid", "border-color":"white"}}>
            <Wiz stepid="2" clientid={this.state.clientid} hasnoresponse={this.state.campaign.hasnoresponse} isproductdirty={this.state.isproductdirty} campaignid={this.state.campaign.campaignid} 
                allowedmessagetype={this.state.campaign.allowedmessagetype} 
                goto1={this.goto1} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} goto4={this.goto4} goto5={this.goto5} goto6={this.goto6} goto7={this.goto7} 
                isDone1={this.state.isDone1} isDone2={this.state.isDone2} isDone3={this.state.isDone3} isDone4={this.state.isDone4} isDone5={this.state.isDone5} isDone6={this.state.isDone6} isDone7={this.state.isDone7}/>                 
            <p>&nbsp;</p>
            <CampaignEmail updateDirtyProductFlag={this.updateDirtyProductFlag} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} parentCampaign={this.state.campaign} updateCampaignStateIdOnly={this.updateCampaignStateIdOnly} parentProps={this.props}/>
            {this.previousButton}
          </div>
        )
      case 2.5:
        if(this.state.campaign.allowedmessagetype!=="Email")
        return (
          <div style={{"float":"left", "width" : "100%", "margin":"1px", marginTop:"10px",
                    "border-style":"solid", "border-color":"white"}}>
            <Wiz stepid="2.5" clientid={this.state.clientid} hasnoresponse={this.state.campaign.hasnoresponse} isproductdirty={this.state.isproductdirty} campaignid={this.state.campaign.campaignid} 
                allowedmessagetype={this.state.campaign.allowedmessagetype} 
                goto1={this.goto1} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} goto4={this.goto4} goto5={this.goto5} goto6={this.goto6} goto7={this.goto7} 
                isDone1={this.state.isDone1} isDone2={this.state.isDone2} isDone3={this.state.isDone3} isDone4={this.state.isDone4} isDone5={this.state.isDone5} isDone6={this.state.isDone6} isDone7={this.state.isDone7}/>                 
            <p>&nbsp;</p>
            <CampaignSms updateDirtyProductFlag={this.updateDirtyProductFlag} goto2={this.goto2} goto3={this.goto3} parentCampaign={this.state.campaign} updateCampaignStateIdOnly={this.updateCampaignStateIdOnly} parentProps={this.props}/>
            {this.previousButton}
          </div>
          )
      case 3:
        return (
          <div style={{"float":"left", "width" : "100%", "margin":"1px", marginTop:"10px",
                    "border-style":"solid", "border-color":"white"}}>
            <Wiz stepid="3" clientid={this.state.clientid} hasnoresponse={this.state.campaign.hasnoresponse} isproductdirty={this.state.isproductdirty} campaignid={this.state.campaign.campaignid} 
                allowedmessagetype={this.state.campaign.allowedmessagetype} 
                goto1={this.goto1} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} goto4={this.goto4} goto5={this.goto5} goto6={this.goto6} goto7={this.goto7} 
                isDone1={this.state.isDone1} isDone2={this.state.isDone2} isDone3={this.state.isDone3} isDone4={this.state.isDone4} isDone5={this.state.isDone5} isDone6={this.state.isDone6} isDone7={this.state.isDone7}/>
           <p>&nbsp;</p>
           <CampaignResponse updateDirtyProductFlag={this.updateDirtyProductFlag} goto4={this.goto4} goto6={this.goto6} updateCampaignStateIdOnly={this.updateCampaignStateIdOnly} parentCampaign={this.state.campaign} parentProps={this.props}/>
            <p>&nbsp;</p>
            <div>
              {this.previousButton}
            </div>
            
          </div>
        )
        case 4:
        return (
          <div style={{"float":"left", "width" : "100%", "margin":"1px", marginTop:"10px",
                    "border-style":"solid", "border-color":"white"}}>
            <Wiz stepid="4" clientid={this.state.clientid} hasnoresponse={this.state.campaign.hasnoresponse} isproductdirty={this.state.isproductdirty} campaignid={this.state.campaign.campaignid} 
                allowedmessagetype={this.state.campaign.allowedmessagetype} 
                goto1={this.goto1} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} goto4={this.goto4} goto5={this.goto5} goto6={this.goto6} goto7={this.goto7} 
                isDone1={this.state.isDone1} isDone2={this.state.isDone2} isDone3={this.state.isDone3} isDone4={this.state.isDone4} isDone5={this.state.isDone5} isDone6={this.state.isDone6} isDone7={this.state.isDone7}/>
            <p>&nbsp;</p>
            <CampaignResponseP goto3={this.goto3} goto5={this.goto5} goto6={this.goto6} isproductdirty={this.state.isproductdirty} updateDirtyProductFlag={this.updateDirtyProductFlag} parentCampaign={this.state.campaign} parentProps={this.props}/>
       
            <div>
              {this.previousButton}
            </div>
            
          </div>
        )
        case 5:
        return (
          <div style={{"float":"left", "width" : "100%", "margin":"1px", marginTop:"10px",
                    "border-style":"solid", "border-color":"white"}}>
            <Wiz stepid="5" clientid={this.state.clientid} hasnoresponse={this.state.campaign.hasnoresponse} isproductdirty={this.state.isproductdirty} campaignid={this.state.campaign.campaignid}  
                allowedmessagetype={this.state.campaign.allowedmessagetype} 
                goto1={this.goto1} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} goto4={this.goto4} goto5={this.goto5} goto6={this.goto6} goto7={this.goto7} 
                isDone1={this.state.isDone1} isDone2={this.state.isDone2} isDone3={this.state.isDone3} isDone4={this.state.isDone4} isDone5={this.state.isDone5} isDone6={this.state.isDone6} isDone7={this.state.isDone7}/>
            <p>&nbsp;</p>
            <CampaignResponseQ updateDirtyProductFlag={this.updateDirtyProductFlag} goto3={this.goto3} goto6={this.goto6} parentCampaign={this.state.campaign} parentProps={this.props}/>
            <div>
              {this.previousButton}
            </div>
            
          </div>
        )
      case 6:
        return (
          <div style={{"float":"left", "width" : "100%", "margin":"1px", marginTop:"10px",
                    "border-style":"solid", "border-color":"white"}}>
            <Wiz stepid="6" clientid={this.state.clientid} hasnoresponse={this.state.campaign.hasnoresponse} isproductdirty={this.state.isproductdirty} campaignid={this.state.campaign.campaignid} 
                allowedmessagetype={this.state.campaign.allowedmessagetype} 
                goto1={this.goto1} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} goto4={this.goto4} goto5={this.goto5} goto6={this.goto6} goto7={this.goto7} 
                isDone1={this.state.isDone1} isDone2={this.state.isDone2} isDone3={this.state.isDone3} isDone4={this.state.isDone4} isDone5={this.state.isDone5} isDone6={this.state.isDone6} isDone7={this.state.isDone7}/>             
            <p>&nbsp;</p>
            <CampaignSchedule updateDirtyProductFlag={this.updateDirtyProductFlag} goto7={this.goto7} parentCampaign={this.state.campaign} parentProps={this.props}/>
            {this.previousButton}
          </div>
        )
      case 7:
        return (
          <div style={{"float":"left", "width" : "100%", "margin":"1px", marginTop:"10px",
                    "border-style":"solid", "border-color":"white"}}>
            <Wiz stepid="7" clientid={this.state.clientid} hasnoresponse={this.state.campaign.hasnoresponse} isproductdirty={this.state.isproductdirty} campaignid={this.state.campaign.campaignid}  
                allowedmessagetype={this.state.campaign.allowedmessagetype} 
                goto1={this.goto1} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} goto4={this.goto4} goto5={this.goto5} goto6={this.goto6} goto7={this.goto7} 
                isDone1={this.state.isDone1} isDone2={this.state.isDone2} isDone3={this.state.isDone3} isDone4={this.state.isDone4} isDone5={this.state.isDone5} isDone6={this.state.isDone6} isDone7={this.state.isDone7}/>
            <p>&nbsp;</p>
            <CampaignReview goto1={this.goto1} goto2={this.goto2} goto2_5={this.goto2_5} goto3={this.goto3} goto4={this.goto4} 
                goto5={this.goto5} goto6={this.goto6} goto7={this.goto7} parentCampaign={this.state.campaign} parentProps={this.props}
                updateCampaignStateIdOnly={this.updateCampaignStateIdOnly} />
            {this.previousButton}
           
          </div>
        )
    }
  }
}

