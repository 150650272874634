import React, { Component } from "react";
import { Link } from "react-router-dom";
//import {FormGroup,FormControl,ControlLabel} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import Select from 'react-select';
import "./CreateUser.css";
import { Auth } from "aws-amplify";
import Form from 'react-bootstrap/Form';
import {getData} from "./SharedFunctions";


let pkgDataSource = [];

export default class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      clientname: "",
      contactname: "",
      contactemail: "",
      contactphone: "",
      result: "",
      newUser: null,
      plan: ""
    };


  }


  validateForm() {
    return (
      this.state.clientname.length > 0 &&
      this.state.contactemail.length > 0 
      //this.state.password.length > 0 &&
      //this.state.password === this.state.confirmPassword
    );
  }


  handleChange = event => {
    //console.log(event.target);
		this.setState({
		  [event.target.id]: event.target.value
		});
	}

	//method to handle signUp submit button
	handleSubmit = async event => {

    event.preventDefault();
    let body= {
      "procname" : "procClientInsert",
      "paramcount": "6", 
      "param1": this.state.clientname,
      "param2": this.state.contactname,          
      "param3": this.state.contactemail,   
      "param4": this.state.contactphone,
      "param5" : this.state.plan,
      "param6": this.props.username                 
    }

    await getData(body)
    .then(json => {
      this.setState({isLoading: false})
      this.props.history.push({pathname: 'ManageClient', state: {clientid: json[0][0].ClientID, campaignid:null}})
    }); //global.clientid = json[0][0].ClientID; 
    //.then(json => console.log(global.clientid))
    //.then(this.props.history.push("./clients")); 
    
  }


  async componentDidMount() { 

    let body= {
      "procname" : "procSubscriptionPlanSelect",
      "paramcount": "1", 
      "param1": "1"
    }
    //get packages
    await getData(body)
    .then(json => {
      pkgDataSource.length = 0;
      json[0].map(el => {
        pkgDataSource.push({ label: el.PlanName + ' - Max Msg:' +  el.MaxMessages + ' - Price: $' +  el.Price, value: el.PlanCode }) 
      });
      this.setState({isLoading: false});
    })

}

renderConfirmationForm() {
    return (
        <Form>
            <Form.Label>Create success!</Form.Label>
            <br/>
            <Link to="/clients" className="btn btn-info btn-sm">
                     Back to clients
            </Link>
        </Form>
    );
  }

  renderForm() {

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="clientname" bsSize="medium">
          <Form.Label>Client Name</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={this.state.clientname}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group controlId="contactname" bsSize="medium">
          <Form.Label>Primary Contact Name</Form.Label>
          <Form.Control
            value={this.state.contactname}
            onChange={this.handleChange}
            type="text"
          />
        </Form.Group>
        <Form.Group controlId="contactemail" bsSize="medium">
          <Form.Label>Contact Email</Form.Label>
          <Form.Control
            value={this.state.contactemail}
            onChange={this.handleChange}
            type="email"
          />
        </Form.Group>
        <Form.Group controlId="contactphone" bsSize="medium">
          <Form.Label>Contact Phone</Form.Label>
          <Form.Control
            value={this.state.contactphone}
            onChange={this.handleChange}
            type="phone"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label> Subscription Plan</Form.Label>
          <Select options={ pkgDataSource } onChange={this.handleSelectChange} />   
        </Form.Group>
        <LoaderButton
          block
          bsSize="medium"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Create Client"
          loadingText="Creating Client�"
        />
      </Form>
    );
  }

  handleSelectChange = e => {
    this.setState({ plan: e.value });
  }

  render() {

      return (
      <div className="CreateUser">
        {this.state.result === ""
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
}
