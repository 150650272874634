import React from "react";
import config from '../config';

function RedirectPurl(props) {

    let purl = "";

    if(props.match.params.purl) {
        purl = props.match.params.purl;
        
        //Response site will validate the purl is correct and active, here we just make sure it's correctly formatted
        const splitPurl = purl.split('_');
        if(splitPurl.length == 2) {
            const eventContactId = parseInt(splitPurl[0], 32);
            const randomId = splitPurl[1];

            //If able to parse values with no error, format is correct so redirect to response site
            //add /s to URL so response site knows request was initiated from portal site (from a text message, not email message)
            if(eventContactId && randomId.length === 2) {
                window.location.href = config.RESPONSEURL + purl + '/s';
                return null;
            } 
        }
    }

    return (
        <div className="NotFound">
            <h3>Sorry, page not found!</h3>
        </div>
    );
}

export default RedirectPurl;