import React from "react";
import { Route, Switch } from "react-router-dom";
//import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import ChangePassword from "./containers/ChangePassword";
import Clients from "./containers/Clients";
import ManageClient from "./containers/ManageClient";
import Campaigns from "./containers/Campaigns";
import Admin from "./containers/Admin";
import Contacts from "./containers/Contacts";
import CreateContactList from "./containers/CreateContactList";
import CampaignNew from "./containers/CampaignNew";
// import CampaignContact from "./containers/CampaignContact";
// import CampaignEmail from "./containers/CampaignEmail";
import Dashboard from "./containers/Dashboard";
import Faq from "./containers/Faq";
import Unauthorized from "./containers/Unauthorized";
import RoutePublic from "./components/RoutePublic";
import RouteAuthenticated from "./components/RouteAuthenticated";
import RouteSRCLAuthenticated from "./components/RouteSRCLAuthenticated";
import RedirectPurl from "./containers/RedirectPurl";
//import MouseOverPopover from "./containers/test";


  export default ({ childProps }) =>
  <Switch>
    <RouteAuthenticated path="/" exact component={Login} props={childProps}  />
    <RoutePublic path="/login" exact component={Login} props={childProps}  />
    <RouteSRCLAuthenticated path="/clients" exact component={Clients} props={childProps} />
    <RouteAuthenticated path="/ManageClient" exact component={ManageClient} props={childProps} />
    <RouteAuthenticated path="/Campaigns" exact component={Campaigns} props={childProps} />
    <RouteSRCLAuthenticated path="/Admin" exact component={Admin} props={childProps} />
    <RouteAuthenticated path="/CampaignNew" exact component={CampaignNew} props={childProps} />
    
    <RouteAuthenticated path="/Contacts" exact component={Contacts} props={childProps} />
    <RouteAuthenticated path="/CreateContactList" exact component={CreateContactList} props={childProps} />
    <RouteAuthenticated path="/dashboard/:dashboardId" exact component={Dashboard} props={childProps} />
    <RouteAuthenticated path="/dashboard/:dashboardId/sheet/:sheetId" exact component={Dashboard} props={childProps} />
    
    {/* <RoutePublic path="/test" exact component={MouseOverPopover}  props={childProps}/> */}
    <RoutePublic  path="/changepassword" exact component={ChangePassword} props={childProps}/>
    <RouteAuthenticated path="/faq" exact component={Faq} props={childProps} />
    <Route path="/unauthorized" exact component={Unauthorized} />
    <Route path="/:purl" exact component={RedirectPurl} />
    <Route component={RedirectPurl} />
  </Switch>;
