import React, {Component } from "react";
import { Link, BrowserRouter as Router, Route } from "react-router-dom";
import "../css/rrr.css";
import { Auth } from "aws-amplify";
import AWS from 'aws-sdk';
import config from "../config";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import "react-table/react-table.css";
import queryString from 'query-string';
import LeftMenu, {PageHeader} from '../components/leftmenu';
import Form from 'react-bootstrap/Form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Switch from "react-switch";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ManageClient.css";
import getUsers, {getData} from "./SharedFunctions";
import { join } from "path";
import MUIDataTable from "mui-datatables";
import AddCircle from '@material-ui/icons/AddCircle';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import PropTypes from 'prop-types';
import {Grid,FormGroup,FormControl, FormLabel,RadioGroup,FormControlLabel, Radio, Button, MenuItem, TextField} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

let pkgDataSource = [];

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  fab: {
    // margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    // bottom: theme.spacing(2),
    // right: theme.spacing(3),
  },  
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: red[800],
    },
  },
  menu: {
    width: 300,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150,
    backgroundColor: "white"
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: "#f7f7f7"
  },
  table: {
    marginTop: theme.spacing.unit * 2,
    width: "75%"
  },  
  title: {
    color: "#00956e",
    marginTop: theme.spacing(2),
    fontWeight:"fontWeightBold",
    fontFamily: "AvenirNextLTPro",
    height: "24px",
    fontSize: "14px",
  }, 
  title_grey: {
    color:grey[400],
    fontWeight:"fontWeightLight",
    marginTop: theme.spacing(2),
    marginRight: "40px",
    fontFamily: "Ebrima",
    fontSize: "14px",
    fontStyle:"italic",
  }, 
  submit: {
    //margin: theme.spacing(3, 0, 2),
    backgroundColor:"#1782c5",
    marginTop: "18px", 
    height:"53px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
});

class ManageClient extends Component {
  
  constructor(props) {
    super(props);

      //const clientinfo = {clientid:null};
    const file= null 

    this.state = {
      checked: false,
      isLoading: false,
      isFormLoading : false,
      isBillingFormLoading : false,
      myclientid:0,
      dataSource: [],
      customerSource:[],
      plan: "",
      logo: null,
      clientid: null,
      isPrimary:0,
      hideForm : true,
      hideBillingForm : true,
      hidePlanForm : true,
      clientid: null,
      contextid:null,
      newUser: null,
      userCreated: false,
      email: "",
      firstname: "",
      lastname: "",
      phone: "",
      text:"",
      logoURN:"",
      emailInvalid:false,
      emailExists:false,
      availableUsers: 0,
      activeUsers: 0,
      packagesDataSource: [],
      usersDataSource: [],
      billingDivSource: [],
      emailBody:'',
      emailSubject:'',
      uploadedFile: null,
      clientname: null,
      cname:'',
      isBillingUser:false,
      billingDivision:'Corporate'
    };

    this.handleSwitchChange = this.handleSwitchChange.bind(this);

  }


  handlePlanClick = event => {
    event.preventDefault()
    this.setState({
      hidePlanForm : false
    });
  }

  handlePlanCancel = event => {

    this.setState({
      hidePlanForm : true
    });
  }

  handleClick = event => {
  event.preventDefault()

    if(this.state.availableUsers - this.state.activeUsers === 0)
    {
      toast.info("No available users.", {
        position: toast.POSITION.TOP_RIGHT, hideProgressBar: true  })   
      return;
    }
    
    this.setState({
      hideForm : false,
      emailInvalid:false,
      emailExists:false,
      firstname:'',
      lastname:'',
      email:'',
      phone:''
    });
  }

  handleCancel = event => {

    this.setState({
      hideForm : true
    });
  }

  validateForm = event => {
    return (
      this.state.email.length > 0 &&
      this.state.firstname.length > 0 &&
      this.state.lastname.length > 0 
    );
  }

  validateBillingForm = () =>{
    return (
      this.state.cname.includes(" - ")
    );
  }

  validatePlanForm() {
    return (
      this.state.plan.length > 0
    );
  }

	handleChange = event => {
		this.setState({
		  [event.target.type==="radio" ?event.target.name: event.target.id]: event.target.value
		});
  }

  handleAutoCompleteChange = (event, value,reason) =>{
    //prevents resetting initial selected value on escape
    if(event.currentTarget.type==='text' && reason==='reset' && value.length===0) return

    this.setState({
		  cname: value
		});
  }

  handleSelectChange = e => {
    this.setState({ plan: e.target.value });
    //this.state.text = 
  }

  removePackage = (planid,pname) => {

    confirmAlert({
      message : `Are you sure you want to delete ${pname} Package?`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Yes',
          onClick: async () => {

            let body = {
              "procname" : "procClientPlanDelete",
              "paramcount": "3", 
              "param1": planid,
              "param2": this.state.clientid,
              "param3": this.props.username
              }
          
            await getData(body)
            .then(json => this.setState({ packagesDataSource: json[0]}))
            .then(()=>toast.info(`${pname} Plan has been deleted `, {
              position: toast.POSITION.TOP_RIGHT})   )
            
             // await this.getSubscriptionPlans()

          }
        }
      ]
    }); 

  }


 handleBillingSubmit = async event => {
  this.setState({isBillingFormLoading:true})
  event.preventDefault();
  

  let {cname,customerSource,dataSource,billingDivision}=this.state
  let customerId= cname.substr(0, cname.indexOf(' ')); 

  let record = customerSource.find(x=> x.CustomerID=== parseInt(customerId));
  
  let body= {
    "procname" : "procContextInsertForNotificationSolutions",
    "paramcount": "3", 
    "param1":  record.CustomerID, //clientid
    "param2" : dataSource[0].ContextID,
    "param3" : billingDivision                
    }    
    
  await getData(body,'getdatasql')

  body= {
    "procname" : "procCustomerMasterInfoInsert",
    "paramcount": "4", 
    "param1":  record.CustomerID, //clientid
    "param2" : record.BusinessName,
    "param3": record.QuestClientID, //Customer Master ID        
    "param4": this.props.username                
    }    
    
  await getData(body)

  body= {
    "procname" : "procClientUpdate_v1_2",
    "paramcount": "9", 
    "param1":  this.state.clientid, //clientid
    "param2" : -1,
    "param3": record.CustomerID, //Customer Master ID        
    "param4": '0',
    "param5": '0',
    "param6": '0', 
    "param7": '0',
    "param8": billingDivision,
    "param9": this.props.username                
    }    
    
   await getData(body)
  .then(() => {

    toast.success('Customer Master ID has been assigned to the client'  , {
      position: toast.POSITION.TOP_RIGHT
    })  
    this.setState({isBillingFormLoading:false}) 
  })
  .catch(error => console.log(error)); 

    
  }

    //method to handle signUp submit button
handleSubmit = async event => {
  //handleSubmit = event => {
  event.preventDefault();

  if(!new RegExp(/\S+@\S+\.\S+/).test(this.state.email))
  {
    this.setState({emailInvalid: true})
    return
  }

  const username = this.state.email.toLowerCase();

      this.setState({ isLoading: false });
      try {
        const newUser =  Auth.signUp({
          username: username,
          email:username,
          password: config.TEMP_PWD,
          attributes: {
            name: this.state.firstname +  ' ' + this.state.lastname
          }
        });

    //success


    //create DB user
    //get packages
    try {
      let body= {
        "procname" : "procUserInsert",
        "paramcount": "15", 
        "param1": username, //username
        "param2" : this.state.clientid, //clientid ext
        "param3" : this.state.firstname, 
        "param4" : this.state.lastname, 
        "param5" : "", //addr1
        "param6" : "", //addr2
        "param7" : "", //city
        "param8" : "", //st
        "param9" : "", //zip
        "param10" : "US", //country
        "param11" : this.state.phone, //ph
        "param12" : "", //altph
        "param13" : "0", //isprimary,
        "param14" : "1", //isactive
        "param15" : "IN" //pwdstatus
      }
    
      await getData(body)
      .then(async json => {
      //debugger;

      if(json.errorMessage === undefined)
      {
        this.setState({   
          email : "",
          firstname : "",
          lastname : "",
          phone : "",
          hideForm : true, 
          userCreated: true,
          isFormLoading: false,
        })  
        toast.success("User has been added", {position: toast.POSITION.TOP_RIGHT})        
        await this.getUsers();    
      }
      else{
        this.setState({     
          userCreated: false,
          isFormLoading: false,
          emailExists: true
        })   
        // toast.error("An active user with the given email already exists", {position: toast.POSITION.TOP_RIGHT})               
      }
    })
  }
  catch (e) {
    console.log('my error : ' + e.message);
  }

  //await this.getUsers();
  } 
  catch (e) {
    alert(e.message);
  }
  this.setState({userCreated: false, isFormLoading: false });
 
}

async getClientSubscriptionPlans(){
  // console.log(this.state.clientid)
  let body= {
    "procname" : "procClientPlanSelect",
    "paramcount": "1", 
    "param1": this.state.clientid
  }

  await getData(body)
  .then(json => this.setState({ packagesDataSource: json[0], isLoading: false}));  
  //.then(this.setState(this.state));
}

 //method to handle signUp submit button
	handlePlanSubmit = async event => {

  let body= {
    "procname" : "procClientPlanInsert",
    "paramcount": "3", 
    "param1": this.state.clientid, //username
    "param2" : this.state.plan, //clientid ext
    "param3" : this.props.username
  }

    
    event.preventDefault();
    await getData(body)
    .then(json => this.setState({ packagesDataSource: json[0], isLoading: false}))
    //.then(await this.getClientSubscriptionPlans())
    .then(() => {

      // console.log(json)
        this.setState({
          plan : "",
          hidePlanForm : true, 
          //userCreated: true,
          isFormLoading: false
        })  
        toast.success("New subscription plan has been added", {
        position: toast.POSITION.TOP_RIGHT
      })            
    })    
    //.then(this.setState(this.state))
    .catch(error => console.log(error)); 
  }

	//method to handle signUp submit button
	async componentDidMount() {

    // console.log('props');
    // console.log(this.props);

    this.state.clientid = this.props.clientid;

    if(this.state.clientid===-1)
    {
      this.props.history.push({
        pathname: './clients',
        state: {clientid: -1, email: this.props.username}
      });
    }
    // console.log (this.state.clientid + ',  ' + this.props.isSRCLUser )


    let body= {
      "procname" : "procClientSelect",
      "paramcount": "2", 
      "param1": this.state.clientid,
      "param2" : ""
    }

     await getData(body)
    .then(json => {
      this.setState({ dataSource: json[0], availableUsers : json[0][0].AvailableUsers, isLoading: true, clientname: json[0][0].ClientName, emailBody: json[1][0].emailBody, emailSubject: json[1][0].emailSubject})
    })
    .then(async ()=>{
      await this.getClientSubscriptionPlans()
      await this.getUsers()
      await this.getSubscriptionPlans()
      
      if(this.props.isSRCLUser)
      {
        await getUsers(0,this.props.username)
        .then(async (json) => {
          let isInRole = json[0][0].Role!== null && json[0][0].Role==='BIL'
          this.setState({isBillingUser: isInRole,hideBillingForm:false})

          if(isInRole)
          body= {
            "procname" : "procBillingDivisionSelect",
            "paramcount": "0"
          }
            await getData(body)
            .then(json => {
              this.setState({ billingDivSource: json[0]})
            })

            await this.getCustomers()
            .then(()=>{
                if (this.state.dataSource[0].CustomerMasterID !==null)
                {
                  let selValue = this.state.customerSource.find(x=>{return x.CustomerID=== parseInt(this.state.dataSource[0].CustomerMasterID )})
                  if(selValue!==null && selValue!==undefined)
                    this.setState({cname:selValue.CustomerID + ' - ' + selValue.BusinessName, billingDivision: this.state.dataSource[0].BillingDivision})
                }
              }
            )
        });
      }
      else
        this.setState({hideBillingForm:false})

        //TO DO selected value
      //
    });
  }
 
  async getUsers(){
    const responseUsers = await getUsers(this.state.clientid,'')
    .then(json => this.setState({ usersDataSource: json[0], activeUsers: json[0].filter(u => {return u.IsActive.data[0] === 1;}).length,  isFormLoading: false}))
  }

  async getCustomers(){
    let body =  {
      "procname": "procClientSelectByCustomerID",
      "paramcount": "0"
    } 

    await getData(body,'getdatasql')
    .then(json => this.setState({ customerSource: json}))

  }

  async getSubscriptionPlans(){
    let body =  {
      "procname" : "procSubscriptionPlanSelect",
      "paramcount": "1", 
      "param1": "1"
    }


    await getData(body)
    .then(json => {
      pkgDataSource.length = 0;
      json[0].map(el => {
        pkgDataSource.push({ label: el.PlanName + ' Plan :  Max Msg - ' +  (el.MaxMessages.toString() == '0'? 'Unlimited' : el.MaxMessages)  + ', Price - $' +  el.Price, value: el.PlanCode })  
      });

      //this.setState({isLoading: false});
     // console.log(pkgDataSource)
    })
  }  

  handleSwitchChange = (checked,event,id) => {
    if(this.state.availableUsers - this.state.activeUsers===0 && checked)
    {
      toast.info("No available users.", {
        position: toast.POSITION.TOP_RIGHT, hideProgressBar: true  })   
      return;
    }

    confirmAlert({
      message : `Are you sure you want to ${!checked ? 'de' : ''}activate the user?`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Yes',
          onClick: () => this.updateUser(id, checked)
        }
      ]
    });  
  }

  updateUser = async (id, checked) =>{
    let body= {
      "procname" : "procUserUpdateStatus",
      "paramcount": "3", 
      "param1": id, //username (email)
      "param2" : !checked ? '0' : '1', //active flag
      "param3": '' //status
    }

    await getData(body)
    .then(() => 
      {
        let {usersDataSource} = this.state
        usersDataSource.filter(u=>{return u.UserName === id;})[0].Active = !checked ? 'Inactive' : 'Active'
        usersDataSource.filter(u=>{return u.UserName === id;})[0].IsActive.data[0] = !checked ? 0 : 1
        this.setState({usersDataSource, hideForm : true,activeUsers: usersDataSource.filter(u => {return u.IsActive.data[0] === 1;}).length})
        toast.success(`User is ${!checked ? 'de' : ''}activated`, {
          position: toast.POSITION.TOP_RIGHT
        })        
    })
  }


  render() {


    let {dataSource,isLoading,usersDataSource,hideBillingForm} = this.state;

    const { classes } = this.props;    

    if(dataSource.length === 0 || hideBillingForm) //wait until the last of all async functions is loaded
      return <div />
    
    const columns = [
      {
        label: 'Name',
        name: 'FullName',
        options: {
          //filter: true,
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                                    
        },
      },
      {
        label: 'Email',
        name: 'UserName',
        options: {
          sortDirection: "asc",
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
      },
    },
    {
      label: 'Phone',
      name: 'Phone',
      options: {
        sortDirection: "asc",
        setCellHeaderProps: (value) => {
          return {
              style: {fontWeight: "bold"}
          };
        },                
    },
  },    
      {
        label: 'Active',
        name: 'Active',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log(tableMeta)
            return (
              <Switch height={16} width={35} id={tableMeta.rowData[1]} onChange={this.handleSwitchChange} checked={value === 'Active'  ? true : false} />        
            );              
        },           
        },
      },
    ];    

    const options =  {
      filter: false,
      //filterType: 'multiselect',
      responsive: 'stacked', //scroll, 'scrollMaxHeight'
      page: 0,
      rowsPerPage: 5,
      rowsPerPageOptions:  [5, 10, 20, 25],
      //searchText: this.state.searchText,
      // searchOpen: true,
      search:true,
      searchPlaceholder: 'Search...',
      selectableRows: false,
      print: false,
      download: false,
      viewColumns: false,
      customToolbar: null,
    }    

    const title =  (!this.props.isSRCLUser) 
                  ? this.state.clientname
                  : (!this.state.dataSource[0].IsActive.data[0] ) 
                          ? `${this.state.clientname} (ID ${this.state.clientid}) - DISABLED` 
                          : `${this.state.clientname} (ID ${this.state.clientid})` 

    return (

      <div style={{width:"100%", borderStyle:"solid", borderColor:this.props.siteConfig.srclColor, borderWidth:"0px"}}>

        <LeftMenu selected="account" confirmationUnsaved_LM={(tmp) => event => {return true}} clientid={this.state.clientid} props={this.props}/>

        <div className="MainInnerWrapperDiv">
          <PageHeader title={title} username={this.props.username}/>
          <div style={{float:"left", width : "90%", paddingLeft:"30px" }}>
          <p>
            <label class="subtitle_page"> Packages</label>
          </p>
          {(!this.props.isSRCLUser) ?
          <p><a href={"mailto:notificationsolutions@stericycle.com?" +
            "subject=" + (this.state.emailSubject.replace('[client name]',this.state.dataSource[0].ClientName).replace(' ','%20')).toUpperCase() +
            "&body=" + this.state.emailBody.replace('[client name]',this.state.dataSource[0].ClientName).replace(' ','%20')}  data-rel="external"><font size="3">
              <img width="20" height="20" src={require('../images/add.png')}></img>&nbsp;Request Additional Packages</font></a><br />
          </p> : (!this.state.dataSource[0].IsActive.data[0] ) ? <div/> :
            <>  <Link to = {{pathname: "ManageClient",  state: {clientid: this.state.clientid}}} onClick={this.handlePlanClick} style={{display: 'flex',fontSize:"15px",alignItems: 'center'}} >
              <AddCircle style={{color: "#1782c5",paddingRight:"3px" }}/>Add New Subscription Plan</Link> <p/>   </>  
          }

          {(!this.state.hidePlanForm) && planform(this,classes)}
          <div style={{width:"100%", float:"left", display:"inline-block"}}>
            {this.state.packagesDataSource.map(item => 
              <Plan key={item.PlanCode} obj={this} plan={item}/>)}
          </div>

          </div>
          <div style={{float:"left", width : "70%", paddingLeft:"30px"}}>
            <label class="subtitle_page">Users</label>
            <p/>
            <Link to = {{pathname: "ManageClient",  state: {clientid: this.state.clientid}}} onClick={this.handleClick} style={{display: 'flex',fontSize:"15px",alignItems: 'center'}} >
            <AddCircle style={{color: "#1782c5",paddingRight:"3px" }}/>Invite User ({this.state.availableUsers - this.state.activeUsers} left)</Link> <p/>                   
            {(!this.state.hideForm) && userForm(this,classes)}                        
            <MUIDataTable className={classes.table} data={usersDataSource} columns={columns} options={options} />
          </div>
          <div style={{float:"left", width : "90%", paddingLeft:"30px", paddingTop:"30px" }}>
          {(this.state.isBillingUser && !this.state.hideBillingForm) && billingForm(this,classes)} 
          </div>
        </div>
      </div> 
    );
  }
}

ManageClient.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageClient);

      
//Subscription plans
const Plan = (props, obj) => 

  <div style={{float:"left", width:"30%", height:"200px", borderRadius: "12px", paddingLeft:"0px", marginTop:"30px", marginRight:"30px",
  borderWidth:"0px", paddingRight:"0px", 
   borderStyle:"solid", borderColor:"black", display:"inline-block", boxShadow:"0 2px 6px 0 rgba(0,0,0,0.2) ", 
    "border-width":"0px", "border-style":"solid", "background-color": "#1e2859;"}}>

    <div style={{ "width" : "40%", "height":"200px","float":"left", 
      "border-width":"1px", paddingTop:"5px",borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", 
      "border-style":"solid", "border-color":"black", "background-color": "#1e2859"
      }}>
      <div style={{ paddingTop:"10px"}}><p class="plan_title">{props.plan.PlanName}
        
        </p></div>

      <div style={{"padding-left":"10px"}}>
        <p style={{"font-family": "Arial, Helvetica, sans-serif", 
          "font-size": "10px", "font-weight": "normal", lineHeight:"50%",
          "color": "#ffffff", "letterSpacing":"1.87px", "opacity":"0.7"}}>STARTS
        </p>
        <p style={{"font-family": "Arial, Helvetica, sans-serif", "font-size": "12px", paddingBottom:"20px",
          lineHeight:"50%","font-weight": "normal", "color": "#ffffff",
          "letterSpacing":"1.87px"}}>{props.plan.DtStart}
        </p>
      </div>

      <div style={{"padding-left":"10px"}}>
        <p style={{"font-family": "Arial, Helvetica, sans-serif", 
        "font-size": "10px", "font-weight": "normal", lineHeight:"50%",
        "color": "#ffffff", "letterSpacing":"1.87px", "opacity":"0.7"}}>EXPIRES</p>
        <p style={{"font-family": "Arial, Helvetica, sans-serif", "font-size": "12px", lineHeight:"50%",
        "font-weight": "normal", "color": "#ffffff", "letterSpacing":"1.87px"}}>{props.plan.DtEnd}</p>
      </div>
  </div>
  <div style={{"width" : "30%", "float":"left", "text-align":"center", 
      "padding-top": "15px", "border-width":"0px", 
      "border-style":"solid", "border-color":"black",
      }}>
    <div style={{paddingLeft:"15px", "text-align":"left", paddingTop:"15px"}}>
      <p style={{"font-family": "Arial, Helvetica, sans-serif", 
        "font-size": "12px", "font-weight": "500", "line-height":"2px",
        "color": "#666666", "letterSpacing":"1.17px"}}>TOTAL
      </p>
      <p style={{"font-family": "Arial, Helvetica, sans-serif", 
      "font-size": "24px", "font-weight": "bold", align:"left", "line-height":"10px","margin-top": "13px",
        "color": "#000000"}}>{props.plan.PctAvailable!==null? props.plan.MaxMessages : 'No Limit'}
      </p>
      <p style={{"font-family": "Arial, Helvetica, sans-serif", paddingTop:"35px",
        "font-size": "12px", "font-weight": "500", "line-height":"2px",
        "color": "#666666", "letterSpacing":"1.17px"}}>AVAILABLE
      </p>
      <p style={{"font-family": "Arial, Helvetica, sans-serif", 
      "font-size": "24px", "font-weight": "bold", align:"left", "line-height":"15px",
        "color": "#000000"}}>{props.plan.PctAvailable!==null? props.plan.AvailableMsgs : 'No Limit'}
      </p>
    </div>
  </div>

  <div style={{"width" : "30%", "float":"left", "text-align":"center", 
      "padding-top": "15px", "border-width":"0px", 
      "border-style":"solid", "border-color":"black",
      }}>
      {(props.obj.props.isSRCLUser && props.plan.MsgUsed==0)?
      <Tooltip title="Delete">
      {/* <IconButton aria-label="delete"> */}
      <Delete id={props.plan.PlanID} style={{"margin-right":"2px",float:"right",color: "#1782c5",}} 
        onClick={()=>{props.obj.removePackage(props.plan.ClientPlanID,props.plan.PlanName)}}  /> 
      {/* </IconButton> */}
    </Tooltip>
:
        ''
      }        
    <div style={{paddingLeft:"15px", "text-align":"left", paddingTop:"15px"}}>
      <p style={{"font-family": "Arial, Helvetica, sans-serif", 
        "font-size": "12px", "font-weight": "500", "line-height":"2px",
        "color": "#666666", "letterSpacing":"1.17px"}}>USED
      </p>
      <p style={{"font-family": "Arial, Helvetica, sans-serif", 
      "font-size": "24px", "font-weight": "bold", align:"left", "line-height":"15px",
        "color": "#000000"}}>{props.plan.MsgUsed}
      </p>
      <p style={{"font-family": "Arial, Helvetica, sans-serif", paddingTop:"30px",
        "font-size": "12px", "font-weight": "500", "line-height":"2px",
        "color": "#666666", "letterSpacing":"1.17px"}}>REMAINING
      </p>
      <p style={{"font-family": "Arial, Helvetica, sans-serif", 
      "font-size": "24px", "font-weight": "bold", align:"left", "line-height":"15px",
    "color": "#000000"}}>{props.plan.PctAvailable}{props.plan.PctAvailable!==null? '%' : ''}
      </p>
    </div>
  </div>
</div>  


const  billingForm = (caller,classes) => 
<form onSubmit={caller.handleBillingSubmit} noValidate autoComplete="off">
  <div style={{width:"90%"}}>
  <FormLabel class="subtitle_page" >BIlling</FormLabel>
    </div> 

  <div>
  <Grid container spacing={1}>
          <Grid item  sm={'auto'} >
          <Autocomplete
              id="cname"
              options= {caller.state.customerSource}
              getOptionLabel={(option) => option.CustomerID + ' - ' + option.BusinessName}
              filterOptions={
                createFilterOptions({
                  //matchFrom: 'any',//'start'
                  startAfter: 2,
                  limit:15,
                  stringify: (option) => option.CustomerID + ' - ' + option.BusinessName,
              })}
              onInputChange={caller.handleAutoCompleteChange}
              //autoComplete={true}
              autoHighlight={true}
              autoSelect={true}
              inputValue={caller.state.cname}
              renderInput={params => (
                <TextField {...params} 
                  //id="cname"
                  label="Customer Name" 
                  helperText="Minimum of 3 characters is required to search"
                  //value={this.state.cname}
                  //onChange={this.handleChange}                
                  style={{width:"600px"}} 
                  className={classes.textField}
                  margin="normal"
                  placeholder="[customer id - customer name]"
                  variant="outlined"
                  InputLabelProps={{shrink: true}}                
                />
              )}
              renderOption={(option, { inputValue }) => {
                const matches = match(option.CustomerID + ' - ' + option.BusinessName, inputValue);
                const parts = parse(option.CustomerID + ' - ' + option.BusinessName, matches);
                //will only highlight the beginning of the word, NOT anywhere
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />          
          </Grid>
          <Grid item sm={'auto'}>
          <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Division</FormLabel>
              <RadioGroup aria-label="billingDivision" name="billingDivision" value={caller.state.billingDivision} onChange={caller.handleChange}>
              {
                caller.state.billingDivSource.map(item => (
                  <FormControlLabel
                    value={item.BillingDivision}
                    control={<Radio color="primary" />}
                    label={item.BillingDivision}
                  />
                ))                
              }
              </RadioGroup>
            </FormControl>            
          </Grid>
          <Grid item xs={3}>
          <Button
            type="submit"
            alignItems="flex-end"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!caller.validateBillingForm()}
          >
          {caller.state.isBillingFormLoading ? 'Assigning�' : 'Assign'}
        </Button>
          </Grid>
        </Grid>
      </div>
</form>


const  planform = (caller,classes) => 
<form className={classes.container} onSubmit={caller.handlePlanSubmit} noValidate autoComplete="off">
  <div style={{width:"90%", backgroundColor: "#f7f7f7;"}}>
  <FormLabel class="add_section_title" >Plan</FormLabel>
    </div> 

<div>
<TextField
          id="plan"
          select
          label="Package"
          className={classes.textField}
          required
          value={caller.state.plan}
          style={{width:"450px"}}
          onChange={caller.handleSelectChange}
          //helperText="Please select subscription plan"
          margin="normal"
          variant="outlined"
          InputLabelProps={{shrink: true}}
        >
          {pkgDataSource.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      <Button
          //type="submit"
          style={{backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"}}
          variant="outlined" //color="secondary"     
          className={classes.submit}
          alignItems="flex-end"
          onClick = {caller.handlePlanCancel}      
      >
        Cancel
      </Button>
      <Button
          type="submit"
          alignItems="flex-end"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!caller.validatePlanForm()}
        >
        {caller.state.isFormLoading ? 'Adding�' : 'Add'}
      </Button>     

      </div>
</form>

const  userForm = (caller,classes) => 

<form className={classes.container} onSubmit={caller.handleSubmit} noValidate autoComplete="off">
<div style={{width:"90%", backgroundColor: "#f7f7f7;"}}>
  <FormLabel class="add_section_title" >Invite User</FormLabel>
    </div> 
<div>
      <TextField
      id="firstname"
      label="First Name"
      required
      value={caller.state.firstname}
      onChange={caller.handleChange}
      className={classes.textField}
      margin="normal"
      placeholder="[first name]"
      variant="outlined"
      InputLabelProps={{shrink: true}}
      />
      <TextField
      id="lastname"
      label="Last Name"
      required
      value={caller.state.lastname}
      onChange={caller.handleChange}
      className={classes.textField}
      margin="normal"
      placeholder="[last name]"
      variant="outlined"
      InputLabelProps={{shrink: true}}
      />
      <TextField
        id="email"
        label="Email"
        required
        error={caller.state.emailInvalid || caller.state.emailExists}
        helperText={caller.state.emailInvalid ?'Invalid email format':caller.state.emailExists?"An active user with the given email already exists":''}  
        className={classes.textField}
        value={caller.state.email}
        onChange={caller.handleChange}
        style={{width:"220px"}}
        margin="normal"
        placeholder="[email]"
        variant="outlined"
        InputLabelProps={{shrink: true}}
      />
        <TextField
          id="phone"
          label="Phone"
          value={caller.state.phone}
          onChange={caller.handleChange}
          className={classes.textField}
          //style={{width:"150px"}}
          margin="normal"
          type="phone"
          placeholder="[phone]"
          variant="outlined"
          InputLabelProps={{shrink: true}}
        />
      <Button
          //type="submit"
          style={{backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"}}
          variant="outlined" //color="secondary"     
          className={classes.submit}
          alignItems="flex-end"
          onClick = {caller.handleCancel}      
      >
        Cancel
      </Button>
      <Button
          type="submit"
          alignItems="flex-end"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!caller.validateForm()}
        >
        Add
      </Button>     
      </div>
</form>


