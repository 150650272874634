/* DEV */
const dev = {
  responseUrl: "https://response.thenotifyhubdev.com/",
  s3Region: "us-east-2",
  s3EventContentBucket: "srcl-selfserve-eventcontent-dev",
  s3ContactListBucket: "srcl-selfserve-contacts-dev",
  cognitoRegion: "us-east-2",
  congitoUserPoolId: "us-east-2_Dh325FyvU",
  cognitoAppClientId: "3ltcr7f74bj14bceb5bbckpu45",
  cognitoIdentityPoolId: "us-east-2:da9c7621-deb5-49d5-a686-0149627411d5",
  cognitoIdpLoginId: "cognito-idp.us-east-2.amazonaws.com/us-east-2_Dh325FyvU",
  apiRegion: "us-east-2",
  apiUrl: "https://92eyo7p0l7.execute-api.us-east-2.amazonaws.com/",
  apiEnv: "Dev/",
  quicksightClientDashboardId: "dc193cad-eddf-4e44-9ea4-fda296eb4419",
  quicksightSrclDashboardId: "4e557509-316c-4267-ad4f-1efa157d1f9f"
}

/* QA */
const qa = {
  responseUrl: "https://response.thenotifyhubqa.com/",
  s3Region: "us-east-2",
  s3EventContentBucket: "scmsbp-notifyhub-qa-eventcontent",
  s3ContactListBucket: "scmsbp-notifyhub-qa-contacts",
  cognitoRegion: "us-east-2",
  congitoUserPoolId: "us-east-2_856aoOmJF",
  cognitoAppClientId: "4lkov0unho3hek7c63e9egm84o",
  cognitoIdentityPoolId: "us-east-2:cc4d1a88-b039-41f4-ba7e-474e91e5c121",
  cognitoIdpLoginId: "cognito-idp.us-east-2.amazonaws.com/us-east-2_856aoOmJF",
  apiRegion: "us-east-2",
  apiUrl: "https://5bwuvtzrj4.execute-api.us-east-2.amazonaws.com/",
  apiEnv: "QA/",
  quicksightClientDashboardId: "2b53ea54-c438-4213-ab7c-d0e22af19418",
  quicksightSrclDashboardId: "1541b798-a168-4777-bd2e-8c6594b586fc"
}

/* PROD */
const prod = {
  responseUrl: "https://response.thenotifyhub.com/",
  s3Region: "us-east-2",
  s3EventContentBucket: "scmsbp-notifyhub-prod-eventcontent",
  s3ContactListBucket: "scmsbp-notifyhub-prod-contacts",
  cognitoRegion: "us-east-2",
  congitoUserPoolId: "us-east-2_YUEewxaL4",
  cognitoAppClientId: "3qi779glp25fipv77fs8vajjgg",
  cognitoIdentityPoolId: "us-east-2:8b63d8e8-f221-46bc-a62b-86280a89dc58",
  cognitoIdpLoginId: "cognito-idp.us-east-2.amazonaws.com/us-east-2_YUEewxaL4",
  apiRegion: "us-east-2",
  apiUrl: "https://w0uboroi4c.execute-api.us-east-2.amazonaws.com/",
  apiEnv: "PROD/",
  quicksightClientDashboardId: "a6cbd413-d725-4958-aed0-0ec3e525a442",
  quicksightSrclDashboardId: "6b27ad65-ea31-4204-8b7e-bf3606ab4321"
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : (process.env.REACT_APP_STAGE === 'qa' ? qa : dev);

export default {
  APP_VERSION: process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '1.0.0',
  MAX_ATTACHMENT_SIZE: 5000000,
  TEMP_PWD: "SCx5@#58",
  /*SRCL_Color: "#00956e", */
  SDWK_Color: "#007dbc",
  /*SRCL_color_light: "#005745",*/
  SDWK_color_light: "#d0ebf2",
  site_height:"150vh",
  scheduleIncrements: 15,
  //RESPONSEURL : 'http://srcl-selfserve-response-staticweb-dev.s3-website.us-east-2.amazonaws.com/',
  RESPONSEURL : config.responseUrl, //'https://response.thenotifyhubdev.com/',
  s3: {
    REGION: config.s3Region,//"us-east-2",
    BUCKET: config.s3EventContentBucket, //"srcl-selfserve-eventcontent-dev",
    ContactTemplateFile: "RRRTemplate.csv",
    ContactListBUCKET: config.s3ContactListBucket, //"srcl-selfserve-contacts-dev"
    ForbiddenWordsList: "fwl.txt"
  },
  // apiGateway: {
  //   REGION: "us-east-2",
  //   URL: "https://265x69lj94.execute-api.us-east-2.amazonaws.com/test/"
  // },
  cognito: {
   REGION: config.cognitoRegion, // "us-east-2",
   USER_POOL_ID: config.congitoUserPoolId,// "us-east-2_Dh325FyvU",
   APP_CLIENT_ID: config.cognitoAppClientId, // "3ltcr7f74bj14bceb5bbckpu45",
   //IDENTITY_POOL_ID: "us-east-2:dc9e3b86-cdb1-446a-a01a-afb0f1b392aa",
   IDENTITY_POOL_ID: config.cognitoIdentityPoolId,// "us-east-2:da9c7621-deb5-49d5-a686-0149627411d5",
   IDP_LOGIN_ID: config.cognitoIdpLoginId, // "cognito-idp.us-east-2.amazonaws.com/us-east-2_Dh325FyvU"
  },
  apiGateway: {
    REGION: config.apiRegion, // "us-east-2",
    URL: config.apiUrl, //"https://92eyo7p0l7.execute-api.us-east-2.amazonaws.com/",
    ENV: config.apiEnv, //"Dev/",
    OBJ : {
      mode: 'cors',
      method: 'POST',
      headers: 
      {
          'Access-Control-Allow-Origin': '*', 
          'Access-Control-Allow-Headers': 'Content-Type',
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      }},
      body:''
  },
  quicksight: {
    CLIENT_DASHBOARD_ID: config.quicksightClientDashboardId, // "dc193cad-eddf-4e44-9ea4-fda296eb4419",
    SRCL_DASHBOARD_ID: config.quicksightSrclDashboardId, // "4e557509-316c-4267-ad4f-1efa157d1f9f"
  }
};

