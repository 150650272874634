import React from "react";
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Login.css";
import LoaderButton from "../components/LoaderButton";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthenticateUser from '../libs/AuthService';
// import {ForgotPassword} from "../containers/ForgotPassword";
//import ForgotPassword from './ForgotPassword';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Auth } from 'aws-amplify';
import Config from '../config';
import Info from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
//import MouseOverPopover from "../containers/test";
import InputAdornment from '@material-ui/core/InputAdornment';


export default class Login extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: false,
      email: "",
      password: "",
      clientColor: this.props.siteConfig.srclColor,
      isForgotPassword:false,
      //--------------
      isEmailInvalid:false,
      confirmCode: "",
      newPassword: "",
      confirmNewPassword: "",
      currentForm: 1,
      anchorEl: null,
      emailRegex : new RegExp(/\S+@\S+\.\S+/),
      passwordRules: [<div>Your password must contain:
        <br/><span style={{marginLeft:"15px"}}>•	A minimum of 8 characters</span>
        <br/><span style={{marginLeft:"15px"}}>•	At least 1 number</span>
        <br/><span style={{marginLeft:"15px"}}>•	At least 1 special character <br/><span style={{marginLeft:"22px"}}>^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , ' : ; | _ ~ ` </span></span>
        <br/><span style={{marginLeft:"15px"}}>•	At least 1 upper case character</span></div>]
    };
//debugger;
    this.isForget = this.isForget.bind(this)
    this.handleUsernameSubmit = this.handleUsernameSubmit.bind(this);
  }

 regExp = str => {
   //debugger;
   return (
    this.state.emailRegex.test(str)
   )
 }
  
  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };


  isForget(flag) { 
    this.setState({currentstep: 1, isForgotPassword:flag,isEmailInvalid :false}); 
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  Validated() {
    return(
      (this.state.currentForm===1  && this.state.email.length > 0) ||
      (this.state.currentForm===2  && this.state.confirmCode.length>0 && this.state.newPassword.length > 0 && this.state.confirmNewPassword.length >0 && this.state.newPassword === this.state.confirmNewPassword)
    )
}

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,isEmailInvalid:false
    });

  }

  handleUsernameSubmit(e) {
    e.preventDefault();

    const username = this.state.email.toLowerCase();
    if(!this.regExp(username)) {
      this.setState({ isEmailInvalid : true})
      return
    }
    switch (this.state.currentForm) {
      case 1:
        Auth.forgotPassword(username)
            .then(data => {
                //console.log(data)
                this.setState({currentForm: 2});
            })
            .catch(err => toast.error(err.message, {position: toast.POSITION.TOP_RIGHT}));              
        return 
      case 2:   
        Auth.forgotPasswordSubmit(username, this.state.confirmCode, this.state.newPassword)
            .then(data => {
                toast.info('Your password has been successfully changed', {position: toast.POSITION.TOP_RIGHT})
                this.setState({isForgotPassword:false});  
            })
            .catch(err => toast.error(err.message, {position: toast.POSITION.TOP_RIGHT}));
    }

}  

	handleSubmit = async event => {
	  event.preventDefault();
    
    const username = this.state.email.toLowerCase();
    if(!this.regExp(username)) {
      this.setState({ isEmailInvalid : true})
      return
    }

    this.setState({ isLoading: true });

    try {
      let userAuthResult = await AuthenticateUser(username, this.state.password);

      if(userAuthResult.passwordStatus === "IN") {
        this.props.userHasAuthenticated(true, userAuthResult.clientId, userAuthResult.username);
        this.props.history.push({pathname:'/changepassword', state: { passwordStatus: "IN" }});
      }
      else {

        this.props.userHasAuthenticated(true, userAuthResult.clientId, userAuthResult.username);
        let toPage = userAuthResult.clientId > 0? 'ManageClient' : 'clients'
        this.props.history.push({pathname: `./${toPage}`,state: {clientid: userAuthResult.clientId}});
      }

    } catch(e) {
      // console.log(e);

      if(e.message === "NEW_PASSWORD_REQUIRED") {
        this.props.setForcePasswordReset();
        this.props.history.push({
          pathname: '/changepassword',
          state: { username: username }
        });
      } else {
        toast.error(e.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true });
        this.setState({ isLoading: false });
      }
      
    }
  }
  
  // async componentDidMount() { 
  //   console.log("Login.js this.props", this.props);
    
  // }


render(){
  return(
    <div style={{float:"left", width:"100%", height:"100%",  backgroundImage: `url("${require(`../images/login_bgImage_sm.jpg`)}")`,
     backgroundPosition: 'center', backgroundSize: "cover", position:"absolute", zIndex:"1"}} >
    <div style={{float:"right", 
                height:"100%",
                "background-color": "#ffffff",
                width:"50%",
                marginLeft:"50%",
                letterSpacing: "normal",       
                paddingTop: "7%",
                //paddingLeft: "10%",
                position:"absolute", 
                zIndex:"3"}}>   
          {(!this.state.isForgotPassword) ? <SignIn obj={this}/> 
                                          : <ForgotPassword obj={this} />
          }          
    </div>        
          <div style={{ paddingLeft:"20px",marginBottom:"10px", marginTop:"10px"}}>
          <img align="center" style={{height:"62px", paddingLeft:"0px", paddingTop:"0px"}} src={require(`../images/SDWK_PrimaryLogo_Black.png`)}/>
          {/* ${Link} */}
          <Link onClick={()=>{ window.open(`https://www.sedgwick.com/brandprotection/`) }} to="#"><label align="left" style={{fontSize:"14px", paddingLeft:"50px"}}><font size="2" color="black"><strong> PRODUCTS</strong></font></label></Link>
          <Link onClick={()=>{ window.open(`https://www.sedgwick.com/terms-conditions`) }} to="#"><label align="left" style={{paddingLeft:"50px"}}><font size="2" color="black"><strong> TERMS</strong></font></label></Link>
          <Link onClick={()=>{ window.open(`https://www.sedgwick.com/global-privacy-policy/`) }} to="#"><label align="left" style={{paddingLeft:"50px"}}><font size="2" color="black"><strong> PRIVACY</strong></font></label></Link>
          </div> 

        <p style={{width:"50%", paddingLeft:"20px",marginTop:"30px",fontFamily: "Ebrima",fontWeight: "bold",fontSize:"45px"}}>Incident Management Made Easy</p>
        <p style={{width:"25%", paddingLeft:"20px",marginTop:"50px",fontFamily: "Ebrima", fontWeight: "normal" }}>Initiate, manage, automate and reconcile customized compliance communications in one solution.</p> 
    </div>
  
  );
}
}


function styles(theme){ return ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  // avatar: {
  //   margin: theme.spacing(1),
  //   backgroundColor: theme.palette.secondary.main,
  // },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:"blue"
  },
  button: {
    fontSize:"16px",
    marginTop: theme.spacing(2),
    // fontFamily: "Ebrima",
    color: Config.SDWK_Color, //Config.SRCL_Color,
    marginLeft:"37%"
  },    
  link: {
    fontSize:"14px",
    marginTop: theme.spacing(1),
    // fontFamily: "Ebrima",
    //color: Config.SRCL_Color,
    marginLeft:"35%"
  },    
  title: {
    color: Config.SDWK_Color, //Config.SRCL_Color,
    marginTop: theme.spacing(2),
    fontFamily: "Ebrima"
  },
  popover: {
    pointerEvents: 'none',
  },
  paper_popover: {
    padding: theme.spacing.unit,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: "red",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
})}

function ForgotPassword(caller) {

  const useStyles = makeStyles(theme => (styles(theme)));
  // console.log(caller)
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs" align="left">
    <CssBaseline />
    <Typography component="h1" variant="h5"  className={classes.title} >
      {/* style={{color: caller.obj.props.siteConfig.srclColor }}> */}
    {/* Welcome to the Sedgwick Notiﬁcation Solutions™ Hub */}
    Notiﬁcation Solutions™
      </Typography>      
    <div className={classes.paper}>
      {/* <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar> */}
      {caller.obj.state.currentForm===1? ForgotPassword_step1(caller) : ForgotPassword_step2(caller)} 
      <br/>
        <Link onClick={()=>caller.obj.isForget(false)} className={classes.link} to="#">Cancel</Link>   
    </div>
    {/* <Box style={{position: "fixed",bottom: "10px",marginLeft:"5%"}}>
          <Typography variant="body2" color="textSecondary" align="center">
          {`Copyright © Sedgwick ${new Date().getFullYear()}`}
        </Typography>
      </Box> */}
  </Container>    
  );
}

function ForgotPassword_step1(caller) {
  const useStyles = makeStyles(theme => (styles(theme)));

  const classes = useStyles();
  return (
    <form className={classes.form} validate onSubmit={caller.obj.handleUsernameSubmit}> 
    <Typography component="h1" variant="h6">
      Forgot Password
    </Typography>
    <Typography variant="caption" display="block" gutterBottom>
        Please submit your email address below to begin <br/> the Forgot Password procedure
    </Typography>
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      error={caller.obj.state.isEmailInvalid}
      helperText={caller.obj.state.isEmailInvalid ?'Invalid email format':''}
      value={caller.obj.state.email} 
      onChange={caller.obj.handleChange}
      id="email"
      label="Email"
      name="email"
      autoComplete="email"
      autoFocus
      InputLabelProps={{shrink: true}}
    />
    <LoaderButton
        block
        bssize="large"
        disabled={!caller.obj.Validated()}
        type="submit"
        style={{height:"45px", marginTop:"30px"}}
        //style={{width:"40%"}}
        //isLoading={caller.obj.state.isLoading}
        text="Submit"
        //className={classes.submit}
        //loadingText="Signing in…"
        />     
  </form>
  )
}

//let anchorEl = null

function ForgotPassword_step2(caller) {
  const useStyles = makeStyles(theme => (styles(theme)));

  const classes = useStyles();
  
  const {anchorEl} = caller.obj.state

  const open = Boolean(anchorEl);

  

  return (
    <form className={classes.form} validate onSubmit={caller.obj.handleUsernameSubmit}> 
    <Typography component="h1" variant="h6">
      Forgot Password
    </Typography>
    <Typography variant="caption" display="block" gutterBottom>
      An email has been sent with a verification code.  <br/>Please submit that verification code along with your new password below
    </Typography>
    <TextField
      variant="outlined"
      margin="normal"
      required
      className={classes.textField}
      fullWidth
      type="number"
      onChange={caller.obj.handleChange}
      id="confirmCode"
      label="Verification Code"
      name="confirmCode"
      //autoComplete="email"
      autoFocus
      InputLabelProps={{shrink: true}}
    />
    <TextField
      variant="outlined"
      margin="normal"
      required
      className={classes.textField}
      fullWidth
      //style={{width:"90%"}}
      //value={caller.obj.state.newPassword} 
      onChange={caller.obj.handleChange}
      name="newPassword"
      label="New Password"
      type="password"
      id="newPassword"
      //autoComplete="current-password"
      InputLabelProps={{shrink: true}}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Info className={classes.icon} onMouseEnter={caller.obj.handlePopoverOpen} onMouseLeave={caller.obj.handlePopoverClose}/>
          </InputAdornment>
        )
      }}
    />
            <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper_popover,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={caller.obj.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography variant="caption" display="block" >{caller.obj.state.passwordRules}</Typography>
        </Popover>
    <TextField
      variant="outlined"
      margin="normal"
      error={caller.obj.state.newPassword.length > 0 && caller.obj.state.confirmNewPassword.length > 0 && caller.obj.state.newPassword!==caller.obj.state.confirmNewPassword}
      helperText={caller.obj.state.newPassword.length > 0 && caller.obj.state.confirmNewPassword.length > 0 && caller.obj.state.newPassword!==caller.obj.state.confirmNewPassword ? 'Passwords don`t match. Try again?' : ' '}
      required
      className={classes.textField}
      fullWidth
      value={caller.obj.state.confirmNewPassword} 
      onChange={caller.obj.handleChange}
      name="confirmNewPassword"
      label="Confirm Password"
      type="password"
      id="confirmNewPassword"
      //autoComplete="current-password"
      InputLabelProps={{shrink: true}}
    />              
    <LoaderButton
        block
        bssize="large"
        disabled={!caller.obj.Validated()}
        type="submit"
        style={{height:"45px", marginTop:"30px"}}
        //style={{width:"40%"}}
        //isLoading={caller.obj.state.isLoading}
        text="Submit"
        //className={classes.submit}
        //loadingText="Signing in…"
        />     
  </form>
  )
}



function SignIn(caller) {

  const useStyles = makeStyles(theme => (styles(theme)));

  const classes = useStyles();
  
  return (
    <Container component="main" maxWidth="xs" align="left">
      <CssBaseline />
      <Typography component="h1" variant="h5"  className={classes.title} >
        {/* style={{color: caller.obj.props.siteConfig.srclColor }}> */}
      {/* Welcome to the Sedgwick Notiﬁcation Solutions™ Hub */}
      Notiﬁcation Solutions™
        </Typography>      
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h6">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={caller.obj.handleSubmit}> 
          <TextField
            variant="outlined"
            margin="normal"
            required
            error={caller.obj.state.isEmailInvalid}
            helperText={caller.obj.state.isEmailInvalid ?'Invalid email format':''}
            //style={{width:"40%"}}
            //value={caller.obj.state.email}
            fullWidth
            onChange={caller.obj.handleChange}
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            InputLabelProps={{shrink: true}}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            //style={{width:"40%"}}
            fullWidth
            onChange={caller.obj.handleChange}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            InputLabelProps={{shrink: true}}
          />
          <Link onClick={()=>caller.obj.isForget(true)} style={{fontSize:"14px"}} to="#">Forgot Password?</Link>
          {/* <Button
            type="submit"
            //style={{width:"40%"}}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!caller.obj.validateForm()}
          >
            {!caller.obj.state.isLoading ? 'Sign In' : 'Signing in...'}
          </Button> */}

          <LoaderButton
              block
              bssize="large"
              disabled={!caller.obj.validateForm()}
              type="submit"
              style={{height:"45px", marginTop:"30px"}}
              isLoading={caller.obj.state.isLoading}
              text="Sign In"
              loadingText="Signing in…"
              />   
              {/* <MouseOverPopover params={caller.obj.state.test}/> */}
        </form>
      </div>
      <Box style={{position: "fixed",bottom: "10px",marginLeft:"5%"}}>
          <Typography variant="body2" color="textSecondary" align="center">
          {`Copyright © Sedgwick ${new Date().getFullYear()}`}
        </Typography>
      </Box>
    </Container>
  );
}

