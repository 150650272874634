'use strict'
import React from 'react';
//import {FormGroup,FormControl,ControlLabel, Radio} from "react-bootstrap";
//import {RadioGroup, Radio} from "react-radio-group";
import "../css/rrr.css";
import "./CampaignContact.css";
import LoaderButton from "../components/LoaderButton";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Form from 'react-bootstrap/Form';
import {Grid} from "@material-ui/core";
import InputGroup from 'react-bootstrap/InputGroup';
import {getData} from "./SharedFunctions";
import MUIDataTable from "mui-datatables";
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { confirmAlert } from 'react-confirm-alert'; // Import
import {Checkbox} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from 'react-router-dom/Link';
import * as moment from 'moment';

import {  FormGroup, FormLabel, Button, MenuItem, TextField} from '@material-ui/core';

const styles = theme => ({
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 400,
    backgroundColor: "white"
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: "#f7f7f7",
    marginTop:theme.spacing(3),
  },
  table: {
    marginTop: theme.spacing.unit * 2,
    width: "90%"
  },  
  submit: {
    //margin: theme.spacing(3, 0, 2),
    backgroundColor:"#1782c5",
    marginTop: "18px", 
    height:"53px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
});


class CampaignContact extends React.Component {
  constructor (props) {
    super(props)
  
    this.state = {
      isLoading: false,
      campaignid:0,
      clientid: 0,
      login:'admin@stericycle.com',
      campaignname: '',
      listid: 0,
      result: 'initial',
      //confirmationCode: "",
      newUser: null,
      listSource: [],
      rowsSelected:[],
      isdirty:false,
      launchstatus:'PE',
      allowedmessagetype: '',
      messagetypes: [],
      schedules:[],
      campaignTypes:[],
      isCampaignTypesLoading:true,
      open: false,
      hasAgreed:false,
    };
  }

   componentWillMount() {

    //console.log(" in wiz1",this.props);
    if(this.props.parentCampaign === undefined)
      this.setState({
        campaignid:0
      });

    if(this.props.parentCampaign.campaignid !== 0){
      this.setState({
        campaignname:this.props.parentCampaign.campaignname, 
        campaignid:this.props.parentCampaign.campaignid,
        listid: this.props.parentCampaign.listid,
        clientid: this.props.parentCampaign.clientid,
        launchstatus: this.props.parentCampaign.campaignid===undefined ?  'PE' : this.props.parentCampaign.launchstatus ,
        login: this.props.parentProps.username,
        allowedmessagetype: this.props.parentCampaign.allowedmessagetype,
        campaigntypecode:this.props.parentCampaign.campaigntypecode,
        campaigntypeconfirmedby:this.props.parentCampaign.campaigntypeconfirmedby,
        campaigntypeconfirmedon:this.props.parentCampaign.campaigntypeconfirmedon,
        hasAgreed:(this.props.parentCampaign.campaigntypecode ? true : false)

      });
    }
  }

  async componentDidMount() {
    await this.getCampaignTypes();
    await this.getLists();
    await this.getMessageTypes();
    await this.getSchedulesForCampaign();
    window.scrollTo(0, 0);
    //console.log("after getlist:", this.state);
    
    // const {rowsSelected} = this.state
    // if(this.state.listSource!== undefined && this.state.listSource.length>0)
    // rowsSelected.push(this.state.listSource.map(e=> { return e.ListID}).indexOf(this.state.listid))

    // this.setState({rowsSelected:rowsSelected})
  }

  async getCampaignTypes(){
    let body= {
      "procname" : "procEventTypeSelectWithAgreement",
      "paramcount": "0" 
      }

    await getData(body)
    .then(json => {
      this.setState({ campaignTypes: json[0], isCampaignTypesLoading: false})
    })
  }

  async getLists(){
    const {rowsSelected} = this.state
      let body= {
        "procname" : "procListSelect",
        "paramcount": "3", 
        "param1": this.state.clientid, //clientid
        "param2": 'A', //list status available
        "param3": this.state.campaignid===undefined?0:this.state.campaignid
        }

      await getData(body)
      .then(json => {
        rowsSelected.push(json[0].map(e=> { return e.ListID}).indexOf(this.state.listid))
        this.setState({ listSource: json[0], isLoading: false,rowsSelected:rowsSelected})
      })
    }

  async getSchedulesForCampaign(){
   // debugger;
    let body = {
      "procname" : "procEventMessagingSetupSelect",
      "paramcount": "1", 
      "param1": this.props.parentCampaign.campaignid

    }

    await getData(body)
    .then(json => this.setState({ schedules: json[0]}));
  }

  handleNameChange = event => {
    this.setState({campaignname:event.target.value, isdirty:true})
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
  }

  handleMessageTypeChange = event => {
    this.setState({allowedmessagetype:event.target.value, isdirty:true})
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
  }

  handleCampaignTypeChange = event => {
    this.setState({campaigntypecode:event.target.value, isdirty:true})
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
  }

	handleSubmit = async event => {
    event.preventDefault();
//console.log(this.state.allowedmessagetype);
    if(!this.state.isdirty)
    {
      if(this.state.allowedmessagetype === 'Sms')
      {   
        this.props.goto2_5('');
        return      
      }
      this.props.goto2('');
      return
    }

    if (this.state.schedules && this.state.schedules.length > 0 && this.props.parentCampaign.listid !== this.state.listid) 
    {
      await confirmAlert({
        message : 'Changing the contact list will also delete any existing schedules. ',
        buttons: [
          {
            label: 'Cancel',
            onClick: () => {return;}
          },
          {
            label: 'Delete Schedules and Save',
            onClick: () => {this.clearScheduleAndSave(event);}
          }
        ]
      })
    } 
    else 
    {
      this.saveEventSetup(event);
    }

  }

  clearScheduleAndSave = async e => {
    let d_body = {
      "procname" : "procEventMessagingSetupDelete",
      "paramcount": "2", 
      "param1": this.state.campaignid,
      "param2": this.state.login
    }
    await getData(d_body)
    .then(async ()=>{this.saveEventSetup(e);} )
  }
  
  saveEventSetup = async e => {
    let body= {
      "procname" : "procEventSave_v1_3",
      "paramcount": "23", 
      "param1": (this.state.campaignid === undefined ? 0 : this.state.campaignid),
      "param2": this.state.clientid,
      "param3" : this.state.campaignname,
      "param4" : this.state.listid , //listid TODO
      "param5": this.state.allowedmessagetype === 'Sms'?'':0, //inNotificationMessage - reset email body when SMS
      "param6": 0, //inWebHeaderMsg
      "param7": 0, //inWebDetailedMsg
      "param8": 0, //inWebFooterMessage
      "param9": 0, //inRecallHotline
      "param10": 0, //inRemedyText
      "param11": 0, //inEventDescription
      "param12": this.state.allowedmessagetype === 'Sms'?'':0, //inNotificationSubject - reset email subject when SMS
      "param13": this.state.login, //createdby
      "param14": this.state.login, //updatedby
      "param15": this.state.allowedmessagetype === 'Sms'?'':0, //inEmail - reset email address when SMS
      "param16": 0, //inEmail inNotificationMessageCSS
      "param17": 0, //LogoURN
      "param18": 0, //inSiteColor   
      "param19": this.state.allowedmessagetype === 'Email'?'':0, //inSmsMessage - reset SMS message when Email type       
      "param20": this.state.allowedmessagetype, //inAllowMessageType       
      "param21": this.state.campaigntypecode, //inEventTypeCode       
      "param22": this.state.login, //inEventTypeConfirmedBy       
      "param23": new Date() //inEventTypeConfirmedOn       
      }
//console.log(body);
    await getData(body)
    .then(async json => {
      if(json.errorMessage === undefined)
      {
        if(this.state.campaignid === undefined)
        {
            this.props.updateCampaignStateIdOnly(json[0][0].NewCampaignID);
        }
        this.setState({ isLoading: false }); 
        this.props.updateDirtyProductFlag(false);
        if(this.state.allowedmessagetype === 'Sms')
        {
          this.props.goto2_5('Campaign Contact Info is saved');
          return     
        }

        this.props.goto2('Campaign Contact Info is saved');
      }
      else  
        this.setState({ isLoading: true, result : json.errorMessage})
    })
  }

  renderConfirmationForm(msg) {
    return (
        <Form>
            <Form.Label>{msg}</Form.Label>
            <br></br>
            
        </Form>
    );
  }

  
  validateMyForm(){
    //this.preventDefault();
    //console.log("validate:", this.state);
    if(this.state.campaignname === undefined || this.state.rowsSelected == undefined)
      return false;

    if(this.state.listSource === undefined || this.state.listSource.length === 0 || this.state.rowsSelected.length === 0)
      return false;

    return this.state.campaignname.length > 0 
    && this.state.listid > 0 
    && (this.state.allowedmessagetype??'') !== '' 
    && this.state.allowedmessagetype === this.state.listSource[this.state.rowsSelected[0]].ListMessageType
    && this.state.hasAgreed
    && this.state.campaigntypecode;
  }

  handleAgreedToTerms = (flag) =>{
    this.setState({hasAgreed:flag, isdirty:true})
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
  }

  async getMessageTypes()
  {
   // debugger;
    let body = {
      "procname" : "procMessageTypeSelect",
      "paramcount": "0" 
    }

    await getData(body)
    .then(json => {
      let messagetypesTmp= json[0].map(el => {
        return {
             ...el
        };
      });

      this.setState({ messagetypes: messagetypesTmp, isMessageTypesLoading:false})
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
        MuiTableRow: {
          root: {
            '&$selected': {
              backgroundColor: 'white'
            },
            '&$selected:hover':
            {
              backgroundColor: '#f8f8f8'
            },            
          }
        },
      }
  })

  renderForm() {
    if (this.state.isCampaignTypesLoading)
    {
      return (<div>&nbsp;</div>)
    }

    const {listSource} = this.state;
    const { classes } = this.props;

    const columns = [
      {
        label: 'ListID',
        name: 'ListID',
        options: {
          filter: false,
          display: false,                
        },
      },
      {
        label: 'List Name',
        name: 'ListName',
        options: {
          
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `List Name: ${v}` },
          //filterList: ['robtest1']
          // filterType: 'textField', // set filterType's at the column level     !!!!         
      },
    },
      {
        label: 'Message Type',
        name: 'ListMessageType',
        options: {
          
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `Message Type: ${v}` },
          filterList: [this.state.allowedmessagetype??'']
          // filterType: 'textField', // set filterType's at the column level     !!!!         
      },
    },
      {
        label: 'Created On',
        name: 'CreatedOn',
        options: {
          // sortDirection: "desc",
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          //filterType:"select",
          customFilterListOptions: { render: v => `Created On: ${v}` },             
          //filterList: ['02/28/2020']
        },
      },


      {
        label: '# of Contacts',
        name: 'Total',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `Contacts: ${v}` },
        },
      },       
      {
        label: '# Unsubscribed',
        name: 'Unsubscribed',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          //customFilterListOptions: { render: v => `Unsubscribed: ${v}` },
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log(tableMeta)
            return (          
              tableMeta.rowData[2]==='Sms' ? 'NA' : value
              );              
          },   
        },
      },  
      {
        label: '# with Mobile',
        name: 'HasPhone',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          customFilterListOptions: { render: v => `Mobile: ${v}` },
        },
      },       
      // {
      //   label: '# Opted In',
      //   name: 'OptedIn',
      //   options: {
      //     setCellHeaderProps: (value) => {
      //       return {
      //           style: {fontWeight: "bold"}
      //       };
      //     },                
      //     customFilterListOptions: { render: v => `OptedIn: ${v}` },
      //   },
      // },  
      {
        label: '# Opted Out',
        name: 'OptedOut',
        options: {
          setCellHeaderProps: (value) => {
            return {
                style: {fontWeight: "bold"}
            };
          },                
          //customFilterListOptions: { render: v => `OptedOut: ${this.state.allowedmessagetype === 'Email' ? 'NA' : v}` },
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log(tableMeta)
            return (          
              tableMeta.rowData[2]==='Email' ? 'NA' : value
              );              
          },   
        },
      }  
    ];        

    const options = {
      filter: false,
      filterType: 'multiselect',
      responsive: 'stacked', //scroll, 'scrollMaxHeight'
      page: 0,
      rowsPerPage: 5,
      rowsPerPageOptions:  [5, 10, 20, 25],
      searchText: this.state.searchText,
      searchPlaceholder: 'Search...',
      //selectableRows: false,
      selectableRows: 'single',
      //selectableRowsOnClick: true,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
       // console.log(rowsSelected, allRows);
        // console.log(listSource[rowsSelected[0].dataIndex].ListID)
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex), listid: listSource[rowsSelected[0].dataIndex].ListID, isdirty:true }); 
        if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true) 
      //  this.handleOptionChange(listSource[rowsSelected[0].dataIndex].ListID,)
      },          
      disableToolbarSelect: true,
      print: false,
      download: false,
      viewColumns: false,
      customToolbar: null,
    }

    return (
      <Form onSubmit={this.handleSubmit}>

      <Grid container direction="row" justify='flex-start' alignItems="center" spacing='5'>
      <Grid item xs={6}>
        <TextField
          id="campaigntype"
          name="campaigntype"
          select
          label="Campaign Type"
          className={classes.textField}
          //required
          //helperText="&nbsp;"  //for spacing
          value={this.state.campaigntypecode}
          style={{width:"275px"}}
          onChange={this.handleCampaignTypeChange}
          margin="normal"
          variant="outlined"
          InputLabelProps={{shrink: true}}
        >
          {this.state.campaignTypes.map(mt => (
            <MenuItem selected={this.state.campaigntypecode=== mt.EventTypeCode} key={mt.EventTypeCode} value={mt.EventTypeCode}>
              {mt.EventType}
            </MenuItem>
          ))}
        </TextField>
        </Grid>
      </Grid>
      
      <div className="CampaignContact"  style={{float:"left",fontSize:"12px"}}>
                    {true && <ScrollDialog 
                    campaignTypes={this.state.campaignTypes} 
                    termsText={this.state.termsText} 
                    hasAgreed={this.state.hasAgreed} 
                    handleAgreedToTerms={this.handleAgreedToTerms}
                    campaigntypeconfirmedby={this.state.campaigntypeconfirmedby}
                    campaigntypeconfirmedon={this.state.campaigntypeconfirmedon}
                    campaigntypecode={this.state.campaigntypecode}
                    clientid={this.state.clientid}  
                    campaignid={this.state.campaignid}/>}
      </div>   
      <br/>

      <div style={{display:this.state.hasAgreed ? 'block' : 'none'}}>
        <div width="100px">
        <Grid container direction="row" justify='flex-start' alignItems="center" spacing='5'>
        <Grid item xs={"auto"}>
        <TextField
          id="campaignname"
          label="Campaign Name"
          className={classes.textField}
          // style={{marginTop:"12px",width:"250px"}}
          //error={caller.state.clientnameInvalid}
          //helperText={caller.state.clientnameInvalid ?'Client Name exists. Please enter a unique name':''}  
          value={this.state.campaignname}
          onChange={this.handleNameChange}
          margin="normal"
          required
          variant="outlined"
          placeholder="[campaign name]"
          InputLabelProps={{shrink: true}}
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          id="messagetype"
          name="messagetype"
          select
          label="Delivery Type"
          className={classes.textField}
          required
          //helperText="&nbsp;"  //for spacing
          value={this.state.allowedmessagetype}
          style={{width:"175px"}}
          onChange={this.handleMessageTypeChange}
          margin="normal"
          variant="outlined"
          InputLabelProps={{shrink: true}}
        >
          {this.state.messagetypes.map(mt => (
            <MenuItem selected={this.state.allowedmessagetype=== mt.MessageType} key={mt.MessageType} value={mt.MessageType}>
              {mt.MessageType}
            </MenuItem>
          ))}
        </TextField>
        </Grid>
        </Grid>         
        </div>
        <div style={{float:"left", width : "65%"}}>
              <label class="subtitle_page">Contacts</label>
              <p/>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                 <MUIDataTable className={classes.table} data={listSource} columns={columns} options={options} />
              </MuiThemeProvider>
        </div>
        <div>
        <LoaderButton
          block
          bsSize="small"
          type="submit"
          isLoading={this.state.isLoading}
          style={{marginLeft:"85%", width:"80px",bottom:"30px",position:"fixed"}}
          text="Next"
          //loadingText="Saving Campaign�"
          disabled={!this.validateMyForm() || this.state.launchstatus !== 'PE'}
        />
        </div>
      </div>
      </Form>
    );
  }

  render() {
    
    return (
      <div>
        <div float="left" >
          {this.renderSwitch(this.state.result)}
        </div>
      </div>
    );
  }

  renderSwitch(result){
    switch(result){
      case "initial": return this.renderForm();
      case "success": return this.renderConfirmationForm("success");
      default: return this.renderConfirmationForm(result);
    }
  }

}

CampaignContact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CampaignContact);

function ScrollDialog(props) {
  // debugger;
   const [open, setOpen] = React.useState(false);
   const scroll='paper'
   const selectedcampaigntype = props.campaignTypes.find(ct => ct.EventTypeCode==props.campaigntypecode);
   const verbiage = selectedcampaigntype === undefined ? "" : selectedcampaigntype.EventAgreementVerbiage;
   
   const handleClickOpen = () => {
     setOpen(true);
   };
 
   const handleClose = (flag) => () => {
     if (!props.hasAgreed) {
       props.handleAgreedToTerms(flag)
     }
     setOpen(false);
   };
 
   const descriptionElementRef = React.useRef(null);
   React.useEffect(() => {
     if (open) {
       const { current: descriptionElement } = descriptionElementRef;
       if (descriptionElement !== null) {
         descriptionElement.focus();
       }
     }
   }, [open]);
 
   const handleCheck=(e)=>{
    //Only allow checking, do not allow un-checking 
    if (!props.hasAgreed) {
      props.handleAgreedToTerms(e.target.checked)
    }
   }

   return (
     <div style={{display:props.campaigntypecode ? 'block' : 'none'}}>
       <div>
         <Checkbox color="black" style={{margin:"0,0,0,0"}} checked={props.hasAgreed} onClick={handleCheck}/>  
            I accept <Link to = {{pathname: "CampaignNew",  state: {clientid: props.clientid,  campaignid:props.campaignid}}} onClick={handleClickOpen}>the terms of the agreement</Link>
       </div> 
       <div style={{display:props.hasAgreed ? 'none' : 'block'}}> 
        <br/>
        To continue, click the link above to review and approve the campaign agreement.
        <br/>
      </div>
      <div style={{display:props.hasAgreed && props.campaigntypeconfirmedby ? 'block' : 'none'}}> 
            Accepted by {props.campaigntypeconfirmedby} on {moment(props.campaigntypeconfirmedon).format('YYYY-MM-DD')}
      </div>
        
       <Dialog
         open={open}
         onClose={handleClose(false)}
         scroll={scroll}
         aria-labelledby="scroll-dialog-title"
         aria-describedby="scroll-dialog-description"
       >
         <DialogTitle id="scroll-dialog-title">Terms of the agreement</DialogTitle>
         <DialogContent dividers>
           <DialogContentText
             id="scroll-dialog-description"
             ref={descriptionElementRef}
             tabIndex={-1}
           >
             {verbiage.split('\n').map(function(item) {
               return (
                 <span>
                   {item}
                   <br/>
                 </span>
               )
             })}
           </DialogContentText>
         </DialogContent>
         <DialogActions>
         <div style={{display:(props.hasAgreed ? 'none' : 'block')}}>
           <Button onClick={handleClose(false)} color="primary">
             Cancel
           </Button>
           <Button onClick={handleClose(true)} color="primary">
             Accept
           </Button>
         </div>
         <div style={{display:(props.hasAgreed ? 'block' : 'none')}}>
           <Button onClick={handleClose(true)} color="primary">
             Close
           </Button>
         </div>
         </DialogActions>
       </Dialog>
     </div>
   );
 }