'use strict'
import React from 'react';
//import {FormGroup,FormControl,ControlLabel, Fieldset} from "react-bootstrap";
import "../css/rrr.css";
import LoaderButton from "../components/LoaderButton";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Form from 'react-bootstrap/Form';
import config from '../config';
import {getData} from "./SharedFunctions";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { ToastContainer, toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import {FormControlLabel,FormGroup,FormControl,FormLabel,RadioGroup,Radio} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import {Grid, Button, MenuItem, TextField, Checkbox} from '@material-ui/core';


const styles = theme => ({
  formControl: {
    //margin: theme.spacing(1),
    color:"black",
    fontWeight: 500
  },
  submit: {
    //margin: theme.spacing(3, 0, 2),
    backgroundColor:"#1782c5",
    marginTop: "18px", 
    height:"53px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
});

class CampaignResponseQ extends React.Component {
  constructor () {
    super()

    this.state = {
      isLoading: false,
      clientid: 0,
      campaignid:0,
      login:'admin@stericycle.com',
      campaignname:"",
      questions:[],
      launchstatus: "",
      hasQst:false
    };
  }

  componentWillMount() {
    // console.log(" in wiz2",this.props);
    if(this.props.parentCampaign.campaignid !== 0){
      if(this.props.parentCampaign.hasnoresponse===1)
      {
        this.props.goto3('Response Site is required to configure Questions');
        return
      }      
      this.setState({
        campaignname:this.props.parentCampaign.campaignname, 
        campaignid:this.props.parentCampaign.campaignid,
        clientid: this.props.parentCampaign.clientid,
        launchstatus: this.props.parentCampaign.launchstatus,
        login: this.props.parentProps.username
      });
    }
  }

  async componentDidMount() {

    await this.getQuestions();
    window.scrollTo(0, 0);
  }

  handleChange = i => (e) => {
    //debugger;

    let {questions} = this.state
    if ((e.target.name === 'MaxLen' || e.target.name === 'MinLen') && e.target.value > 250) {
      questions[i][e.target.name] = 250;
    }
    else if (e.target.name === 'IsRequired') {
      questions[i][e.target.name] = e.target.checked;
    }
    else {
      questions[i][e.target.name] = e.target.value;
    }

    questions[i].isdirty = true
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
    if( e.target.value.length>0)
      switch (e.target.name)
      {
        case 'Question' : questions[i].isEmptyName=false
        case 'MinLen' : {questions[i].isMinError=false ; questions[i].isMinErrorMissing=false}
        case 'MaxLen' : {questions[i].isMaxError=false ; questions[i].isMaxErrorMissing=false}
        case 'selectoptions' : {questions[i].isOptionsError=false; questions[i].isOptionsCommaError=false}
        case 'QuestionOrder' : questions[i].isOrderError=false
        case 'UsageTypeCode' : if(e.target.value==='TX') 
                                    {questions[i].isOptionsError=false; 
                                     questions[i].isOptionsCommaError=false; 
                                     questions[i].selectoptions=''} 
                               else 
                                     questions[i].isdirty=1
      }
    this.setState({questions}) 
  }

  addQuestion = async e => {
    //debugger;
    let {questions} = this.state
    if(questions.length===10)
    {
      toast.info('Max of 10 questions is allowed on the Response form.', {
        position: toast.POSITION.TOP_RIGHT}) 
        return;
    }

    this.setState((existingState) => ({
      questions: [...existingState.questions, {QuestionOrder: (questions && questions.length > 0  
        ? questions[questions.length-1].QuestionOrder + 1 : 1), 
        QuestionID:0, isNew:true, Question:'', MinLen:0, MaxLen:50,UsageTypeCode:'TX',IsRequired:false,selectoptions:'', isdirty:true,
        isOptionsError:false, isOptionsCommaError:false, isMinError:false, isMaxError:false,isOrderError:false,isMinErrorMissing:false,isMaxErrorMissing:false, isEmptyName:false}]
    }));
  }  

  validateForm()  {
    let {questions} = this.state
    let flag = false
//debugger;
    questions.filter(x=>{return x.Question.length===0}).forEach(x=>
      {
      flag = true; 
      return x.isEmptyName = true;
      }
    )

    questions.filter(x=>{return !x.QuestionOrder}).forEach(x=>
      {
      flag = true; 
      return x.isOrderError = true;
      }
    )
// debugger;
    questions.filter(x=>{return x.UsageTypeCode==='DD' && (x.selectoptions === null || x.selectoptions.length===0)}).forEach(x=>
      {
      flag = true; 
      return x.isOptionsError = true;
      }
    )

    questions.filter(x=>{return x.UsageTypeCode==='DD' && x.selectoptions !== null && x.selectoptions.length>0 && !x.selectoptions.includes(",")}).forEach(x=>
      {
      flag = true; 
      return x.isOptionsCommaError = true;
      }
    )

    questions.filter(x=>{return (!x.MinLen&&x.MinLen!==0) && x.MaxLen}).forEach(x=>
      {
      // !x.MinLen&&x.MinLen!==0 used because !x.MinLen proves true when 0
      flag = true; 
      return x.isMinErrorMissing = true;
      }
    )

    questions.filter(x=>{return x.MinLen && !x.MaxLen}).forEach(x=>
      {
      flag = true; 
      return x.isMaxErrorMissing = true;
      }
    )

    questions.filter(x=>{return x.MinLen && x.MaxLen && parseInt(x.MaxLen) < (parseInt(x.MinLen)||0)}).forEach(x=>
      {
      // parseInt(x.MinLen)||0 is used because parseInt(0) is falsy
      flag = true; 
      x.isMinError = true;
      return x.isMaxError = true;
      }
    )

    questions.filter(x=>{return x.MinLen && x.MaxLen && (parseInt(x.MaxLen)===0||(parseInt(x.MinLen)||0)<0)}).forEach(x=>
      {
        flag = true; 
        if((parseInt(x.MinLen)||x.MinLen)<0) x.isMinError = true;
        if(parseInt(x.MaxLen)===0) x.isMaxError = true;
      return 
      }
    )

    if(flag)
    {
      this.setState({questions})
      return false;
    }

  return true;

 }
 
//method to handle signUp submit button
handleSubmit = async event => {
  event.preventDefault();
  let {questions} = this.state

  let updateSet = questions.filter(x=>{return x.isdirty})
  if(updateSet.length ===0)
  {
    this.props.goto6('',true);
    return
  }

  if(!this.validateForm())
    return

  // alert('all clear')

 //debugger;
    updateSet.map(async (question) =>
    {
      let body= {
        "procname" : "procEventQuestionSave",
        "paramcount": "12", 
        "param1": question.QuestionID, //qnid
        "param2": this.state.campaignid, //campaignid
        "param3": question.QuestionOrder, //order
        "param4": question.Question, //qn
        "param5": question.UsageTypeCode, //qntype
        "param6": 0,//this.state.lookupid, //lookupid
        "param7": question.selectoptions, //select options
        "param8": question.MinLen, //min
        "param9": question.MaxLen, //max
        "param10": this.state.login, //createdby
        "param11": this.state.login, //updatedby
        "param12": question.IsRequired?1:0 //IsRequired boolean to bit
        }
      await getData(body) 
      // console.log(body);
    })
    this.props.updateDirtyProductFlag(false);
    this.props.goto6('Campaign Questions are saved',true);
}

  handleYNQuestions = (event) => {
    if(event.target.value==='0') //no product
    {
      this.props.goto6('No Questions Configured');
      return
    }
    this.setState({ hasQst: true })
  }


   async getQuestions(){
     //debugger;
    this.setState({  isLoading:true})

     let body= {
      "procname" : "procEventQuestionSelect",
      "paramcount": "1", 
      "param1": this.state.campaignid === undefined? 0 : this.state.campaignid
      }

        await getData(body)
        .then(json => {
          
          let questionsTmp= json[0].map((el,i) => {
           // if(el.QuestionOrder!=i)
            el.QuestionOrder= el.QuestionOrder!=i+1 ? i+1 :el.QuestionOrder
            return {
                 ...el, 
                 isdirty:false,
                 isEmptyName:false,
                 isOrderError:false,
                 isOptionsError:false, isOptionsCommaError:false, 
                 isMinError:false, isMaxError:false,
                 isMinErrorMissing:false, isMaxErrorMissing:false
            };
        });
    
          this.setState({hasQty:questionsTmp.length>0, questions: questionsTmp, isLoading:false})
        }
    
    
        );



    }


deleteQuestion = (id, questionId, question) => {
//debugger;
  if(questionId===0)
  {
    let {questions} = this.state
    //let i=this.state.questions.findIndex(x=>{return x.QuestionID===0})
    questions.splice(id, 1);
    questions.map((el,i) => {
       return el.QuestionOrder= el.QuestionOrder!=i+1 ? i+1 :el.QuestionOrder
   });
    
    this.setState({questions});
    return
  }

let body = {
  "procname" : "procEventQuestionDelete",
  "paramcount": "1", 
  "param1": questionId
  }

    confirmAlert({
      message : `Are you sure you want to delete Question '${question}' ?`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Yes',
          onClick: async () => {
            let body = {
              "procname" : "procEventQuestionDelete",
              "paramcount": "1", 
              "param1": questionId
              }
          
            await getData(body)
            // .then(json => console.log(json))
            .then(async ()=>{
              toast.info(`Question has been deleted `, {
                position: toast.POSITION.TOP_RIGHT})   
                await this.getQuestions();
              })
          }
        }
      ]
    });  
}

  
  render() {

    if(this.props.parentCampaign.campaignid===undefined)
    {
      return (<div>...Campaign is not configured</div>)
    }

    if(this.state.isLoading)
    return (<div>&nbsp;</div>)
    
    const { classes } = this.props;  
    const {questions,hasQst} = this.state
    

    if(questions.length===0 && !hasQst)
      return (
        <>
        <div style={{width:"100%"}}>
        <FormControl component="fieldset" >
          <FormLabel component="legend" className={classes.formControl}>Do you have questions to configure?</FormLabel>
          <RadioGroup aria-label="qstYN" name="qstYN" onChange={this.handleYNQuestions}>
            <FormControlLabel value="1" control={<Radio color="black"/>} label="Yes" />
            <FormControlLabel value="0" control={<Radio color="black"/>} label="No" />
          </RadioGroup>
        </FormControl>           
        </div>        
        <div >
           <LoaderButton
            block
            bsSize="small"
            type="submit"
            isLoading={this.state.isLoading}
            style={{marginLeft:"85%", width:"80px",bottom:"30px",position:"fixed"}}
            text="Next"
            onClick={this.handleSubmit}  
            disabled={this.props.parentCampaign.launchstatus !== 'PE'}
          />
          </div>     
          </>   
      )

else
    return (
      <div style={{width:"100%"}}>
          {questions.map((qst,i) => 
              <div>
              <Grid container direction="row" justify="flex-start" alignItems="bseline">
              <Grid item xs={12} style={{marginTop:"5px", borderTop: '1px solid rgba(0, 0, 0, .125)'}} >
                <label class="Product_Header" style={{width:"75%"}} >{`Question #${i+1}`}</label> 
                <label style={{verticalAlign:"middle"}}>|&nbsp;&nbsp;<Delete style={{ color: "#1782c5", marginLeft:"20px", verticalAlign:"middle" }} onClick={()=>{if(this.props.parentCampaign.launchstatus === 'PE')this.deleteQuestion(i,qst.QuestionID,qst.Question)}}  /></label>     
              </Grid>
              <Grid item xs={'auto'} style={{marginRight:"10px"}}>
                <TextField
                  name="QuestionOrder"
                  label="Order"
                  type="number"
                  // multiline
                  // rowsMax="2"
                  style={{margin:"25px 12px 0 0", width:"110px"}}
                  error={questions[i].isOrderError}
                  value={questions[i].QuestionOrder}
                  onChange={this.handleChange(i)}
                  readOnly
                  helperText={questions[i].isOrderError?'Invalid order#':''}
                  variant="outlined"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  />
              </Grid>               
              <Grid item xs={8} style={{marginRight:"15px"}}>
                <TextField
                  name='Question'
                  label={`Question #${i+1}`}
                  // className={classes.textField}
                  style={{margin:"25px 0 25px 0",width:"108%"}}
                  error={questions[i].isEmptyName}
                  helperText={questions[i].isEmptyName?'Field is required':''}
                  value={questions[i].Question}
                  onChange={this.handleChange(i)}
                  margin="normal"
                  required
                  variant="outlined"
                  placeholder="[question text]"
                  InputLabelProps={{shrink: true}}
                />           
              </Grid>
              <Grid item xs={2}/>
              <Grid item xs={1}>
                <FormControlLabel control={
                  <Checkbox
                          name="IsRequired"
                          checked={questions[i].IsRequired}
                          onChange={this.handleChange(i)}
                          color="primary"
                  />
                } label='Required?'
                />
              </Grid>
              <Grid item xs={'auto'} style={{marginRight:"15px"}}>
                <RadioGroup 
                    aria-label={`qstType${i}`} name='UsageTypeCode' value={questions[i].UsageTypeCode.length==0?'TX':questions[i].UsageTypeCode} onChange={this.handleChange(i)}>
                  <FormControlLabel value="DD" control={<Radio color="black"/>} label="Dropdown" />
                  <FormControlLabel value="TX" control={<Radio color="black"/>} label="Text" />
                </RadioGroup>
              </Grid>
              <Grid item xs={'auto'} >
                <TextField
                    name='MinLen'
                    label="Min Length"
                    type="number"
                    // multiline
                    // rowsMax="2"
                    style={{width:"100px",marginTop:"15px"}}
                    error={questions[i].isMinError || questions[i].isMinErrorMissing}
                    //onBlur={()=>{alert('on blur')}}
                    value={questions[i].MinLen}
                    onChange={this.handleChange(i)}
                    //error={questions[i].MaxLen < questions[i].MinLen}
                    //helperText={questions[i].MaxLen!=='' && questions[i].MaxLen==='' ? '' parseInt(questions[i].MaxLen) < parseInt(questions[i].MinLen) || parseInt(questions[i].MinLen)===0 ? 'Invalid value' : ''}
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                  />
              </Grid>  
              <Grid item xs={'auto'} >
                <TextField
                    name="MaxLen"
                    label="Max Length"
                    type="number"
                    style={{width:"100px",marginLeft:"15px",marginTop:"15px"}}
                    value={questions[i].MaxLen}
                    onChange={this.handleChange(i)}
                    error={questions[i].isMaxError || questions[i].isMaxErrorMissing}
                    //helperText={parseInt(questions[i].MaxLen) < parseInt(questions[i].MinLen) || parseInt(questions[i].MaxLen)===0 ? 'Invalid value' : ''}
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                  />
              </Grid>       
              <Grid item xs={6} >
                <TextField
                    name="selectoptions"
                    label="Options"
                    //multiline
                    //rowsMax="2"
                    error={questions[i].isOptionsError || questions[i].isOptionsCommaError}
                    helperText={questions[i].isOptionsError?'Field is required':questions[i].isOptionsCommaError?'Comma delimited string is required':''}
                    placeholder="[answer1, answer2, answer3, answer4]"
                    helper text="Enter comma delimited answers to question"
                    disabled = {questions[i].UsageTypeCode!=='DD'}
                  // style={{visibility:state.checkedA!=='DD'?'hidden':'visible', width:"100%"}}
                    style={{marginLeft:"15px",marginTop:"15px",width:"100%"}}
                    value={questions[i].selectoptions}
                    onChange={this.handleChange(i)}
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                  />
                  {/* </FormGroup> */}
                
              </Grid>          
              </Grid>
              </div>
          )}
          <div style={{marginTop:"50px",borderTop: '1px solid rgba(0, 0, 0, .125)'}}>
          <Button
                //type="submit"
                style={{backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"}}
                variant="outlined" //color="secondary"     
                className={classes.submit}
                alignItems="flex-end"
                onClick={this.addQuestion}  
                //disabled={this.state.previewemail.length===0 || (!new RegExp(/\S+@\S+\.\S+/).test(this.state.previewemail))}
                disabled={this.props.parentCampaign.launchstatus !== 'PE'}
                >
              {/* {questions.length===0 ? 'Add a Question' : 'Add Another Question'} */}Add a Question
         </Button>                  
          </div>
          <div >
           <LoaderButton
            block
            bsSize="small"
            type="submit"
            isLoading={this.state.isLoading}
            style={{marginLeft:"85%", width:"80px",bottom:"30px",position:"fixed"}}
            text="Next"
            onClick={this.handleSubmit}  
            disabled={this.props.parentCampaign.launchstatus !== 'PE'}
          />
          </div>          
      </div>
      )
  }
}

export default withStyles(styles)(CampaignResponseQ);
