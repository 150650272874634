'use strict'
import React from 'react'
import LoaderButton from "../components/LoaderButton";
import "../css/rrr.css";
import config from "../config";
import { Container, Row, Col } from 'react-bootstrap';
import {getData} from "./SharedFunctions";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Warning from '@material-ui/icons/Warning';
import Error from '@material-ui/icons/Error';
import Create from '@material-ui/icons/Create';
import { Link } from "react-router-dom";

export class CampaignReview extends React.Component {
  constructor () {
    super()
    this.state = { 
      csubject: '', 
      cbody:'',
      user: '',
      campaign:{
        campaignid: 0,
        campaignname: '',
        listid: 0,
        remedytext: '',
        campaigndescription: '',
        products: [],
        schedules: [],
        launchstatus: '',
        launchstatusdesc: '',
        PURL: '',
        emailbody: '',
        emailsubject: '',
        webheader:'',
        webdetail:'',
        webfooter:'',
        hotline:'',
        hasnoresponse:''
      },
      questionSource: [],
      productSource: [],
      schedules: []
    }
  }

  async componentDidMount() {
    await this.getQuestions();
    await this.getProductList();
    await this.getSchedulesForCampaign();
    window.scrollTo(0, 0);

    // console.log("campaing review state", this.state);
  }

  componentWillMount() {

    if(this.props.parentCampaign.campaignid !== 0){
      this.setState({
        clientid: this.props.parentCampaign.clientid,
        campaignname:this.props.parentCampaign.campaignname, 
        campaign:this.props.parentCampaign,
        emailsubject: this.props.parentCampaign.notificationsubject,
        emailbody: this.props.parentCampaign.notificationbody,
        webheader: this.props.parentCampaign.webhdrmsg,
        webdetail: this.props.parentCampaign.webdetailmsg,
        webfooter: this.props.parentCampaign.webfootermsg,
        hotline: this.props.parentCampaign.recallhotline,
        questionSource: [],
        productSource: [],
        schedules: [],
        hasnoresponse: this.props.parentCampaign.hasnoresponse,
        hasnoemail:this.props.parentCampaign.hasnoemail,
        hasnosms:this.props.parentCampaign.hasnosms,
        smsmessage:this.props.parentCampaign.smsmessage
      });
    }
  }

  handleLaunch = (e) => {
    confirmAlert({
      message : `IMPORTANT! Are you sure you want to launch this campaign?`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Yes',
          onClick: () => this.launchCampaign(e)
        }
      ]
    });  
  }

  launchCampaign = async event => {
    event.preventDefault();

    let body= {
      "eventID" : this.props.parentCampaign.campaignid,
      "launchedBy": this.props.parentProps.username
      };

      await getData(body,'launchevent')
      .then(json => { 
        // console.log(JSON.stringify(json));

        this.props.updateCampaignStateIdOnly(this.props.parentCampaign.campaignid);
        let localCampaign = {...this.state.campaign};
        localCampaign.launchstatus = 'LN';
        localCampaign.launchstatusdesc = 'Launched';
        this.setState({campaign: localCampaign });        

        // console.log(`campaign ${this.props.parentCampaign.campaignid} launched`);
        toast.info( `Campaign '${this.props.parentCampaign.campaignname}' has been launched.` , {
          position: toast.POSITION.TOP_RIGHT
        })

      })
  }

  handleCancel = (e) => {
    confirmAlert({
      message : `IMPORTANT! Are you sure you want to permanently cancel this campaign?`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Yes',
          onClick: () => this.cancelCampaign(e)
        }
      ]
    });  
  }

  cancelCampaign = async event => {
    event.preventDefault();

    let body= {
      "procname" : "procEventUpdateCancel",
      "paramcount": "2", 
      "param1": this.props.parentCampaign.campaignid,
      "param2": this.props.parentProps.username
      };

      await getData(body)
      .then(json => { 
        // console.log(JSON.stringify(json));

        this.props.updateCampaignStateIdOnly(this.props.parentCampaign.campaignid);
        let localCampaign = {...this.state.campaign};
        localCampaign.launchstatus = 'CN';
        localCampaign.launchstatusdesc = 'Cancelled';
        this.setState({campaign: localCampaign });        

        // console.log(`campaign ${this.props.parentCampaign.campaignid} cancelled`);
        toast.info( `Campaign '${this.props.parentCampaign.campaignname}' has been cancelled.` , {
          position: toast.POSITION.TOP_RIGHT
        })

      })
  }



  async getQuestions(){
    let body= {
      "procname" : "procEventQuestionSelect",
      "paramcount": "1", 
      "param1": this.props.parentCampaign.campaignid
      }

      await getData(body)
      .then(json => this.setState({ 
        questionSource: json[0]}))      
    }

  async getProductList (){
    let body= {
      procname: "procEventProductSelect",
      paramcount: 1,
      param1: this.props.parentCampaign.campaignid
    }
    await getData(body)
    .then(json =>   { if (json.length > 1) {
        this.setState({
            productSource: json[0]
          })}
    }
    );
  }    

  async getSchedulesForCampaign (){
    let body= {
      procname: "procEventMessagingSetupSelect",
      paramcount: 1,
      param1: this.props.parentCampaign.campaignid
    }
    
    await getData(body)
    .then(json =>   { if (json.length > 1) {
        this.setState({
          schedules: json[0]
      })}
    }
      );
    }    

  renderProductSection() {
    return  this.state.productSource.map((product, index) => {
      return (
        <tr>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;#{index+1}</td>
          <td><CheckCircle htmlColor="green"></CheckCircle>&nbsp;{product.ProductName}
          </td>
        </tr>
      )  
    })
  }

  renderSchedulesSection() {
    //debugger;

    let invalidStart = 0;
    let displayNum = ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eight', 'Ninth', 'Tenth'];

    if  
    (
      (this.state.schedules.length > 1 && 
        (this.state.schedules[0].scheduleDT === '2000-01-01T00:00:00.000Z') && 
        (new Date(this.state.schedules[1].scheduleDT) < Date.now())
      ) ||
      // Launch Now NOT used - Disable if initial is prior to now
      (this.state.schedules.length >= 1 && 
        (this.state.schedules[0].scheduleDT !== '2000-01-01T00:00:00.000Z') && 
        (new Date(this.state.schedules[0].scheduleDT) < Date.now())
      )
    )
    {
      invalidStart = 1;
    } 

    return  this.state.schedules.map((sched, index) => {
      return (
        <tr>
          <td width="30%">{displayNum[index]} - {sched.MessageType}</td>
          <td>
            {invalidStart === 1 && this.props.parentCampaign.launchstatus === 'PE'
              ? (sched.scheduleDT === '2000-01-01T00:00:00.000Z' 
                ? <><Error htmlColor="red"></Error>&nbsp;Upon Launch</> 
                : <><Error htmlColor="red"></Error>&nbsp;{new Date(sched.scheduleDT).toLocaleString("en-US", {timeZone:"America/New_York"})} Eastern</>)
              : (sched.scheduleDT === '2000-01-01T00:00:00.000Z' 
                ? <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;Upon Launch</> 
                : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;{new Date(sched.scheduleDT).toLocaleString("en-US", {timeZone:"America/New_York"})} Eastern</>)
            }
          </td>
        </tr>
        
      )  
    })
  }

  render () {
    if(this.props.parentCampaign.campaignid===undefined)
    {
      return (<div>...Campaign is not configured</div>)
    }
    let {allowedmessagetype, total, outedOut, unsubscribed} = this.state.campaign;
                  
    let totalSubscribied = total - unsubscribed;
    let totalSMSable = total - outedOut;       

    return ( <>
      <div class="campaing-review-page">
        
        <b>Name and Contacts</b>&nbsp;&nbsp;{this.state.campaign.launchstatus === 'PE' && <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,stepid:1,  campaignid:this.state.campaign.campaignid}}}  onClick={this.props.goto1}  ><Create htmlColor='blue'></Create>Edit</Link> }
        <table class="campaign-review-table">
          <tr>
            <td width="30%">Launch Status</td>
            <td>
              {this.state.campaign.launchstatus === 'LN'
                ? <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;{this.state.campaign.launchstatusdesc}</>
                : (this.state.campaign.launchstatus === 'CN' 
                  ? <><Error htmlColor="red"></Error>&nbsp;{this.state.campaign.launchstatusdesc}</>
                  : <><Warning htmlColor="orange"></Warning>&nbsp;{this.state.campaign.launchstatusdesc}</>
                )
              }
            </td>
          </tr>
          
          <tr>
            <td>Contact List</td>
            <td>
              {this.state.campaign.launchstatus === 'LN' 
                ? <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;{this.state.campaign.listname}</>
                : (this.state.campaign.listname == 'undefined' || !this.state.campaign.listname || this.state.campaign.listname.length === 0 || this.state.campaign.liststatusid !== 'A'
                  ? <><Error htmlColor="red"></Error>&nbsp;Not Configured</>
                  : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;{this.state.campaign.listname}</>
                )
              }
            </td>
          </tr>
          <tr>
            <td># of Contacts</td>
            <td>
              {this.state.campaign.launchstatus === 'LN' 
                ? <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;{allowedmessagetype === 'Both' ? totalSubscribied >= totalSMSable ? totalSubscribied : totalSMSable : allowedmessagetype === 'Email' ? totalSubscribied : totalSMSable}</> 
                : (this.state.campaign.listname == 'undefined' || !this.state.campaign.listname || this.state.campaign.listname.length === 0 || this.state.campaign.liststatusid !== 'A'
                  ? <><Warning htmlColor="orange"></Warning>&nbsp;0</>
                  : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;{allowedmessagetype === 'Both' ? totalSubscribied >= totalSMSable ? totalSubscribied : totalSMSable : allowedmessagetype === 'Email' ? totalSubscribied : totalSMSable}</>
                )
              }
            </td>
          </tr>
        </table>
        <br />
        <br />

        <b>Delivery Method</b>
        <table class="campaign-review-table">
        {this.state.hasnoemail===0 ? <>
          <tr>
            <td width="30%">Email&nbsp;&nbsp;{this.state.campaign.launchstatus === 'PE' && <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,stepid:2,  campaignid:this.state.campaign.campaignid}}}  onClick={this.props.goto2}  ><Create htmlColor='blue'></Create>Edit</Link> }</td>
          </tr>          
          <tr>
            <td width="30%">&nbsp;&nbsp;&nbsp;&nbsp;Subject</td>
            <td>
              {this.state.emailsubject == 'undefined' || !this.state.emailsubject || this.state.emailsubject.length === 0 || this.state.emailsubject === '<p><br></p>' 
                ? <><Error htmlColor="red"></Error>&nbsp;Not Configured</>
                : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;{this.state.emailsubject}</>
              }
            </td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;Body</td>
            <td>
              {this.state.emailbody == 'undefined' || !this.state.emailbody || this.state.emailbody.length === 0 || this.state.emailbody === '<p><br></p>' 
                ? <><Error htmlColor="red"></Error>&nbsp;Not Configured</>
                : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;Configured</>
              }
            </td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>          
          </>  : ''}
          {this.state.hasnosms===0 ? <>
          <tr>
            <td width="30%">Text&nbsp;&nbsp;{this.state.campaign.launchstatus === 'PE' && <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,stepid:2.5,  campaignid:this.state.campaign.campaignid}}}  onClick={this.props.goto2_5}  ><Create htmlColor='blue'></Create>Edit</Link> }</td>
          </tr>          
          <tr>
            <td width="30%">&nbsp;&nbsp;&nbsp;&nbsp;Message</td>
            <td>
              {this.state.smsmessage == 'undefined' || !this.state.smsmessage || this.state.smsmessage.length === 0 || this.state.smsmessage === '<p><br></p>' 
                ? <><Error htmlColor="red"></Error>&nbsp;Not Configured</>
                : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;Configured</>
              }
            </td>
          </tr>
          </>  : ''}
        </table>
        <br />
        <br />

        <b>Response Site</b>
        <table class="campaign-review-table">
          
          <tr>
            <td width="30%">MicroSite&nbsp;&nbsp;{this.state.campaign.launchstatus === 'PE' && <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,stepid:3,  campaignid:this.state.campaign.campaignid}}}  onClick={this.props.goto3}  ><Create htmlColor='blue'></Create>Edit</Link> }</td>
            <td>{(this.state.hasnoresponse 
                  ? <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;No Response Site</> 
                  : <>&nbsp;</>)}
            </td>
          </tr>
          { (!this.state.hasnoresponse && <>
          <tr>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;Logo</td>
            <td>
              {this.state.campaign.logoURN == 'undefined' || !this.state.campaign.logoURN || this.state.campaign.logoURN.length === 0 
                ? <><Warning htmlColor="orange"></Warning>&nbsp;Not Configured</>
                : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;Configured</>}
            </td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;Header</td>
            <td>
              {this.state.webheader == 'undefined' || !this.state.webheader || this.state.webheader.length === 0 || this.state.webheader === '<p><br></p>' 
                ? <><Error htmlColor="red"></Error>&nbsp;Not Configured</>
                : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;Configured</> 
              }
            </td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;Detail</td>
            <td>
              {this.state.webdetail == 'undefined' || !this.state.webdetail || this.state.webdetail.length === 0 || this.state.webdetail === '<p><br></p>' 
                ? <><Error htmlColor="red"></Error>&nbsp;Not Configured</>
                : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;Configured</>
              }
            </td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;Footer</td>
            <td>
              {this.state.webfooter == 'undefined' || !this.state.webfooter || this.state.webfooter.length === 0 || this.state.webfooter === '<p><br></p>' 
                ? <><Warning htmlColor="orange"></Warning>&nbsp;Not Configured</>
                : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;Configured</>
              }
            </td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;Hotline</td>
            <td>
              {this.state.hotline == 'undefined' || !this.state.hotline || this.state.hotline.length === 0 || this.state.hotline === '<p><br></p>' 
                ? <><Warning htmlColor="orange"></Warning>&nbsp;Not Configured</>
                : <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;Configured</>
              }
            </td>
          </tr>

          <tr><td colspan="2">&nbsp;</td></tr>
          <tr>
            <td width="30%">Products&nbsp;&nbsp;{this.state.campaign.launchstatus === 'PE' && <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,stepid:4,  campaignid:this.state.campaign.campaignid}}}  onClick={this.props.goto4}  ><Create htmlColor='blue'></Create>Edit</Link> }</td>
            <td></td>
          </tr>
          {this.renderProductSection()}

          <tr><td colspan="2">&nbsp;</td></tr>
          <tr>
            <td width="30%">Questions&nbsp;&nbsp;{this.state.campaign.launchstatus === 'PE' && <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,stepid:5,  campaignid:this.state.campaign.campaignid}}}  onClick={this.props.goto5}  ><Create htmlColor='blue'></Create>Edit</Link> }</td>
            <td>
              {this.state.questionSource.length > 0
                ? <><CheckCircle htmlColor="green"></CheckCircle>&nbsp;{this.state.questionSource.length}</>
                : <><Warning htmlColor="orange"></Warning>&nbsp;{this.state.questionSource.length}</>
              }
            </td>
          </tr>
          </>)}
        </table>
        <br />
        <br />

        <b>Schedule</b>&nbsp;&nbsp;{this.state.campaign.launchstatus === 'PE' && <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,stepid:6,  campaignid:this.state.campaign.campaignid}}}  onClick={this.props.goto6}  ><Create htmlColor='blue'></Create>Edit</Link> }
        <table class="campaign-review-table">
          {this.renderSchedulesSection()}
          
        </table>
        
        {!this.state.hasnoresponse && <>
        <br />
        <br />
        <b>Preview</b>
        <br />
        <LoaderButton
          block
          bsSize="small"
          type="button"
          onClick={()=>{ window.open(config.RESPONSEURL + this.state.campaign.PURL) }} 
          text="Generate Preview"
          style={{width:"200px"}}
          variant="btn btn-outline-primary"
          disabled={
            (this.state.hasnoresponse == 'undefined' || this.state.hasnoresponse.length === 0) ||
            (this.state.hasnoresponse == 1)
          }
          loadingText="Creating Preview�"
        />
        </>}
        <br />
        <b>Actions</b>
        <br />
        {this.state.campaign.launchstatus !== 'LN' ? (
          <LoaderButton
              block
              bsSize="small"
              type="button"
              onClick={(e)=>{this.handleLaunch(e)}}
              text="Launch Campaign"
              style={{width:"200px"}}
              disabled={
                (this.state.campaign.total == 'undefined' || !this.state.campaign.total || this.state.campaign.total === 0) ||
                (this.state.hasnoemail===0 && (this.state.emailbody == 'undefined' || !this.state.emailbody || this.state.emailbody.length === 0 
                            || this.state.emailsubject == 'undefined' || !this.state.emailsubject || this.state.emailsubject.length === 0)) ||
                (this.state.hasnosms===0 && (this.state.smsmessage == 'undefined' || !this.state.smsmessage || this.state.smsmessage.length === 0)) ||
                //(this.state.campaign.logoURN == 'undefined' || !this.state.campaign.logoURN || this.state.campaign.logoURN.length === 0) ||
                (this.state.schedules == 'undefined' || !this.state.schedules || this.state.schedules.length === 0) ||
                // Launch Now used with follow up - Disable if follow up is prior to now
                (this.state.schedules.length > 1 && 
                  (this.state.schedules[0].scheduleDT === '2000-01-01T00:00:00.000Z') && 
                  (new Date(this.state.schedules[1].scheduleDT) < Date.now())
                ) ||
                // Launch Now NOT used - Disable if initial is prior to now
                (this.state.schedules.length >= 1 && 
                  (this.state.schedules[0].scheduleDT !== '2000-01-01T00:00:00.000Z') && 
                  (new Date(this.state.schedules[0].scheduleDT) < Date.now())
                ) ||
                (this.state.campaign.launchstatus === 'CN' || this.state.campaign.liststatusid !== 'A')
              }
          />
        ) : (   
          <LoaderButton
              block
              bsSize="small"
              type="button"
              onClick={(e)=>{this.handleCancel(e)}}
              style={{width:"200px"}}
              text="Cancel Campaign"
              variant="danger"
          />
        )}
      </div>
      <div>
      
      </div>
      <div>





<div>

<Container style={{paddingTop:"40px", paddingBottom:"40px"}}>
  <Row>
    <Col>
        
    </Col>
  </Row>
</Container>
          
        </div>
      </div>
    </>)
  }
}
