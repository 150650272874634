//'use strict'
import React from 'react';
//import {FormGroup,FormControl,ControlLabel} from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import "../css/rrr.css";
import LoaderButton from "../components/LoaderButton";
import Form from 'react-bootstrap/Form';
import {getData} from "./SharedFunctions";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {FormControlLabel,FormControl,RadioGroup,Radio, Grid,  FormGroup, FormLabel, Button, MenuItem, TextField} from '@material-ui/core';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 600
    // backgroundColor: "white"
  },
  formControl: {
    //margin: theme.spacing(1),
    color:"black",
    fontWeight: 500
  },
  paper: {
    padding: theme.spacing.unit,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    // width:"90%"
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: "#f7f7f7",
    marginTop:theme.spacing(3),
  },
  table: {
    marginTop: theme.spacing.unit * 2,
    width: "90%"
  },  
  submit: {
    //margin: theme.spacing(3, 0, 2),
    backgroundColor:"#1782c5",
    marginTop: "18px", 
    height:"53px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
});


class CampaignSms extends React.Component {
  constructor () {
    super()
    //this.handleChange = this.handleChange.bind(this);
    // this.handleSubjectChange = this.handleSubjectChange.bind(this);

    this.state = {
      isLoading: false,
      emailInvalid:false,
      clientname: "",
      clientid: 0,
      //login:'admin@stericycle.com',
      //campaignname:"",
      result: "initial",
      emailbody: '',
      emailsubject: '',
      bodytext : '',
      emailfrom: [],
      email:'',
      previewemail:'',
      hasnoresponse:false,
      hasnoemail:false,
      hasnosms:false,
      isdirty:false,
      smsmessage:'',
      charactersleft:110,
      contentMatch:''
    };
  }

  componentWillMount() {
    //console.log(" in wiz2",this.props);

     if(this.props.parentCampaign.campaignid!==undefined && this.props.parentCampaign.campaignid !== 0){
      this.setStateParams()
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }



  handleChange = (name='') => e => {
    let {isdirty,charactersleft} = this.state
		this.setState({
      [name.length>0 ? name : e.target.id]: e.target.value, 
      isdirty:true,
      charactersleft:110 - e.target.value.length
    });

    if(!this.props.isproductdirty)
    {
      this.props.updateDirtyProductFlag(true);
      //console.log('handleChange')
    }
	}

  render() {
    if(this.props.parentCampaign.campaignid===undefined)
    {
      return (<div>...Campaign is not configured</div>)
    }
    
    return (
      <div >
        {this.renderSwitch(this.state.result)}
      </div>
    );
  }

  renderSwitch(result){
    switch(result){
      case "initial": return this.renderForm();
      default: return this.renderConfirmationForm(result);
    }
  }

  renderConfirmationForm(msg) {
    return (
        <Form>
            <Form.Label>{msg}</Form.Label>
            <br></br>
        </Form>
    );
  }

validateForm()  {

    if(this.props.parentCampaign.campaignid!==undefined && this.props.parentCampaign.campaignid !== 0 && this.state.campaignid === undefined){
      this.setStateParams()
    }

     return (      
      this.state.smsmessage !== null && this.state.smsmessage.length > 0 
    ); 
  }

  setStateParams (){
    //debugger;
    this.setState({
      //campaignname:this.props.parentCampaign.campaignname, 
      campaignid:this.props.parentCampaign.campaignid,
      clientid: this.props.parentCampaign.clientid,
      login: this.props.parentProps.username,
      hasnoemail: (this.props.parentCampaign.hasnoemail ===1),
      hasnosms: (this.props.parentCampaign.hasnosms ===1),
      smsmessage: this.props.parentCampaign.smsmessage,
      charactersleft: 110 - ((this.props.parentCampaign.smsmessage??[]).length)
    }); 

  }
  
//method to handle signUp submit button
handleSubmit = async event => {
  //this.state.result = "working..."
  event.preventDefault();
  var {smsmessage} = this.state;

  if(smsmessage !== null && smsmessage.length>0)
  {
  var contentMatch="";
  this.props.parentCampaign.fwl.map(e => {
    contentMatch += smsmessage.match(new RegExp(`\\b${e}\\b`, 'gi'))!== null ? e + ", " : ''
    }
  )
  contentMatch = contentMatch.slice(0, -2);
  this.setState({ contentMatch})
  
  if(contentMatch.length>0)
    return false;
  }

  if(!this.state.isdirty)
  {
    this.props.goto3();
    return
  }

    let body= {
    "procname" : "procEventSave_v1_3",
    "paramcount": "23", 
    "param1": this.state.campaignid,
    "param2": this.state.clientid,
    "param3" : 0, //campaignname
    "param4" : 0 , //listid 
    "param5": 0, //this.state.emailbody, //inNotificationMessage
    "param6": 0, //inWebHeaderMsg
    "param7": 0, //inWebDetailedMsg
    "param8": 0, //inWebFooterMessage
    "param9": 0, //inRecallHotline
    "param10": 0, //inRemedyText
    "param11": 0, //inEventDescription
    "param12": 0, // this.state.emailsubject, //inNotificationSubject
    "param13": this.state.login, //createdby
    "param14": this.state.login, //updatedby
    "param15": 0, // this.state.email ,//email
    "param16": 0, //emailbodyCSS //inNotificationMessage
    "param17": 0, //inLogoURN
    "param18": 0, //inSiteColor   
    "param19": this.state.smsmessage, //inSmsMessage   
    "param20": 0, //inAllowMessageType       
    "param21": 0, //inEventTypeCode       
    "param22": 0, //inEventTypeConfirmedBy       
    "param23": 0 //inEventTypeConfirmedOn       
    }

    await getData(body)
   .then(json => {
    if(json.errorMessage === undefined){
      this.props.updateDirtyProductFlag(false);
      this.props.goto3('Campaign Text Info is saved');
    }
    else  
      this.setState({ isLoading: false, result : json.errorMessage})
     });
  //this.props.updateCampaignState(this.state.campaign);
}

renderOptions() {
  return this.state.emailfrom.map(e => {
          return(<option selected={this.state.email=== e.Email} key={e.Email}>{e.Email}</option>)
      })
}


  renderForm () {
    const { classes } = this.props;
    const {emailfrom} = this.state;
    return (
    <Form onSubmit={this.handleSubmit}>


    <div id='second' style={{visibility:this.state.hasnosms?'hidden':'visible'}}> 
      <div className={classes.root}>
      {/* <Grid container> */}
        <Grid container>
          <TextField
            id="smsmessage"
            label={"Text Message (remaining characters " + this.state.charactersleft + "/110)" } 
            multiline
            rowsMax={3}
            //required  // can't use required or causes a focus error when hidden
            value={this.state.smsmessage}
            onChange={this.handleChange()}
            className={classes.textField}
            margin="normal"
            style={{marginRight:"50%"}}
            error={this.state.contentMatch.length > 0}
            helperText={this.state.contentMatch.length > 0 ? `Words like ${this.state.contentMatch} are NOT permitted` : ""}
            variant="outlined"
            placeholder="[text message]"
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 110 }}
            />
        </Grid>
      </div> 
 

    </div>



    <div >
        <LoaderButton
          block
          bsSize="small"
          type="submit"
          isLoading={this.state.isLoading}
          style={{marginLeft:"85%", width:"80px",bottom:"30px",position:"fixed"}}
          text="Next"
          //loadingText="Saving Campaign�"
          disabled={!this.validateForm() || this.props.parentCampaign.launchstatus !== 'PE'}
        />
        </div>   
      </Form>
    )
  }
}

CampaignSms.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CampaignSms);