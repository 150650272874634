//'use strict'
import React from 'react';
//import {FormGroup,FormControl,ControlLabel} from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import "../css/rrr.css";
import LoaderButton from "../components/LoaderButton";
import Form from 'react-bootstrap/Form';
import {getData} from "./SharedFunctions";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from '@material-ui/core/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import config from '../config';
import { s3Upload, s3Remove } from "../libs/awsLib";

import {FormControlLabel,FormControl,RadioGroup,Radio, Grid,  FormGroup, FormLabel, Button, MenuItem, TextField} from '@material-ui/core';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 330,
    // backgroundColor: "white"
  },
  formControl: {
    //margin: theme.spacing(1),
    color:"black",
    fontWeight: 500
  },
  paper: {
    padding: theme.spacing.unit,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    // width:"90%"
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: "#f7f7f7",
    marginTop:theme.spacing(3),
  },
  table: {
    marginTop: theme.spacing.unit * 2,
    width: "90%"
  },  
  submit: {
    //margin: theme.spacing(3, 0, 2),
    backgroundColor:"#1782c5",
    marginTop: "18px", 
    height:"53px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
});


class CampaignEmail extends React.Component {
  constructor () {
    super()
    //this.handleChange = this.handleChange.bind(this);
    // this.handleSubjectChange = this.handleSubjectChange.bind(this);

    this.modules = {
      toolbar: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{ 'align': [] }],
          [{ 'color': [] }, { 'background': [] }],
          ['link'],
          ['clean']
        ]
    };
  
    this.formats = [
        'font',
        'size',
        'bold', 'italic', 'underline',
        'list', 'bullet',
        'align',
        'color', 'background',
        'link'
      ];

    this.state = {
      isLoading: false,
      emailInvalid:false,
      clientname: "",
      clientid: 0,
      //login:'admin@stericycle.com',
      //campaignname:"",
      result: "initial",
      emailbody: '',
      emailsubject: '',
      bodytext : '',
      emailfrom: [],
      email:'',
      previewemail:'',
      emailLogoURN:'',
      emailHeaderColor:'',
      hasnoresponse:false,
      hasnoemail:false,
      hasnosms:false,
      isdirty:false,
      contentMatch:'',
      contentMatchSubject:''
    };
  }

  componentWillMount() {
    //console.log(" in wiz2",this.props);

     if(this.props.parentCampaign.campaignid!==undefined && this.props.parentCampaign.campaignid !== 0){
      this.setStateParams();
      this.setState({
        emailLogoURN: this.props.parentCampaign.emailLogoURN!==undefined && this.props.parentCampaign.emailLogoURN!==null && this.props.parentCampaign.emailLogoURN.length > 0 ?  this.props.parentCampaign.emailLogoURN : '0',
        emailHeaderColor: this.props.parentCampaign.emailHeaderColor!==undefined &&  this.props.parentCampaign.emailHeaderColor!==null && this.props.parentCampaign.emailHeaderColor.length > 0 ? this.props.parentCampaign.emailHeaderColor:config.SDWK_Color
      })
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }


  async sendEmail(){

    let headerHTMLNoImage = `<table width=100% bgcolor='`+this.state.emailHeaderColor+`' style="align:center margin-left:auto margin-right:auto">
                                <tbody>
                                  <tr style="height: 100px">
                                      <td style="width:50% vertical-align:middle">
                                      </td>
                                  </tr>
                                </tbody>
                            </table>`;

    let headerHTML = `<table width=100% bgcolor='`+this.state.emailHeaderColor+`' style="align:center margin-left:auto margin-right:auto">
                          <tbody>
                            <tr style="height: 100">
                                <td style="width:50% vertical-align:middle">
                                <img  border="0px solid black" height="100" style="padding:10px"
                                        src="https://` + config.s3.BUCKET + `.s3.amazonaws.com/` + 
                                        `public/RRRClientLogo/Event_` + this.state.emailLogoURN + 
                                        `_logo_email.png" alt='Campaign Logo'/> 
                                </td>
                            </tr>
                          </tbody>
                      </table>`;

    headerHTML = this.state.emailLogoURN==0 ? headerHTMLNoImage : headerHTML;

    let emailbodyCSS = this.applyInlineStyle(this.state.emailbody) // await getData({html: this.state.emailbody},"convertemailhtml"); 

    emailbodyCSS = headerHTML + `<br/><br/>` + emailbodyCSS;

    let body = {
          "emailFrom": this.state.email,
          "emailTo": this.state.previewemail,
          "emailSubject": this.state.emailsubject,
          "emailBody": emailbodyCSS
        }

     await getData(body,"sendmessage")
     .then(toast.info("Preview Email has been sent", {position: toast.POSITION.TOP_RIGHT}))
  }

  handleChange = (name='') => e => {
    let {isdirty} = this.state
		this.setState({
      [name.length>0 ? name : e.target.id]: e.target.value, 
      emailInvalid:e.target.id==='previewemail'?false:this.state.emailInvalid,
      isdirty:e.target.id!=='previewemail'?true:isdirty
    });

    if(e.target.id!=='previewemail' && !this.props.isproductdirty)
    {
      this.props.updateDirtyProductFlag(true);
      //console.log('handleChange')
    }
	}
  handleBodyChange1 = (event) => {
    this.setState({ emailbody: event , isdirty:true })
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
  }

  changeHandler = colors => {
    if (this.props.parentCampaign.launchstatus !== 'PE') return
    this.setState({ emailHeaderColor: colors.color, isdirty:true  });
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
  }

  handleChangeFile = (event) => {
    //debugger;

    if(event.target.files[0].type !=='image/jpeg' && event.target.files[0].type !=='image/png')
    {
      toast.info("Please use .png or .jpeg/.jpg file format only", {
        position: toast.POSITION.TOP_RIGHT, autoClose: 5000, hideProgressBar: true  })   
      return;
    }

    this.file = event.target.files[0]
    document.getElementById("logo").src = URL.createObjectURL(event.target.files[0]);

     this.uploadFile();
  }


  uploadFile =  async() =>{
    //debugger;
    const {campaignid} = this.state
    const fileName = 'RRRClientLogo/Event_' + campaignid + '_logo_email.png' ;
      
    try {
        
      const attachment = this.file 
        ? await s3Upload(this.file,fileName)
        .then(()=>{
          this.setState({isdirty:true,hasLogo:true,emailLogoURN: campaignid }) //logoURN: campaignid, 
          if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
        }
        )
        : null;
    } 
    catch (e) {
      toast.error( e , {position: toast.POSITION.TOP_RIGHT})
      this.setState({ isLoading: false });
    }    
      // console.log(this.state.isdirty)
      //console.log(this.state.file.name);
  }

  handleImgClick = e => {
    if (this.props.parentCampaign.launchstatus !== 'PE') return
    document.getElementById("file").click();
  }

  applyInlineStyle = (emailbody) =>{
    return emailbody.replace(/ql-size-large/g,'font-size: 1.5em;')
                              .replace(/ql-size-small/g,'font-size: 0.75em;')
                              .replace(/ql-size-huge/g,'font-size: 2.5em;')
                              .replace(/ql-font-serif/g,'font-family: Georgia, Times New Roman, serif;')
                              .replace(/ql-font-monospace/g,'font-family: Monaco, Courier New, monospace;')
                              .replace(/ql-direction-rtl/g,'direction: rtl;text-align: inherit;')
                              .replace(/ql-align-right/g,'text-align: right;')
                              .replace(/ql-align-center/g,'text-align: center;')                                                            
                              .replace(/ql-align-justify/g,'text-align: justify;')
                              .replace(/class/g,'style')
                              .replace(/<p>/g,'<p style="margin:0;padding:0">')
                              .replace(/<ul>/g,'<ul style="padding-left: 1.5em;">')
                              //.replace(/[^_blank]" style="/g,'; ')
                               .replace(/;" style="/g,'; ')
  }

  render() {
    if(this.props.parentCampaign.campaignid===undefined)
    {
      return (<div>...Campaign is not configured</div>)
    }
    
    return (
      <div >
        {this.renderSwitch(this.state.result)}
      </div>
    );
  }

  renderSwitch(result){
    switch(result){
      case "initial": return this.renderForm();
      default: return this.renderConfirmationForm(result);
    }
  }

  renderConfirmationForm(msg) {
    return (
        <Form>
            <Form.Label>{msg}</Form.Label>
            <br></br>
        </Form>
    );
  }

validateForm()  {

    if(this.props.parentCampaign.campaignid!==undefined && this.props.parentCampaign.campaignid !== 0 && this.state.campaignid === undefined){
      this.setStateParams()
    }

     return (      
      this.state.emailbody !== null && this.state.emailbody!= '<p><br></p>' &&
      this.state.emailsubject !== null && this.state.emailsubject.length > 0 &&
      this.state.email !== null && this.state.email.length > 0
    ); 
  }

  setStateParams (){
    this.setState({
      //campaignname:this.props.parentCampaign.campaignname, 
      campaignid:this.props.parentCampaign.campaignid,
      clientid: this.props.parentCampaign.clientid,
      emailsubject: this.props.parentCampaign.notificationsubject,
      emailbody: this.props.parentCampaign.notificationbody,
      //launchstatus: this.props.parentCampaign.launchstatus,
      emailfrom: this.props.parentCampaign.emailfrom, //Array - an available email list for the user
      email: this.props.parentCampaign.email.length==0 || this.props.parentCampaign.emailfrom.filter(e=> {return e.Email === this.props.parentCampaign.email}).length==0
        ? this.props.parentCampaign.emailfrom[0].Email 
        : this.props.parentCampaign.email,
      login: this.props.parentProps.username,
      previewemail: this.props.parentProps.username,
      hasnoemail: (this.props.parentCampaign.hasnoemail ===1),
      hasnosms: (this.props.parentCampaign.hasnosms ===1)
    }); 

  }
  
//method to handle signUp submit button
handleSubmit = async event => {
  //this.state.result = "working..."
  event.preventDefault();
  var {emailbody,emailsubject} = this.state;

  if(emailsubject !== null && emailsubject.length>0)
  {
  var contentMatchSubject="";
  this.props.parentCampaign.fwl.map(e => {
    contentMatchSubject += emailsubject.match(new RegExp(`\\b${e}\\b`, 'gi'))!== null ? e + ", " : ''
    }
  )
  contentMatchSubject = contentMatchSubject.slice(0, -2);
  this.setState({ contentMatchSubject})
  
  }


  if(emailbody !== null && emailbody.length>0)
  {
  var contentMatch="";
  this.props.parentCampaign.fwl.map(e => {
    contentMatch += emailbody.match(new RegExp(`\\b${e}\\b`, 'gi'))!== null ? e + ", " : ''
    }
  )
  contentMatch = contentMatch.slice(0, -2);
  this.setState({ contentMatch})
  
  if(contentMatchSubject.length>0 || contentMatch.length>0)
    return false;
  }

  if(!this.state.isdirty)
  {
    this.props.goto2_5();
    return
  }

  let emailbodyCSS = this.applyInlineStyle(this.state.emailbody)// await getData({html: this.state.emailbody},"convertemailhtml"); 

  let body= {
    "procname" : "procEventSave_v1_4",
    "paramcount": "25", 
    "param1": this.state.campaignid,
    "param2": this.state.clientid,
    "param3" : 0, //campaignname
    "param4" : 0 , //listid 
    "param5": this.state.emailbody, //inNotificationMessage
    "param6": 0, //inWebHeaderMsg
    "param7": 0, //inWebDetailedMsg
    "param8": 0, //inWebFooterMessage
    "param9": 0, //inRecallHotline
    "param10": 0, //inRemedyText
    "param11": 0, //inEventDescription
    "param12": this.state.emailsubject, //inNotificationSubject
    "param13": this.state.login, //createdby
    "param14": this.state.login, //updatedby
    "param15": this.state.email ,//email
    "param16": emailbodyCSS, //inNotificationMessage
    "param17": 0, //inLogoURN
    "param18": 0, //inSiteColor   
    "param19": 0, //inSmsMessage  //inSiteColor   
    "param20": 0, //inAllowMessageType       
    "param21": 0, //inEventTypeCode       
    "param22": 0, //inEventTypeConfirmedBy       
    "param23": 0, //inEventTypeConfirmedOn
    "param24": this.state.emailHeaderColor, //emailHeaderColor
    "param25": this.state.emailLogoURN   //emailLogoURN       
    }

    await getData(body)
   .then(json => {
    if(json.errorMessage === undefined){
      this.props.updateDirtyProductFlag(false);
      this.props.goto2_5('Campaign Email Info is saved');
    }
    else  
      this.setState({ isLoading: false, result : json.errorMessage})
     });
  //this.props.updateCampaignState(this.state.campaign);
}

renderOptions() {
  return this.state.emailfrom.map(e => {
          return(<option selected={this.state.email=== e.Email} key={e.Email}>{e.Email}</option>)
      })
}


  renderForm () {
    const { classes } = this.props;
    const {emailfrom} = this.state;
    let src='https://' + config.s3.BUCKET + '.s3.us-east-2.amazonaws.com/public/RRRClientLogo/Event_' + this.state.emailLogoURN + '_logo_email.png'
    return (
    <Form onSubmit={this.handleSubmit}>


    <div id='second' style={{visibility:this.state.hasnoemail?'hidden':'visible'}}> 

      <div className={classes.root}>
      {/* <Grid container> */}
        <Grid container>
        <Grid item xs={8}>
        <FormLabel component="legend" style={{marginTop:"10px"}} className={classes.formControl}> Primary Branding Color</FormLabel>
        {/* <Tooltip title="Click to pick a color"> */}
        <ColorPicker animation="slide-up" color={this.state.emailHeaderColor} enableAlpha={false} mode={'RGB'} onChange={this.changeHandler} >     
        <div><font color={this.state.emailHeaderColor}>/\/\/\/\<br/>\/\/\/\/<br/></font></div> 
        </ColorPicker>
        {/* </Tooltip> */}
        </Grid>
        <Grid item xs={8}>
        <FormLabel component="legend" style={{marginTop:"30px"}} className={classes.formControl}> Logo</FormLabel>
        <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,  campaignid:this.state.campaignid}}}>
          <Tooltip title="Click to upload a new logo">
          <img id="logo" width="72" height="72" onClick={this.handleImgClick} src={src}/>
          </Tooltip>
        </Link>
        {/* <Tooltip title="Delete">
          <Delete style={{align:"bottom",  color: "#1782c5",paddingBottom:"3px" }} onClick={()=>{alert('why delete?');}}  /> 
        </Tooltip>         */}
         <input name="fileControl" id="file" type="file" onChange={this.handleChangeFile} style={{display: "none"}} />
         <FormLabel component="legend" />
         </Grid>
        <Grid item xs={8}>
          <TextField
            id="email"
            select
            label="From"
            className={classes.textField}
            required
            value={this.state.email}
            // style={{width:"350px"}}
            //onChange={caller.handleChange('plan')}
            onChange={this.handleChange('email')}
            margin="normal"
            style={{margin:"30px 0 0px 0"}}
            variant="outlined"
            InputLabelProps={{shrink: true}}
          >
            {emailfrom.map(e => (
              <MenuItem selected={this.state.email=== e.Email} key={e.Email} value={e.Email}>
                {e.Email}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <div id='sendEmail' style={{visibility:!this.validateForm()?'hidden':'visible'}}> 
          <FormLabel class="add_section_title" style={{paddingLeft:"5px"}}> Preview Email</FormLabel>
          <FormGroup row>  
          <TextField
              id="previewemail"
              label="To"
              className={classes.textField}
              // style={{marginTop:"12px",width:"250px"}}
              error={this.state.previewemail.length>0 && (!new RegExp(/\S+@\S+\.\S+/).test(this.state.previewemail))}
              helperText={this.state.previewemail.length>0 && (!new RegExp(/\S+@\S+\.\S+/).test(this.state.previewemail))?'Invalid format':''}  
              value={this.state.previewemail}
              onChange={this.handleChange()}
              margin="normal"
              //required
              variant="outlined"
              InputLabelProps={{shrink: true}}
            />  
          <Button
              //type="submit"
              style={{backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"}}
              variant="outlined" //color="secondary"     
              className={classes.submit}
              alignItems="flex-end"
              onClick={() => this.sendEmail()}  
              disabled={this.state.previewemail.length===0 || (!new RegExp(/\S+@\S+\.\S+/).test(this.state.previewemail))}
          >
            Send
          </Button>    
          </FormGroup>              
          </div>
        </Grid>
        <Grid item xs={8}> 
            <TextField
            id="emailsubject"
            label="Subject"
            //required   // can't use required or causes focus error when hidden
            value={this.state.emailsubject}
            onChange={this.handleChange()}
            className={classes.textField}
            margin="normal"
            style={{margin:"30px 0 0px 0", width:"60%"}}
            
            error={this.state.contentMatchSubject.length > 0}
            helperText={this.state.contentMatchSubject.length > 0 ? `Words like ${this.state.contentMatchSubject} are NOT permitted` : ""}
            variant="outlined"
            placeholder="[subject name]"
            InputLabelProps={{shrink: true}}
            />
        </Grid>
        </Grid>
      </div>     
      <div class="app" style={{paddingLeft:"0px", marginTop: "25px"}}>
            {/* <FormGroup controlId="emailbody1" bsSize="small"> */}
            <Form.Group controlId="webdetail" bsSize="small">
              <Form.Label class="add_section_title">Body</Form.Label>   
              { (this.state.contentMatch.length>0) ?
                <span style={{marginLeft:"30px", width:"70%"}}> Words like <font color="red">{this.state.contentMatch} </font> are NOT permitted</span>  
                : ''
              }  
                <ReactQuill 
                    modules={this.modules}
                    formats={this.formats}
                    style={{minHeight:"200px",width:"60%"}}
                    value={this.state.emailbody}
                    id = "emailbody1"
                    bounds={'.app'}
                    onChange={this.handleBodyChange1}
                    />
        </Form.Group>
      </div>
            {/* <div>{this.applyInlineStyle(this.state.emailbody)}</div>     */}

    </div>



    <div >
        <LoaderButton
          block
          bsSize="small"
          type="submit"
          isLoading={this.state.isLoading}
          style={{marginLeft:"85%", width:"80px",bottom:"30px",position:"fixed"}}
          text="Next"
          //loadingText="Saving Campaign�"
          disabled={!this.validateForm() || this.props.parentCampaign.launchstatus !== 'PE'}
        />
        </div>   
      </Form>
    )
  }
}

CampaignEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CampaignEmail);