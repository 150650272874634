import React, { Component, UIComponent } from "react";
import { Link, BrowserRouter as Router, Route } from "react-router-dom";
//import {Form, FormControl, FormGroup, ColHelpBlock,ControlLabel} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
//import "./CreateUser.css";
import { Auth } from "aws-amplify";
import AWS from 'aws-sdk';
import config from "../config";
import ReactTable from "react-table";
import ReactTooltip from 'react-tooltip';
import "react-table/react-table.css";
import queryString from 'query-string';
import LeftMenu, {PageHeader} from '../components/leftmenu';
//import { container } from "@aws-amplify/ui";
import DatePicker  from  "react-datepicker";
import Moment from 'react-moment';
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from 'react-dom';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import {RadioGroup, Radio} from "react-radio-group";
import "../css/rrr.css";
import Form from 'react-bootstrap/Form';
import {getData} from "./SharedFunctions";
import AddCircle from '@material-ui/icons/AddCircle';
import MUIDataTable from "mui-datatables";
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import PropTypes from 'prop-types';
import {  FormGroup, FormLabel, Button, MenuItem, TextField} from '@material-ui/core';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: red[800],
    },
  },
  menu: {
    width: 300,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    backgroundColor: "white"
  },
  popupCaption: {
    marginLeft: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: "#f7f7f7",
    marginTop:theme.spacing(3),
  },
  table: {
    marginTop: theme.spacing.unit * 2,
    width: "90%"
  },  
  title: {
    color: "#00956e",
    marginTop: theme.spacing(2),
    fontWeight:"fontWeightBold",
    fontFamily: "AvenirNextLTPro",
    height: "24px",
    fontSize: "14px",
  }, 
  title_grey: {
    color:grey[400],
    fontWeight:"fontWeightLight",
    marginTop: theme.spacing(2),
    marginRight: "40px",
    fontFamily: "Ebrima",
    fontSize: "14px",
    fontStyle:"italic",
  }, 
  submit: {
    //margin: theme.spacing(3, 0, 2),
    backgroundColor:"#1782c5",
    marginTop: "18px", 
    height:"53px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
});

class Campaigns extends Component {
  
  constructor(props) {
    super(props);

      //const clientinfo = {clientid:null};

    this.state = {
      isLoading: false,
      isSearching: false,
      dataSource: [],
      campaignDataSource : [],
      clientid: null,
      clientColor: '',
      login: props.login,
      logoURN:"",
    };
  }

	//method to handle signUp submit button
	async componentDidMount() {
    // debugger;
    // console.log('props');
    // console.log(this.props);

    this.state.clientid = this.props.clientid;
    
    if(this.state.clientid === -1)
      {
        this.props.history.push({pathname: './clients',state: {clientid: -1, email: this.props.username}});
        return;
      }
     
    let body= {
      "procname" : "procClientSelect",
      "paramcount": "2", 
      "param1": this.state.clientid,
      "param2" : ""
    }
   
     await getData(body)
    .then(json => this.setState({ dataSource: json[0], clientName:json[0][0].ClientName, clientColor:json[0][0].ClientColor,logoURN: json[0][0].LogoURN.length > 0 ? json[0][0].LogoURN : '0', isLoading: true}));


    body = {
        "procname" : "procEventSelect",
        "paramcount": "2", 
        "param1": this.state.clientid,
        "param2" : 0
      }

      await getData(body)
      .then(json => this.setState({ campaignDataSource: json[0], isLoading: false}));

    
  }


  render() {
    const { isLoading,dataSource,campaignDataSource} = this.state;

    if(dataSource.length === 0)
      return <div />
      const { classes } = this.props;
      const columns = [
        {
          label : "EventID",
          name : "EventID",
          options: {
            display:false,
            filter: false, 
          }
      },   
      {
        label : "CreatedOn",
        name : "CreatedOn",
        options: {
          sortDirection: "desc",
          display:false,
          filter: false, 
        }
    },              
      {
        label : "LaunchStatusID",
        name : "LaunchStatusID",
        options: {
          display:false,
          filter: false, 
        }
    },              
        {
          label: 'Campaign Name',
          name: 'EventName',
          options: {
            
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            },  
            filterType:"custom",
            filterOptions: {
              logic: (listValue, filters) => {
                //debugger;
                if (filters.length) return !listValue.includes(filters);
                return false;
              },                             
              display: (filterList, onChange, index, column) => (
              <div>
                  <TextField
                    label='Campaign Name'
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '210px', marginRight: '5%' }}
                  />
               </div>
            )},            
            customFilterListOptions: { render: v => `Campaign: ${v}` },//chip display
            customBodyRender: (value, tableMeta, updateValue) => {
              //console.log(tableMeta)
              return (
                <Link onClick={()=>this.props.handleStateChange(this.props.clientid,tableMeta.rowData[0])} to={{
                  pathname: "CampaignNew",
                  state: {
                    campaign: value,
                    //selectedeventid: this.state.campaignDataSource[row._index].EventID,
                    campaignid: tableMeta.rowData[0],
                    launchstatus: tableMeta.rowData[1],
                    clientid: this.props.clientid,
                    login: this.props.username
                    }
                  }}>{value}</Link>                
              );              
          },            
          },
        },          
        {
          label: 'Launch Date',
          name: 'DtLaunch',
          options: {
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            },                
            filter: false,   
           // customFilterListOptions: { render: v => `Date: ${v}` },             
          },
        },
        {
          label: 'Allowed Delivery Types',
          name: 'AllowedMessageType',

          options: {
            //hint: '',
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            },   
            filter: false,                
            //customFilterListOptions: { render: v => `Notified: ${v}` },
          },
        }, 
        {
          label: 'Total Contacts',
          name: 'TotalContacts',

          options: {
            //hint: '',
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            },   
            filter: false,                
            //customFilterListOptions: { render: v => `Notified: ${v}` },
          },
        }, 
        {
          label: 'Delivered',
          name: 'Notified',
          options: {
            hint:"Number of contacts that had at least one notification email delivered",
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            },   
            filter: false,                
            //customFilterListOptions: { render: v => `Notified: ${v}` },
          },
        },    
        {
          label: 'Failed',
          name: 'FailedCount',
          options: {
            hint:"Number of contacts that did not have any notification emails delivered",
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            },   
            filter: false,                
            //customFilterListOptions: { render: v => `Notified: ${v}` },
          },
        },                   
        {
          label: 'Responders',
          name: 'Responded',
          options: {
            hint: "Number of contacts that have submitted a response",
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            }, 
            filter: false,                  
            //customFilterListOptions: { render: v => `Notified: ${v}` },
          },
        },                 
        {
          label: 'Non-Responders',
          name: 'NotResponded',

          options: {
            hint: "Number of contacts that have not submitted a response",
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            },       
            filter: false,            
            //customFilterListOptions: { render: v => `Notified: ${v}` },
          },
        },         
        {
          label: 'Status',
          name: 'LaunchStatus',
          options: {
            hint:"A Pending Launch campaign has not started and may still be edited. A Launched campaign has started and no changes may be made. A Cancelled campaign has been stopped, emails will not be sent and responses will not be accepted",
            setCellHeaderProps: (value) => {
              return {
                  style: {fontWeight: "bold"}
              };
            },                
            customFilterListOptions: { render: v => `Status: ${v}` },
          },
        },                         

      ];        

      const options = {
        filter: true,
        filterType: 'multiselect',
        responsive: 'stacked', //scroll, 'scrollMaxHeight'
        page: 0,
        rowsPerPage: 10,
        rowsPerPageOptions:  [5, 10, 20, 25],
        searchText: '',
        searchPlaceholder: 'Search...',
        selectableRows: false,
        print: false,
        download: false,
        viewColumns: false,
        customToolbar: null,
      }

    return (
      <div>
        <LeftMenu selected="campaigns" confirmationUnsaved_LM={(tmp) => event => {return true}} clientid={this.state.clientid} props={this.props}/>

        <div className="MainInnerWrapperDiv">
        <PageHeader title="Campaigns" username={this.props.username} clientname={this.props.isSRCLUser?this.state.clientName:''}/>

            <div style={{float:"left",  paddingLeft:"30px", width : "95%"}}>
            <Link to={{pathname: "CampaignNew", state: {clientid: this.state.clientid,login: this.props.username}}}            
                  style={{width:"200px", display: 'flex',fontSize:"15px",alignItems: 'center'}} >
                  <AddCircle style={{color: "#1782c5",paddingRight:"3px" }}/>Create New Campaign</Link>
              {/* {(!this.state.hideForm) && <div>{clientForm(this,classes)} </div>} */}
              <MUIDataTable className={classes.table} data={campaignDataSource} columns={columns} options={options} />
            </div>      
        </div>  
      </div>
    );
  }
}

export default withStyles(styles)(Campaigns);