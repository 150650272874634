import React from "react";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import "./LoaderButton.css";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button
    className={'LoaderButton ${className}'}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && 
      <Spinner 
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
      />}

    {!isLoading ? text : loadingText}
  </Button>;
