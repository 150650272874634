import Amplify, { Storage } from "aws-amplify";
import config from "../config";
var AWS = require('aws-sdk');


AWS.config.update({
  region : config.apiGateway.REGION,
}); 


export async function s3Remove(filename) {

  const result = await Storage.remove(filename, {
    bucket:config.s3.BUCKET
  })
  // .then(result => console.log(result))
  .catch(err => console.log(err));
  
  return result;
}

export async function s3Get(filename) {

  //const result = await Storage.get(filename, {
  //  bucket:config.s3.BUCKET
  //})
  //// .then(result => console.log(result))
  //.catch(err => console.log(err));
  
  const result = await Storage.get(filename, { download: true, bucket:config.s3.BUCKET }).catch(err =>  console.log(err))

  return result;
}

export async function s3Upload(file, filename,isContactList=false) {

  let currentBucket = config.s3.BUCKET;
  if(isContactList)
    currentBucket = config.s3.ContactListBUCKET;

    
  const stored = await Storage.put(filename, file, {
    bucket:currentBucket,
    contentType: file.type
  }).catch(err => console.log(err));

  return stored.key;
}
