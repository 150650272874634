'use strict'
import React from 'react';
//import {FormGroup,FormControl,ControlLabel} from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import "../css/rrr.css";
import LoaderButton from "../components/LoaderButton";
import Form from 'react-bootstrap/Form';
//import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import config from "../config";
import { API } from "aws-amplify";
import { s3Upload, s3Remove } from "../libs/awsLib";
import {getData} from "./SharedFunctions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
// import Tooltip from 'react-bootstrap/Tooltip'
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import {FormControlLabel,FormGroup,FormControl,FormLabel,RadioGroup,Radio} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import {Grid, Button, MenuItem, TextField, Checkbox} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link} from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import OndemandVideo from '@material-ui/icons/OndemandVideo';

const styles = theme => ({
  formControl: {
    //margin: theme.spacing(1),
    color:"black",
    fontWeight: 500
  },
  submit: {
    //margin: theme.spacing(3, 0, 2),
    backgroundColor:"#1782c5",
    marginTop: "18px", 
    height:"53px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
});

class CampaignResponseP extends React.Component {
  constructor () {
    super()

    this.modules = {
      toolbar: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{ 'align': [] }],
          [{ 'color': [] }, { 'background': [] }],
          ['link'],
          ['clean']
        ]
    };
  
    this.formats = [
        'font',
        'size',
        'bold', 'italic', 'underline',
        'list', 'bullet',
        'align',
        'color', 'background',
        'link'
      ];


      this.attrNames = {  // [0] - UsageTypeCode (TX,DD,NU)   [1] - Options - comma delimited string  [2] - label
        Qty: ['Qty','Quantity'] ,       
        Model: ['Model','Model Number'],
        Serial: ['Serial','Serial Number'],
        BatchLot: ['BatchLot','Batch / Lot Number'],
        NDC: ['NDC','NDC'],
        UPC: ['UPC','UPC'],
        PartNumber: ['PartNumber','Part Number'],
        ExpDate: ['ExpDate','Expiration Date'],
        DateCode: ['DateCode','Date Code'],
    }

    this.imgVars = {
      img1: '', img2:'', img3:'', img4:'', img5:'', img6:'',
      file1: '', file2:'', file3:'', file4:'', file5:'', file6:'',
      imgDirty1: 0, imgDirty2: 0, imgDirty3: 0, imgDirty4: 0, imgDirty5: 0, imgDirty6: 0
    };

      this.state = {
        isLoading: false,
        isLoadingMedia:false,
        clientname: "",
        clientid: 0,
        campaignid:0,
        productid: 0,
        mediaOrder: 0,
        productSource: [],
        productMediaSource:[],
        mediaSource:[],
        login:'admin@stericycle.com',
        campaignname:"",
        hasProduct:false,
        expanded:'panel0',
        //panelDelete:'',
       // isDirty:0,
        imgS3: 'https://' + config.s3.BUCKET + '.s3.us-east-2.amazonaws.com/public/RRRProductMedia/',
      };

      this.imageClicked= {
        productid:-1,
        sortOrder:-1,
        file:'',
        path:''
      }

      this.mediaUpdateFlag = false
  }

  componentWillMount() {
    // console.log(" in wiz2",this.props);
    if(this.props.parentCampaign.campaignid !== undefined && this.props.parentCampaign.campaignid !== 0){
      this.setState({
        campaignname:this.props.parentCampaign.campaignname, 
        campaignid:this.props.parentCampaign.campaignid,
        clientid: this.props.parentCampaign.clientid,
        emailsubject: this.props.parentCampaign.notificationsubject,
        emailbody: this.props.parentCampaign.notificationbody,
        launchstatus: this.props.parentCampaign.launchstatus,
        login: this.props.parentProps.username
      });
    }
    
  }

  async componentDidMount() {
    if(this.props.parentCampaign.campaignid !== undefined && this.props.parentCampaign.campaignid !== 0){
      this.setState({
        campaignname:this.props.parentCampaign.campaignname, 
        campaignid:this.props.parentCampaign.campaignid,
        clientid: this.props.parentCampaign.clientid,
        emailsubject: this.props.parentCampaign.notificationsubject,
        emailbody: this.props.parentCampaign.notificationbody,
        launchstatus: this.props.parentCampaign.launchstatus,
        login: this.props.parentProps.username
      });
    }    
    if(this.props.parentCampaign.hasnoresponse===1)
      {
        this.props.goto3('Response Site is required to configure Products');
        return
      }

    await this.getProductMediaList()
    .then (
      await this.getProductList()
      .then(
          ()=>     
          {    
            const{productMediaSource,mediaSource} = this.state
            productMediaSource.map(el=>{
                mediaSource.find(x=>x.ProductID===el.ProductID)[`img${el.SortOrder}`] = el.MediaURL
            }
            )
            // console.log('mediaSource_M')
            // console.log(this.state.mediaSource)  
            // console.log(mediaSource)
          }      
      )
    )  
    window.scrollTo(0, 0);
  }


  async getProductList (){

    this.setState({isLoading:true})

    let body= {
      procname: "procEventProductSelect",
      paramcount: 1,
      param1: this.state.campaignid
    }    
    

    await getData(body)
    .then(json =>   
      {this.setState({isLoading:false})
       if (json[0].length >= 1) {
        const productSource_tmp= json[0].map(el => {
          return {
               ...el, 
               SerialOptionsError:false,
               BatchLotOptionsError:false,
               NDCOptionsError:false,
               UPCOptionsError:false,
               PartNumberOptionsError:false,
               ExpDateOptionsError:false,
               DateCodeOptionsError:false,
               QtyOptionsError:false, 
               SerialOptionsCommaError:false,
               BatchLotOptionsCommaError:false,
               NDCOptionsCommaError:false,
               UPCOptionsCommaError:false,
               PartNunberOptionsCommaError:false,
               ExpDateOptionsCommaError:false,
               DateCodeOptionsCommaError:false,
               QtyOptionsCommaError:false,
               isDirty:false,
               isExistingProductName:false,
               isEmptyProductName:false,         
               };   
              }
            )    

            const mediaSource_tmp= json[0].map(el => {
              let ProductID = el.ProductID
              return {
                  // ...el,
                  ProductID,
                  ...this.imgVars
              };
          });
        
          this.setState({
            hasProduct:true,
            productSource: productSource_tmp,
            mediaSource:mediaSource_tmp  
          })
        }
        
        // console.log('mediaSource')
        // console.log(this.state.productSource)
        // console.log(this.state.mediaSource)
      }
   );
  }


  //this function gets all media for the campaign - all products
  async getProductMediaList (){

    this.setState({isLoadingMedia:true});

    let body= {
      procname: "procProductMediaSelectByEvent",
      paramcount: 1,
      param1: this.state.campaignid
    }

    await getData(body)
    .then(json =>   { if (json[0].length >= 1) {
        this.setState({productMediaSource: json[0],isLoadingMedia:false});
      }
    })
  }

  handleAddNewProduct = async (event) => {

   if(this.props.parentCampaign.launchstatus !== 'PE')  
    return

   let {productSource} = this.state
   //debugger;
       let flag = false
       // if(!this.validateForm())
       //   return
       
       let i=productSource.findIndex(x=>{return x.ProductID===0})
       //let i = questions.length-1
       if(i>=0)  // found, not -1
       {
         if(!this.validateForm())
           return
          let product = productSource[i]
         // //no error - save
          this.handleProductUpdates(product,true);
          this.setState({productSource})
       }

   this.setState((exisingState) => ({
    productSource: [...exisingState.productSource, 
      {
        ProductID: 0,
        ProductOrder: exisingState.productSource.length+1, 
        ProductName: '',
        ProductDescription: "",
        ModelUsageTypeCode: "NU",
        SerialUsageTypeCode: "NU",
        BatchLotUsageTypeCode: "NU",
        NDCUsageTypeCode: "NU",
        UPCUsageTypeCode: "NU",
        PartNumberUsageTypeCode: "NU",
        ExpDateUsageTypeCode: "NU",
        DateCodeUsageTypeCode: "NU",
        QtyUsageTypeCode: "TX",
        ModelOptions: "",
        SerialOptions: "",
        BatchLotOptions: "",
        NDCOptions: "",
        UPCOptions: "",
        PartNumberOptions: "",
        ExpDateOptions: "",
        DateCodeOptions: "",
        QtyOptions: "",
        SerialOptionsError:false,
        BatchLotOptionsError:false,
        NDCOptionsError:false,
        UPCOptionsError:false,
        PartNumberOptionsError:false,
        ExpDateOptionsError:false,
        DateCodeOptionsError:false,
        QtyOptionsError:false, 
        SerialOptionsCommaError:false,
        BatchLotOptionsCommaError:false,
        NDCOptionsCommaError:false,
        UPCOptionsCommaError:false,
        PartNunberOptionsCommaError:false,
        ExpDateOptionsCommaError:false,
        DateCodeOptionsCommaError:false,
        QtyOptionsCommaError:false,
        isDirty:true,
        isEmptyProductName:false,
        isExistingProductName:false
      }],
    mediaSource: [...exisingState.mediaSource,
      {
        ProductID: 0,
        ...this.imgVars
      }]  
   }));
   if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
  }


 handleProductUpdates = async(product,isNew) => {

  let body= {
    "procname" : "procEventProductSave",
    "paramcount": "35", 
    "param1": this.state.clientid,
    "param2": this.state.campaignid,
    "param3" : product.ProductID, 
    "param4" : product.ProductOrder, 
    "param5": product.ProductName, //
    "param6": product.ProductDescription, //inproductdesc

    "param7": product.ModelDirty !==undefined, //inmodelupdate
    "param8": product.ModelUsageTypeCode, //covert to UN/DD/TX
    "param9": product.ModelOptions, //inmodeloptions

    "param10": product.SerialDirty !==undefined,
    "param11": product.SerialUsageTypeCode, //covert to UN/DD/TX
    "param12": product.SerialOptions, //inmodeloptions

    "param13": product.BatchLotDirty !==undefined,
    "param14": product.BatchLotUsageTypeCode, //covert to UN/DD/TX
    "param15": product.BatchLotOptions, //inmodeloptions

    "param16": product.NDCDirty !==undefined, //ndc
    "param17": product.NDCUsageTypeCode, //covert to UN/DD/TX
    "param18": product.NDCOptions, //inmodeloptions

    "param19": product.UPCDirty !==undefined, //upc
    "param20": product.UPCUsageTypeCode, //covert to UN/DD/TX
    "param21": product.UPCOptions, //inmodeloptions

    "param22": product.PartNumberDirty !==undefined, //partnum
    "param23": product.PartNumberUsageTypeCode, //covert to UN/DD/TX
    "param24": product.PartNumberOptions, //inmodeloptions

    "param25": product.ExpDateDirty !==undefined, //exp
    "param26": product.ExpDateUsageTypeCode, //covert to UN/DD/TX
    "param27": product.ExpDateOptions, //inmodeloptions

    "param28": product.DateCodeDirty !==undefined, //datecode
    "param29": product.DateCodeUsageTypeCode, //covert to UN/DD/TX
    "param30": product.DateCodeOptions, //inmodeloptions

    "param31": product.QtyDirty !==undefined, //qty
    "param32": product.QtyUsageTypeCode, //covert to UN/DD/TX
    "param33": product.QtyOptions, //inmodeloptions

    "param34": this.state.login, //createdby
    "param35": this.state.login, //updatedby

    }

     await getData(body)
    .then(json => {
      var {mediaSource} = this.state
      var media = mediaSource.find(x=>x.ProductID===product.ProductID)

      if(isNew) {
        product.ProductID = json[0][0].outProductID
        product.isDirty=false
        media.ProductID = json[0][0].outProductID
        
        // console.log(mediaSource)
        // console.log(product)
      }
      //upload media
      this.handleSubmitMedia(media,isNew)    

      this.setState({mediaSource})
    }
    )       
 }


  handleProduct = (event) => {
    if(event.target.value==='0') //no product
    {
      this.props.goto5('No Product Configured');
      return
    }
    this.setState({ hasProduct: true })
  }

  imageDelete = async (productid, sortOrder) => {
      let {mediaSource} = this.state
      let obj = mediaSource.find(x=>{return x.ProductID===productid})//['img'+productorder]
      //nothing to delete
      if(obj['img'+sortOrder].length===0) return

      if(obj['imgDirty'+sortOrder]===0)  //not new - saved - remove from DB and S3
      {
      let body= {
        "procname" : "procProductMediaDelete",
        "paramcount": "3", 
        "param1" : productid,
        "param2": sortOrder,
        "param3" : this.state.login 
        }

        await getData(body)
        s3Remove('RRRProductMedia/' + obj['img'+sortOrder])
      }

      obj['img'+sortOrder]=''
      obj['file'+sortOrder]=''
      obj['imgDirty'+sortOrder]=0
      this.setState({mediaSource})
}
  
  validateFileType(file){
    if (file=== undefined) return
      let allowedExtensions = /(\.mp4|\.mov)$/i;
      if (file.size > config.MAX_ATTACHMENT_SIZE) {
        //alert(`Please pick a video smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
        toast.info(`Please pick a video file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`, {position: toast.POSITION.TOP_RIGHT})
        return false;
      }
    
      if (!file.type.toLowerCase().startsWith('video') || !allowedExtensions.exec(file.name)) {
        //alert(`Only video file formats are allowed for upload.`);
        toast.info(`Only .MP4 and .MOV file formats are allowed for upload.`, {position: toast.POSITION.TOP_RIGHT, autoClose: 5000})
        return false;
      }
      return true;
  }


  productDelete (pid, productname)
  {
    confirmAlert({
      message : `Are you sure you want to delete Product ${productname} ?`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Yes',
          onClick: async () => {
            // if(this.state.productMediaSource!== undefined)
            // {

            let {mediaSource,productSource} = this.state
              //debugger;
              let media = {...mediaSource.find(el=>{return el.ProductID === pid})}
              //if(media.length > 0)
              Array(6).fill().map((_,i)=> {
                if(media['img'+(i+1)].length > 0)
                    s3Remove('RRRProductMedia/' + media['img'+(i+1)])

              });

            let body = {
              "procname" : "procEventProductDelete",
              "paramcount": "2", 
              "param1": pid,
              "param2": this.state.login
              }
          
            await getData(body)
            .then(json => {
                let ind = mediaSource.findIndex(el=>{return el.ProductID === pid})  
                mediaSource.splice(ind,1)
                productSource.findIndex(el=>{return el.ProductID === pid})    
                productSource.splice(ind,1)
                this.setState({mediaSource,productSource})
                if(productSource.length===0)
                  this.props.updateDirtyProductFlag(false);
            })
            // .then(()=>toast.info(`Product ${productname} has been deleted `, {
            //   position: toast.POSITION.TOP_RIGHT})   )


          }
        }
      ]
    }); 
  }



  handleAttrCheck = event => {
    
    let {productSource} = this.state
    let product = productSource[event.target.value]//index
    
    // console.log(product)
    let attrName = event.target.id  //key name
    product.isDirty = 1
    product[attrName+'Dirty']=1  //raise dirty flag on the object

    product[attrName+'UsageTypeCode'] = !event.target.checked ? 'NU' : 'TX'
    product[attrName+'Options'] = ''

    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)

    this.setState({productSource})
    //console.log(this.state.productSource)
  }

  handleSelectAttrChange = (name,i) => event => {
    
    // console.log(name)
    // console.log(event.target)
    let {productSource} = this.state
    let product = productSource[i]

    //console.log(product)
    product[name+'Dirty']=1  //raise dirty flag on the object
    product.isDirty = 1
    product[name+'UsageTypeCode'] = event.target.value  //TX or DD

    if(event.target.value !== 'DD')
    {
      product[name+'Options'] = ''
    }
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
    //productSource[event.target.value] = product;
     this.setState({productSource})
  }

  handleChangeOptions = event => {

    // console.log(event.target)

    let {productSource} = this.state
    let product = productSource[event.target.name]

    let attrName = event.target.id
    product.isDirty = 1
    product[attrName+'Dirty']=1  //raise dirty flag on the object
    product[attrName+'Options'] = event.target.value
    
    if(event.target.value.length>1)
    {
      product[attrName+'OptionsError']=false 
      product[attrName+'OptionsCommaError']=false
    }
        
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
    this.setState({productSource})

  }
  

  handleChangeProductName = event => {

    let {productSource} = this.state
    let product = productSource[event.target.id]

    product.isDirty = 1
    product[event.target.name] = event.target.value
    if(event.target.value.length>1)
      {
        product.isExistingProductName=false;
        if(productSource.filter(x=>{return x.ProductID!= product.ProductID && x.ProductName===event.target.value}).length>0)
        {
          product.isExistingProductName=true
        }
        else
          product.isEmptyProductName = false
      }

    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
    this.setState({productSource})
  }

  handleChangeProductDescription = (i) => event => {
    
    // console.log(event.target)

    let {productSource} = this.state
    let product = productSource[i]

    product.isDirty = 1
    product.ProductDescription = event

    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
    this.setState({productSource})
  }

 handleChange = (panel) => (event, newExpanded) => {
    //     console.log(panel)
    //     console.log(newExpanded)
    
    
    //  console.log(panelDelete)
    //debugger;
       if(panel==='panel0')
       {
        newExpanded=true
       }
       this.setState({expanded:newExpanded ? panel : false})
       window.scrollTo(0, 0);
        //setExpanded(newExpanded ? panel : false);
        // console.log('after')
        // console.log(this.state.expanded)
    
      };
  
  render() {
    
  if(this.props.parentCampaign.campaignid===undefined)
  {
    return (<div>...Campaign is not configured</div>)
  }

  return (
      <div style={{width:"100%"}}>
         {this.renderProductList()}
         <div >
          <LoaderButton
            block
            bsSize="small"
            type="submit"
            isLoading={this.state.isLoading}
            style={{marginLeft:"85%", width:"80px",bottom:"30px",position:"fixed"}}
            text="Next"
            onClick={this.handleSubmit}
            //loadingText="Saving Campaign�"
            //disabled={!this.validateForm() || this.props.parentCampaign.launchstatus === 'LN'}
            disabled={this.props.parentCampaign.launchstatus !== 'PE'}
          />
          </div>  
      </div>
    );
  }


validateForm()  {
  let {productSource} = this.state
  let flag = false

  productSource.filter(x=>{return x.ProductName.length===0}).forEach(x=>
    {
    flag = true; 
    return x.isEmptyProductName = true;
    }
  )

  if(productSource.filter(x=>{return x.isExistingProductName}).length>0)
    flag = true; 


//debugger;
  {Array(9).fill().map((_,ix)=> 
    {
    let attrName= Object.keys(this.attrNames)[ix]
    productSource.filter(x=>{return x[attrName+'UsageTypeCode']==='DD' && x[attrName+'Options'].length===0})
    .forEach(x=>
        {
          flag = true; 
          return x[attrName+'OptionsError']=true
        }
      )
      
      productSource.filter(x=>{return x[attrName+'UsageTypeCode']==='DD' && x[attrName+'Options'].length>0 && !x[attrName+'Options'].includes(",")})
      .forEach(x=>
          {
            flag = true; 
            return x[attrName+'OptionsCommaError']=true
          }
        )
    }
    )}

    if(flag)
    {
      // console.log(productSource)
      this.setState({productSource})
      window.scrollTo(0, 0);
      return false
    }

    //alert('all is great')
    return true
  }


//method to handle signUp submit button
handleSubmit = async event => {
  event.preventDefault();
//debugger;
  let {productSource,mediaSource} = this.state

  if(productSource.length===0)
    this.props.updateDirtyProductFlag(false);

  productSource.filter(x=>{return !x.isDirty}).forEach(product=>{
    this.handleSubmitMedia(mediaSource.find(x=>{return x.ProductID===product.ProductID}),false)
   // this.props.updateDirtyProductFlag(false);  
  })

  let updateSet = productSource.filter(x=>{return x.isDirty})
  
  if(updateSet.length ===0 && !this.mediaUpdateFlag)
  {
    this.props.goto5();
    return
  }

  if(updateSet.length > 0 && !this.validateForm())
    return

 //this.props.updateDirtyProductFlag(false);   
  // console.log(this.props.isproductdirty)
  updateSet.map((product) =>
  {
    let isNew = product.ProductID===0 
    this.handleProductUpdates(product,isNew)
    //console.log(body);
  })

// media updates and uploads for existing products with no other changes
//debugger;



// mediaSource.filter(x=>{return x.isDirty===0}).forEach(media=>{
//   this.handleSubmitMedia(media,false) 
//   this.props.updateDirtyProductFlag(false);  
// })

 this.setState({productSource,mediaSource})
//  console.log(this.props.isproductdirty)
 this.props.updateDirtyProductFlag(false);  
 this.props.goto5('Campaign Products are saved',true);

}


handleSubmitMedia = async(media,isNew) => {
  if(media===undefined) return
  {Array(6).fill().map(async (_,ix)=> 
    {
      let i = ix+1;
      
      if(media[`imgDirty${i}`]===1)
      {
        this.mediaUpdateFlag=true;
        if(isNew)  
          media[`img${i}`] = media[`img${i}`].replace('_0_',`_${media.ProductID}_`)
        let path = 'RRRProductMedia/' + media[`img${i}`]
        await s3Upload(media[`file${i}`], path )
        .then(async()=>{
          let body= {
            "procname" : "procProductMediaSave",
            "paramcount": "7", 
            "param1": 0, //mediaid
            "param2" : media.ProductID,
            "param3": media[`img${i}`], // file name
            "param4" : i>=5 ? 1 : 0 , //  is video
            "param5" : i , // TO ORDER
            "param6": this.state.login, //createdby
            "param7": this.state.login, //updatedby
            }
          await getData(body)     
        })

        // let body= {

        // }  

      }
    }
  )}
}


renderProductList(){
  const { classes } = this.props;  
  const {productSource,hasProduct} = this.state

if(this.state.isLoading)
return (<div>&nbsp;</div>)

//console.log(productSource.length)

if(productSource.length===0 && !hasProduct)
    return this.renderYNForm();
else
  return (
  <div style={{width:"100%"}}>
      {productSource.map((item,i) => 
        this.renderProduct(item,i)
      )}
       <div style={{borderTop: '1px solid rgba(0, 0, 0, .125)'}}>
       <Button
            //type="submit"
            style={{backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"}}
            variant="outlined" //color="secondary"     
            className={classes.submit}
            alignItems="flex-end"
            onClick={this.handleAddNewProduct}  
            //disabled={this.state.previewemail.length===0 || (!new RegExp(/\S+@\S+\.\S+/).test(this.state.previewemail))}
            //hidden={this.state.productSource.filter(x=>(x.ProductID===0)).length>0}
            >
           {productSource.length===0 ? 'Add Product' : 'Add Another Product'}
          </Button>                  
      </div>
  </div>
  )
}


renderProduct=(product,i)=> {
  

const {expanded,productSource,productMediaSource,imgS3} = this.state
// productMediaSource.forEach(el => {
//   productSource.filter(x=>x.ProductID === el.ProductID)[`img${el.sortOrder}`] = el.MediaURL
// });

  // console.log(productSource[i])
  return (
    
    <div>
      <ExpansionPanel square expanded={expanded === `panel${product.ProductID}`} onChange={this.handleChange(`panel${product.ProductID}`)}>
        <ExpansionPanelSummary aria-controls={`panel${product.ProductID}-content`} id={`panel${product.ProductID}-header`}>
           {expanded === `panel${product.ProductID}` ?  <ExpandMore alignItems="center"/> : <ChevronRight alignItems="center"/>}
            <label class="Product_Header" style={{width:"35%"}} >{product.ProductName}</label>
          {/* <FormLabel component="legend" className={classes.formControl}>Product Name </FormLabel> */}
           
          <Edit style={{visibility:expanded === `panel${product.ProductID}`?'hidden':'visible', color: "#1782c5", marginLeft:"5%" }} onClick={this.handleChange(`panel${product.ProductID}`)}  /> 
            <label style={{visibility:expanded === `panel${product.ProductID}`?'hidden':'visible', color: "#1782c5", marginLeft:"10px"}}>Edit&nbsp;&nbsp;&nbsp;&nbsp;</label>
            |&nbsp;&nbsp;
            <Delete style={{ color: "#1782c5", marginLeft:"20px" }} 
              onClick={event => {event.stopPropagation(); if(this.props.parentCampaign.launchstatus === 'PE') this.productDelete(product.ProductID,product.ProductName)}}
              onFocus={event => {event.stopPropagation(); if(this.props.parentCampaign.launchstatus === 'PE') this.productDelete(product.ProductID,product.ProductName)}}
            /> 
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
      
        
        {/* direction="row" justify="space-between" alignItems="center"  */}
        <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} >
        <TextField
          id={i}
          name="ProductName"
          label={"Product Name"}// #" + productSource[i].ProductID}
          style={{margin:"25px 0 25px 0",width:"310px"}}
          error={productSource[i].isEmptyProductName || productSource[i].isExistingProductName}
          helperText={productSource[i].isEmptyProductName ?'Product Name is required': productSource[i].isExistingProductName ? 'Product Name already exists':''}  
          value={productSource[i].ProductName}
          onChange={this.handleChangeProductName}
          margin="normal"
          required
          variant="outlined"
          placeholder="[product name]"
          InputLabelProps={{shrink: true}}
        />           
        </Grid>
        <Grid item xs={12} >
        <div class="app">
            <FormGroup controlId="ProductDescription" bsSize="small">
              <FormLabel class="add_section_title"  style={{marginLeft:"10px"}}>Description</FormLabel>
              <ReactQuill
                  modules={this.modules}
                  formats={this.formats}
                  style={{margin:"0px 0 25px 0",width:"45%"}}
                  value={productSource[i].ProductDescription}
                  id={i}
                  bounds={'.app'}
                  onChange={this.handleChangeProductDescription(i)}
                  />
            </FormGroup>
          </div>          
        </Grid>
        <Grid item xs={12} ><label class="Product_Sub_Header" >Product Attributes to Collect</label></Grid>        
            {Array(9).fill().map((_,ix)=> this.renderAttributeSelection(i,ix) )}
          <Grid item xs={12} ><label class="Product_Sub_Header" >Photos</label></Grid> 
          <Grid item xs={12} ><label class="Product_Sub_Header" style={{fontSize:"12px"}} >Add up to 4 photos</label></Grid>                  
        <Grid container direction="row"  > 
        {/* img = 1,2,3,4 */}
            {Array(4).fill().map((_,ix)=> this.renderPhotos(productSource[i].ProductID,ix+1))} 
         <input name="fileControl" id="file" type="file" onChange={this.handleChangeFile} style={{display: "none"}} /> 
        </Grid>
        <Grid item xs={12} style={{marginTop:"30px"}}><label class="Product_Sub_Header" >Videos</label></Grid> 
          <Grid item xs={12} ><label class="Product_Sub_Header" style={{fontSize:"12px"}} >Add up to 2 videos</label></Grid>                        
          <Grid container direction="row"  >
            {this.renderPhotos(productSource[i].ProductID,5)}
            {this.renderPhotos(productSource[i].ProductID,6)}
          </Grid>        
        </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
)}

handlePhoto = (productid,sortOrder) => event => {
  if(this.props.parentCampaign.launchstatus !== 'PE')return
  this.imageClicked.productid=productid
  this.imageClicked.sortOrder=sortOrder
  document.getElementById("file").click();
}

handleChangeFile = (event) => {
  //debugger;

let {productid,sortOrder} = {...this.imageClicked}
const {campaignid,mediaSource} = this.state

  if(sortOrder <=4 && event.target.files[0].type !=='image/jpeg' && event.target.files[0].type !=='image/png')
  {
    toast.info("Please use .png or .jpeg/.jpg file format only", {
      position: toast.POSITION.TOP_RIGHT, autoClose: 5000, hideProgressBar: true  })   
    return;
  }
  if(sortOrder>=5 && !this.validateFileType(event.target.files[0]))
    return;

  let file = event.target.files[0]
  document.getElementById(`${productid}-${sortOrder}`).src = URL.createObjectURL(file);
//Math.random().toString().replace('.','')
  const fileName = 'PM_' + campaignid + '_'+ productid +'_'+ sortOrder +'.' + file.name.split('.').pop();

  let media = mediaSource.find(x=> x.ProductID===productid);
  media[`img${sortOrder}`] = fileName
  media[`file${sortOrder}`] = file
  media[`imgDirty${sortOrder}`] = 1
// console.log(mediaSource)

  this.setState({mediaSource})  //   !!!!IMPORTANT
 

}

renderPhotos (productid,sortOrder){
  //debugger;
  const {mediaSource,imgS3} = this.state
  // console.log(mediaSource)
  let srcBlank='https://' + config.s3.BUCKET + '.s3.us-east-2.amazonaws.com/public/RRRClientLogo/Event_0_logo.png'
  let src = ''

  let mediaItem = mediaSource.find(x=>{return x.ProductID===productid})
  let mediaURL = mediaItem[`img${sortOrder}`]
  if(mediaItem[`imgDirty${sortOrder}`]===1)
    src = URL.createObjectURL(mediaItem[`file${sortOrder}`])
  else
    src = mediaURL.length > 0? imgS3.concat(mediaURL) : srcBlank

  //let mediaURL = mediaSource.find(x=>{return x.ProductID===productid})[`img${sortOrder}`]


  //let src = mediaURL.length > 0? imgS3.concat(mediaURL) : srcBlank    //media[0].MediaURL
      
  if(sortOrder<=4)  
      return(
          <Grid item sm={'auto'} style={{paddingLeft:"20px", borderLeftWidth:"1px",borderLeftStyle:"solid",borderLeftColor:"#dddddd" }}>
          <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,  campaignid:this.state.campaignid}}}>
            <Tooltip title="Click to upload a new logo">
            <img id={`${productid}-${sortOrder}`} name={sortOrder} width="72" height="72" onClick={this.handlePhoto(productid,sortOrder)} src={src}/> 
            {/* handleImgClick */}
            </Tooltip>
          </Link>
          <Tooltip title="Delete">
            <Delete style={{align:"bottom",  color: "#1782c5",paddingBottom:"3px" }} onClick={()=>{if(this.props.parentCampaign.launchstatus === 'PE')this.imageDelete(productid,sortOrder)}}  /> 
          </Tooltip>        
          </Grid> 
    )
     else
            return(
              <Grid item style={{borderLeftWidth:"1px",borderLeftStyle:"solid",borderLeftColor:"#dddddd" }}>
              <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,  campaignid:this.state.campaignid}}}>     
                  {mediaURL.length===0 ?
                  // <OndemandVideo style={{width:"150px" ,height:"115px", color:"black"}} id={`${productid}-${sortOrder}`} onClick={this.handlePhoto(productid,sortOrder)}  />
                      <Tooltip title="Click to upload a new video">    
                        <img id={`${productid}-${sortOrder}`} width="90" height="72" onClick={this.handlePhoto(productid,sortOrder)} src={src}/> 
                      </Tooltip>
                       :
                      <video id={`${productid}-${sortOrder}`} style={{width:"150px",height:"115px"}} controls> 
                          <source src={src} type="video/mp4" />
                      </video>                      
                  }
              </Link>
              <Tooltip title="Delete">
                <Delete style={{align:"bottom",  color: "#1782c5",paddingBottom:"3px" }} onClick={()=>this.imageDelete(productid,sortOrder)}  /> 
              </Tooltip>        
              </Grid>  
       )
}


renderYNForm(){
  if(this.props.parentCampaign.campaignid===undefined)
  {
    return (<div>...Campaign is not configured</div>)
  }
  const { classes } = this.props;  
  return (
      <div style={{width:"100%"}}>
      <FormControl component="fieldset" >
        <FormLabel component="legend" className={classes.formControl}>Do you have a product?</FormLabel>
        <RadioGroup aria-label="product" name="productYN" onChange={this.handleProduct}>
          <FormControlLabel value="1" control={<Radio color="black"/>} label="Yes" />
          <FormControlLabel value="0" control={<Radio color="black"/>} label="No" />
        </RadioGroup>
      </FormControl>           
      </div>

  )
}

//i = product index within productSource
renderAttributeSelection(i,num){  // i - product index, num - attr index
  const {productSource} = this.state

  let attr = Object.keys(this.attrNames)[num]; //get the NAME
  // console.log(attr,this.attrNames[num])
  return(
  <>
  <Grid item xs={2} >
    <FormControlLabel
    control={
      <Checkbox 
      style={{color:"black"}} 
      checked={productSource[i][attr+'UsageTypeCode'] !== 'NU'} 
      onChange={num!==0?this.handleAttrCheck:false}
      value={i}  
      id={attr}  
      />
    }
    label={this.attrNames[attr][1]}
/>
</Grid>

  <Grid item xs={'auto'} style={{visibility:productSource[i][attr+'UsageTypeCode'] === 'NU'?'hidden':'visible'}}>

  <RadioGroup style={{paddingLeft:"20px", marginTop:"10px", borderLeftWidth:"1px",borderLeftStyle:"solid",borderLeftColor:"#dddddd"}} 
     aria-label={`attrType_${i}`} name={i} value={productSource[i][attr+'UsageTypeCode']} 
       onChange={this.handleSelectAttrChange(attr,i)}>
         {/* //productSource[i].ProductID */}
    <FormControlLabel value="DD" control={<Radio color="black"/>} label="Dropdown" />
    <FormControlLabel value="TX" control={<Radio color="black"/>} label="Text" />
  </RadioGroup>
  
  </Grid>
  <Grid item xs={7} >
  {productSource[i][attr+'UsageTypeCode']==='DD'?
  <TextField
    id={attr}
    name={i}
    label={this.attrNames[attr][2]}
    multiline
    rowsMax="2"
    error={productSource[i][attr+'OptionsError'] || productSource[i][attr+'OptionsCommaError']}
    style={{width:"100%", marginLeft:"10px"}}
    value={productSource[i][attr+'Options']}
    onChange={this.handleChangeOptions}
    placeholder="[comma delimited values: Option1, Option2]"
    helperText={productSource[i][attr+'OptionsCommaError'] ? 'Options need to be comma delimited':productSource[i][attr+'OptionsError']? 'Field is required' : ''}
    variant="outlined"
    InputLabelProps={{shrink: true}}
  />
    :<span/>
  }    </Grid>
     </>
  )
}

}

export default withStyles(styles)(CampaignResponseP);


const ExpansionPanel = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'white',
    // borderBottom: '0px solid rgba(0, 0, 0, .250)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  
  content: {
    color:"black",
    fontWeight: 500,
    fontSize: "18px",
    //fontFamily: "Ebrima",
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    //marginLeft:"0px"
  },
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor:"#f9f9f9" //"#e0f2f1"
  },
}))(MuiExpansionPanelDetails);

