import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function AuthenticatedRoute({ component: C, props: appProps, ...rest }) {
    return (
        <Route
        {...rest}
        render={props =>
            appProps.isAuthenticated
            ? <C {...props} {...appProps} />
            : appProps.forcePasswordReset ? <Redirect to={`/changepassword`} /> : <Redirect to={`/login`} />}
            // ?redirect=${props.location.pathname}${props.location.search}
        />
    );
}