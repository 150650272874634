import React, { Component } from "react";
import { Auth } from "aws-amplify";
//import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./ChangePassword.css";
import Form from 'react-bootstrap/Form';
import Link from 'react-router-dom/Link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getUsers, {getData} from "./SharedFunctions";
import LeftMenu,{PageHeader} from '../components/leftmenu';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
//import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Config from '../config';
import Info from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
//import MouseOverPopover from "../containers/test";
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import {Checkbox, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'left',
    justifyContent: 'center',
    alignItems: 'center',  
    
  },
  // root: {
  //   width: '50%',
  //   '& > * + *': {
  //     marginTop: theme.spacing(2),
  //   },
  // },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:"blue"
  },
  button: {
    fontSize:"16px",
    marginTop: theme.spacing(2),
    // fontFamily: "Ebrima",
    color: Config.SDWK_Color, // Config.SRCL_Color,
    marginLeft:"37%"
  },    
  link: {
    fontSize:"14px",
    marginTop: theme.spacing(1),
    // fontFamily: "Ebrima",
    //color: Config.SRCL_Color,
    marginLeft:"42%"
  },    
  title: {
    color: Config.SDWK_Color, // Config.SRCL_Color,
    marginTop: theme.spacing(2),
    fontFamily: "Ebrima"
  },
  title_grey: {
    //color:grey[400],
    fontWeight:"fontWeightMedium",
    //marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginRight: "40px",
    fontFamily: "Ebrima",
    fontSize: "16px",
    //fontStyle:"oblique",
    },
  popover: {
    pointerEvents: 'none',
  },
  paper_popover: {
    padding: theme.spacing.unit,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: "red",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  form: {
    marginLeft: "36px",
    maxWidth: "306px"
  }
});



class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSRCLUser: props.isSRCLUser,
      clientid: props.clientid,
      clientColor: this.props.siteConfig.srclColor,      
      password: "",
      oldPassword: "",
      isChanging: false,
      confirmPassword: "",
      email: "",
      user: null,
      isSuccess: false,
      name: "",
      anchorEl: null,
      termsFlag:true,
      termsText:'',
      hasAgreed:false,
      emailRegex : new RegExp(/\S+@\S+\.\S+/),
      passwordRules: [<div>Your password must contain:
        <br/><span style={{marginLeft:"15px"}}>•	A minimum of 8 characters</span>
        <br/><span style={{marginLeft:"15px"}}>•	At least 1 number</span>
        <br/><span style={{marginLeft:"15px"}}>•	At least 1 special character <br/><span style={{marginLeft:"22px"}}>^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , ' : ; | _ ~ ` </span></span>
        <br/><span style={{marginLeft:"15px"}}>•	At least 1 upper case character</span></div>]      
    };
    
  }
  async componentDidMount() {

    //debugger;
    let flag = false;
    // console.log("changepassword props", this.props);
    if(this.props.location.state !== undefined && this.props.location.state.username) {
      this.setState({email: this.props.location.state.username, isPwdChallenged: true});
      flag=true;
    } else {
      if (this.props.username.length && this.props.username.length > 0)
      {
        this.setState({email: this.props.username});
        flag=true;
      }
      else if (this.props.isAuthenticated)
      {
        const currentUser =  await Auth.currentAuthenticatedUser()
        // console.log("change password currentUser", currentUser);
        flag=true;
      // .then (us => this.setState({user : us, email : us.attributes.email}));
     // this.setState({email : this.props.username});        
        this.setState({email: currentUser.attributes.email});
      } 
      let termsFlag = false
      if(this.props.location.state!==undefined && this.props.location.state.passwordStatus!=undefined && this.props.location.state.passwordStatus === 'IN')
      {
        termsFlag = true;
        let body= {
          "procname" : "procConfigSelectTerms",
          "paramcount": "0"
          }
  
          await getData(body)
          .then(json => this.setState({ termsText: json[0][0].ConfigValue}) )
      }

      this.setState({termsFlag: termsFlag});
    }

    if (!flag)
      this.props.history.push({pathname: './login',state: {clientid: null, email: null}});

      window.scrollTo(0, 0);
  }
  
  validateForm() {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword &&
      (this.state.termsFlag ? this.state.hasAgreed : true)
    );
  }

  handleAgreedToTerms = (flag) =>{
    this.setState({hasAgreed:flag})
  }

  regExp = str => {
    return (
     this.state.emailRegex.test(str)
    )
  }

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChangeClick = async event => {
    event.preventDefault();

    this.setState({ isChanging: true });

    try {

      if(this.state.isPwdChallenged) {
        var user = await Auth.signIn(this.state.email, this.state.oldPassword);

        await Auth.completeNewPassword(user, this.state.password, { name: this.state.name });

      } else {
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(
          currentUser,
          this.state.oldPassword,
          this.state.password
        );
      }
      

      let body= {
        "procname" : "procUserUpdateStatus",
        "paramcount": "3", 
        "param1": this.state.email, //username
        "param2" : "1", //IsActive
        "param3" : "CN" //PwdStatus = CN 
      }

      await getData(body);

      await getUsers(0,this.state.email)
      .then(json => {
        this.setState({ usersDataSource: json[0], isChanging: false, isSuccess:true})
        toast.info('Your password has been successfully changed', {position: toast.POSITION.TOP_RIGHT})
        this.props.history.push({pathname: 'login', state: {clientid: null, campaignid:null}})
      });

      //this.props.history.push("/settings");
    } catch (e) {
      //alert(e.message);
      toast.error(e.message, {position: toast.POSITION.TOP_RIGHT})
      this.setState({ isChanging: false });
    }
  };

  render() {
      const { classes } = this.props;
      const {anchorEl} = this.state

      const open = Boolean(anchorEl);
      return (
        <div style={{width:"100%", borderStyle:"solid", borderColor:this.props.siteConfig.srclColor, borderWidth:"0px"}}>
  
          <LeftMenu selected="settings" confirmationUnsaved_LM={(tmp) => event => {return true}}  clientid={this.state.clientid} props={this.props}/>
  
          <div className="MainInnerWrapperDiv">
          <PageHeader title="Change Password" username={this.props.username}/>
  
               
        <div className={classes.paper} className="ChangePassword"> 
          <Form onSubmit={this.handleChangeClick} className={classes.form}>
          {/* <Typography component="h1" variant="h6">
            Reset Password
          </Typography> */}
          <Typography className={classes.title_grey}>
              {this.state.email}
          </Typography>
          {this.state.isPwdChallenged && 
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  error={this.state.name.length > 8 && !this.regExp(this.state.name)}
                  helperText={this.state.name.length > 8 && !this.regExp(this.state.name) ?'Invalid format':''}
                  value={this.state.name} 
                  onChange={this.handleChange}
                  id="name"
                  label="Email"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  InputLabelProps={{shrink: true}}
                />
          }
          <TextField
          variant="outlined"
          margin="normal"
          required
          //style={{width:"40%"}}
          fullWidth
          onChange={this.handleChange}
          name="oldPassword"
          label="Old Password"
          type="password"
          id="oldPassword"
          //autoComplete="current-password"
          InputLabelProps={{shrink: true}}
        />        
          <TextField
          variant="outlined"
          margin="normal"
          required
          className={classes.textField}
          fullWidth
          //style={{width:"90%"}}
          value={this.state.password} 
          onChange={this.handleChange}
          name="password"
          label="New Password"
          type="password"
          id="password"
          //autoComplete="current-password"
          InputLabelProps={{shrink: true}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Info className={classes.icon} onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose}/>
              </InputAdornment>
            )
          }}
        />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper_popover,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={this.handlePopoverClose}
              disableRestoreFocus
            >
              <Typography variant="caption" display="block" >{this.state.passwordRules}</Typography>
            </Popover>
            <TextField
              variant="outlined"
              margin="normal"
              error={this.state.password.length > 0 && this.state.confirmPassword.length > 0 && this.state.password!==this.state.confirmPassword}
              helperText={this.state.password.length > 0 && this.state.confirmPassword.length > 0 && this.state.password!==this.state.confirmPassword ? 'Passwords don`t match. Try again?' : ' '}
              required
              className={classes.textField}
              fullWidth
              value={this.state.confirmPassword} 
              onChange={this.handleChange}
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              //autoComplete="current-password"
              InputLabelProps={{shrink: true}}
            /> 
            <div style={{float:"left",fontSize:"12px"}}>
              {this.state.termsFlag && <ScrollDialog termsText={this.state.termsText} hasAgreed={this.state.hasAgreed} handleAgreedToTerms={this.handleAgreedToTerms}/>}
            </div>   
            <LoaderButton
              block
              type="submit"
              style={{height:"45px", marginTop:"15px"}}
              bsSize="small"
              text="Submit"
              loadingText="Submitting…"
              disabled={!this.validateForm()}
              isLoading={this.state.isChanging}
            /> 
           <br/>
          {/* <Link onClick={()=>alert('where to?')} className={classes.link} to="#">Cancel</Link>           */}
          </Form>
        </div>
         </div>      
      </div>
      );
    
}
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChangePassword);

function ScrollDialog(props) {
 // debugger;
  const [open, setOpen] = React.useState(false);
  const scroll='paper'

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (flag) => () => {
    props.handleAgreedToTerms(flag)
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleCheck=(e)=>{
    props.handleAgreedToTerms(e.target.checked)
  }

  return (
    <div>
      <div>
        <Checkbox color="black" style={{margin:"0,0,0,0"}} checked={props.hasAgreed} onClick={handleCheck}/>  
        I accept <Link to = {{pathname: "ChangePassword",  state: { passwordStatus: "IN" }}} onClick={handleClickOpen}>the terms of the agreement</Link>
      </div>         
      <Dialog
        open={open}
        onClose={handleClose(false)}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms of the agreement</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {props.termsText.split('\n').map(function(item) {
              return (
                <span>
                  {item}
                  <br/>
                </span>
              )
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose(true)} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}