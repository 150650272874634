import { Auth } from "aws-amplify";
import getUsers from "../containers/SharedFunctions";

async function getDbUserAttributes(username) {
    let userData = null;
    let userAttributes = {};

    const responseUsers = await getUsers(0,username)
        .then(json => { userData = json[0];})
        .then(()=> {
            // console.log(userData);
            if(userData.length === 1){
                userAttributes.clientId = userData[0].ClientID;
                userAttributes.passwordStatus = userData[0].PwdStatus;

                if(!userData[0].IsActive.data[0]) {
                    throw new Error("This User account is not active.  Please contact your account manager for details");
                }
                else if(userData[0].IsClientActive !== '1') {
                    throw new Error("This client has been deactivated.  Please contact your Client Service Rep for details");
                }
            } else {
                throw new Error("The system is not able to locate your credentials!");
            }
    });

    return userAttributes;
}

export default async function AuthenticateUser(username, password) {
    //debugger;
    let user = {
        username: username
    };

    var signInResponse = await Auth.signIn(username, password);

    if(signInResponse.challengeName) {
        throw new Error(signInResponse.challengeName);
    }

    const dbAttributes = await getDbUserAttributes(username);

    user.isAuthenticated = true;
    user.clientId = dbAttributes.clientId;//await GetClientId(username);
    user.passwordStatus = dbAttributes.passwordStatus;

    return user;
}

export async function RefreshCurrentAuthUser() {
    //calling Auth.currentSession() refreshes the cognito Access Token automatically
    let user = {};
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

    if(currentAuthenticatedUser) {
        const username = currentAuthenticatedUser.attributes.email;
        const dbAttributes = await getDbUserAttributes(username);

        user.username = username;
        user.isAuthenticated = true;
        user.clientId = dbAttributes.clientId;//await GetClientId(username)
        user.passwordStatus = dbAttributes.passwordStatus;
    }

    return user;
}