import React from "react";
import "../css/rrr.css";
import Filter1 from '@material-ui/icons/Filter1';
import Fab from '@material-ui/core/Fab';
import config from "../config";
// import Text  from 'react-native';
import Chip from '@material-ui/core/Chip';
import LooksOneOutlined from '@material-ui/icons/LooksOneOutlined';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Link } from "react-router-dom";

export default ({
    stepid,
    clientid,
    campaignid,
    isDone1,
    isDone2,
    isDone3,
    isDone4,
    isDone5,  
    isDone6,
    isDone7,              
    ...props
  }) =>  {
return (
<div style={{width:"100%", marginBottom:"50px"}}>
{/* <span>{props.allowedmessagetype}</span> */}
<div style={{float:"left", backgroundColor: "#f9f9f9", width:"100%", height: "65px",paddingTop:"15px"}}>
  <div style={{float:"left", width:"23%"}}>
  
    {stepid==='1'?
      <div class="wiz-circle-selected">
        <label class="wiz-text-selected" >
            1&nbsp;&nbsp;&nbsp;Name & Contacts
        </label>
      </div>
     :
    <div style={{float:"left"}}>
      {!isDone1?
      // <Link to = {{pathname: "Campaigns",  state: {clientid: clientid}}}></Link>
        <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid, stepid:stepid, campaignid:campaignid}}} onClick={props.goto1} style={{display: 'flex',alignItems: 'center'}}  >
          <div class="wiz-circle" >
              <label class="wiz-text">
                    1
              </label>
          </div>Name & Contacts
        </Link>
      : 
      <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto1} style={{display: 'flex',alignItems: 'center'}} >
            <CheckCircle style={{height:"36px",width:"36px",paddingRight:"3px"}}/>Name & Contacts
        </Link>
      }
    </div>    
    }
  </div>

  <div style={{float:"left", width:"17%"}}>
  {stepid==='2' || stepid ==='2.5'?
  // <a href='#' onClick={props.goto2}>
    <div class="wiz-circle-selected">
      <label class="wiz-text-selected" >
          2&nbsp;&nbsp;&nbsp;Delivery Method
      </label>
    </div>
  // </a>
  :
  <div style={{float:"left"}}>
    {!isDone2?
      <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid, campaignid:campaignid}}} onClick={props.goto2} style={{display: 'flex',alignItems: 'center'}}  >
        <div class="wiz-circle">
            <label class="wiz-text">
                  2
            </label>
        </div>Delivery Method
      </Link>
    : 
    <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid, campaignid:campaignid}}} onClick={props.goto2} style={{display: 'flex',alignItems: 'center'}}  >
          <CheckCircle style={{height:"36px",width:"36px",paddingRight:"3px" }}/>Delivery Method
      </Link>
    }
  </div>    
  }
</div>

<div style={{float:"left", width:"20%"}}>
  {stepid ==='3' || stepid ==='4' || stepid ==='5'?
    <div class="wiz-circle-selected" style={{width:"85%"}}>
      <label class="wiz-text-selected" >
          3&nbsp;&nbsp;&nbsp;Response
      </label>
    </div>
  :
  <div style={{float:"left"}}>
    {!isDone3 ?
      <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto3} style={{display: 'flex',alignItems: 'center'}}  >
        <div class="wiz-circle">
            <label class="wiz-text">
                  3
            </label>
        </div>Response
      </Link>
    : 
    <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto3} style={{display: 'flex',alignItems: 'center'}}  >
          <CheckCircle style={{height:"36px",width:"36px",paddingRight:"3px" }}/>Response
      </Link>
    }
  </div>    
  }
</div>
  
<div style={{float:"left", width:"20%"}}>
  {stepid==='6'?
    <div class="wiz-circle-selected">
      <label class="wiz-text-selected" >
          4&nbsp;&nbsp;&nbsp;Schedule
      </label>
    </div>
  :
  <div style={{float:"left"}}>
    {!isDone6?
      <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}}  onClick={props.goto6} style={{display: 'flex',alignItems: 'center'}}  >
        <div class="wiz-circle">
            <label class="wiz-text">
                  4
            </label>
        </div>Schedule
      </Link>
    : 
      <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto6} style={{display: 'flex',alignItems: 'center'}}  >
          <CheckCircle style={{height:"36px",width:"36px",paddingRight:"3px" }}/>Schedule
      </Link>
    }
  </div>    
  }
</div>

<div style={{float:"left", width:"20%"}}>
  {stepid==='7'?
    <div class="wiz-circle-selected">
      <label class="wiz-text-selected" >
          5&nbsp;&nbsp;&nbsp;Review
      </label>
    </div>
  :
  <div style={{float:"left"}}>
    {!isDone7?
      <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto7} style={{display: 'flex',alignItems: 'center'}}  >
        <div class="wiz-circle">
            <label class="wiz-text">
                  5
            </label>
        </div>Review
      </Link>
    : 
      <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto7} style={{display: 'flex',alignItems: 'center'}}  >
          <CheckCircle style={{height:"36px",width:"36px",paddingRight:"3px" }}/>Review
      </Link>
    }
    
  </div>    
  }
</div>

</div>
{(stepid==='3' || stepid==='4' || stepid==='5') &&
<div style={{align:'center'}}>
    <div style={{float:"left", width:"41%",}}>&nbsp;</div>
    <div style={{float:"left", width:"4%"}}>
      {stepid==='3'? <Chip style={{backgroundColor:"#005745",color:"white"}} size="small" onClick={props.goto3} label="Site" />
                :  <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto3} class="submenu_item">Site</Link>}
   </div>
   <div style={{float:"left", width:"7%"}}>
      {stepid==='4'? <Chip style={{backgroundColor:"#005745",color:"white"}} size="small" onClick={props.goto4} label="Products" />
                   : <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto4} class="submenu_item">Products</Link>}
   </div>
   <div style={{float:"left", width:"7%"}}>
     {stepid==='5'? <Chip style={{backgroundColor:"#005745",color:"white"}} size="small" onClick={props.goto5} label="Questions" />
                  : <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto5} class="submenu_item">Questions</Link>}
   </div>
    <div style={{float:"left", width:"41%"}}>&nbsp;</div>
</div>
}
{(stepid==='2' || stepid==='2.5') &&
<div style={{align:'center'}}>
  {props.allowedmessagetype==='Both' || !campaignid?
    <div style={{float:"left", width:"24%",}}>&nbsp;</div>
    : <div style={{float:"left", width:"28%",}}>&nbsp;</div>
  }
  {props.allowedmessagetype!=='Sms' &&
    <div style={{float:"left", width:"7%"}}>
      {stepid==='2'? <Chip style={{backgroundColor:"#005745",color:"white"}} size="small" onClick={props.goto2} label="Email" />
                :  <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto2} class="submenu_item">Email</Link>}
   </div>
  }
  {props.allowedmessagetype!=='Email' &&
   <div style={{float:"left", width:"7%"}}>
     {stepid==='2.5'? <Chip style={{backgroundColor:"#005745",color:"white"}} size="small" onClick={props.goto2_5} label="Text" />
                  : <Link to = {{pathname: "CampaignNew",  state: {clientid: clientid,stepid:stepid,  campaignid:campaignid}}} onClick={props.goto2_5} class="submenu_item">Text</Link>}
   </div>
  }
    <div style={{float:"left", width:"24%"}}>&nbsp;</div>
</div>
}

</div>
)
}