import React from "react";
import { Link } from "react-router-dom";
import "react-table/react-table.css";
import "../css/rrr.css";
import { API } from "aws-amplify";
//import {ManageClient} from "./ManageClient";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Switch from "react-switch";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from "aws-amplify";
import {getData} from "./SharedFunctions";
import LeftMenu, {PageHeader} from '../components/leftmenu';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
// import MouseOverPopover,{myTextBox} from "../containers/test";
import AddCircle from '@material-ui/icons/AddCircle';
import MUIDataTable from "mui-datatables";
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {  FormGroup, FormLabel, Button, MenuItem, TextField} from '@material-ui/core';

let pkgDataSource = [];

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: red[800],
    },
  },
  menu: {
    width: 300,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    backgroundColor: "white"
  },
  popupCaption: {
    marginLeft: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: "#f7f7f7",
    marginTop:theme.spacing(3),
  },
  table: {
    marginTop: theme.spacing.unit * 2,
    width: "90%"
  },  
  title: {
    color: "#00956e",
    marginTop: theme.spacing(2),
    fontWeight:"fontWeightBold",
    fontFamily: "AvenirNextLTPro",
    height: "24px",
    fontSize: "14px",
  }, 
  title_grey: {
    color:grey[400],
    fontWeight:"fontWeightLight",
    marginTop: theme.spacing(2),
    marginRight: "40px",
    fontFamily: "Ebrima",
    fontSize: "14px",
    fontStyle:"italic",
  }, 
  submit: {
    //margin: theme.spacing(3, 0, 2),
    backgroundColor:"#1782c5",
    marginTop: "18px", 
    height:"53px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
});



class Clients extends React.Component {
	constructor(props) {
    super(props); 

		this.state = {
      isSRCLUser: props.isSRCLUser,
      clientid: props.clientid,
      clientColor: this.props.siteConfig.srclColor,
      isLoading: true,
      user: props.username,
      selected : -1,
			dataSource: [{clientid:0, clientname:"loading"}],
			search: '',
			direction : {
				clientname: 'asc'
      },
      clientname: "",
      contactname: "",
      contactemail: "",
      contactphone: "",
      result: "",
      newUser: null,
      plan: "",
      editclientid: null,
      hideForm: true,
      contactemailInvalid:false,   
      clientnameInvalid:false
		}
  



	}

	async componentDidMount() {

    // console.log('clients props');
    // console.log(this.props);


    await this.getClients()
    // console.log('after load')
    // console.log(this.state.isSRCLUser + ' ' +  this.state.user + ' ' + this.state.clientid)

    if(pkgDataSource.length == 0)
      await this.getSubscriptionPlans()
  }

  async getSubscriptionPlans(){
    
    let body= {
      "procname" : "procSubscriptionPlanSelect",
      "paramcount": "1", 
      "param1": "1"
    }
    //get packages
    await getData(body)
    .then(json => {
      pkgDataSource.length = 0;
      json[0].map(el => {
        pkgDataSource.push({ label: el.PlanName + ' - Msg :' +  el.MaxMessages + ' - Price: $' +  el.Price, value: el.PlanCode }) 
      });
      this.setState({isLoading: false});
    })
  }

  validateForm() {
    //debugger;
    return (
      this.state.clientname.length > 0 &&
      this.state.contactname.length > 0 &&
      this.state.contactemail.length > 0 &&
      (this.state.editclientid === null ? this.state.plan.length > 0 : true)
    );
  }

  handleChange = (name='') => e => {
		this.setState({
      [name.length>0 ? name : e.target.id]: e.target.value, 
      contactemailInvalid:e.target.id==='contactemail'?false:this.state.contactemailInvalid,
      clientnameInvalid:e.target.id==='clientname'?false:this.state.clientnameInvalid,
		});
	}

//   handleChange_myTextBox = name => event => {
//     this.setState({ values: { ...this.state.values, [name]:  event.target.value} });
// };   


  async getClients(){
    let body= {
      "procname" : "procClientSelect",
      "paramcount": "2", 
      "param1": 0,
      "param2" : ""
    }
   
     await getData(body)
    .then(json => {this.setState({ dataSource: json[0], isLoading: false, direction: {ClientName: 'asc'} })
    });      
  }//global.defaultStatus = json[0][0].Active; 
  
  handleSwitchChange = (checked,event,id) => {

    confirmAlert({
      message : `Are you sure you want to ${!checked ? 'de' : ''}activate client ${this.state.dataSource.find( u=> {return u.ClientID === id;}).ClientName} ?`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Yes',
          onClick: () => this.updateClient(id, checked)
        }
      ]
    });  
  }

	//method to handle signUp submit button
	handleSubmit = async event => {
//debugger;
    event.preventDefault();
    
    let errorFlag = false

    if(!new RegExp(/\S+@\S+\.\S+/).test(this.state.contactemail))
    {
      this.setState({contactemailInvalid: true})
      errorFlag=true
    }

    if(this.state.dataSource.filter(e =>e.ClientName.toLowerCase() === this.state.clientname.toLowerCase()
        && e.ClientID !== (this.state.editclientid === null ? 0 : this.state.editclientid)).length  > 0)      
    {
      this.setState({clientnameInvalid: true})  
      errorFlag=true
    }

    if(errorFlag) return

    let body= {
      "procname" : "procClientInsert",
      "paramcount": "6", 
      "param1": this.state.clientname,
      "param2": this.state.contactname,          
      "param3": this.state.contactemail,   
      "param4": this.state.contactphone,
      "param5" : this.state.plan,
      "param6": this.props.username                 
    }

    let bodyUpdate= {
      "procname" : "procClientUpdate_v1_2",
      "paramcount": "9", 
      "param1": this.state.editclientid,
      "param2": -1, //do not update active
      "param3": '0', //do not update customer master id        
      "param4": this.state.clientname, 
      "param5": this.state.contactname, 
      "param6": this.state.contactemail,  
      "param7": this.state.contactphone,
      "param8": '0', //do not update billing division
      "param9": this.props.username                 
    }    
    await getData(this.state.editclientid === null ? body : bodyUpdate)
    .then(async json => {
      if(this.state.editclientid === null)
      {
        this.props.handleStateChange(json[0][0].ClientID);
        this.props.history.push({pathname: 'ManageClient', state: {clientid: json[0][0].ClientID, campaignid:null}})
      }
      else
        await this.getClients()
      
      this.setState({hideForm: true});
      toast.success(`Changes for Client #${this.state.editclientid} have been saved `, {position: toast.POSITION.TOP_RIGHT})             
    }); 
    
  }

  updateClient = async (id, checked) =>{
    // console.log ( id,checked);

    if(!checked && this.state.dataSource.find( u=> {return u.ClientID === id;}).HasActiveEvents === 1)
    {
      toast.error( `Client ${this.state.dataSource.find( u=> {return u.ClientID === id;}).ClientName} has ACTIVE campaigns. No deactivation is permitted.` , {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }    

    let body= {
      "procname" : "procClientUpdate_v1_2",
      "paramcount": "9", 
      "param1":  id, //clientid
      "param2" : !checked ? '0' : '1', //active flag
      "param3": '0',       
      "param4": '0',
      "param5": '0',
      "param6": '0', 
      "param7": '0',
      "param8": '0',
      "param9": this.props.username                 
    }    
    
   await getData(body)
  .then(async() => {
    await this.getClients()
    toast.success( `Client ${this.state.dataSource.find( u=> {return u.ClientID === id;}).ClientName} is ${!checked ? 'de' : ''}activated` , {
      position: toast.POSITION.TOP_RIGHT
    })   
  });    
  }

  editClient = (clientid) => {
    //debugger;
    var cl = this.state.dataSource.find(e => e.ClientID == clientid);
    
    this.setState({
      clientname: cl.ClientName,
      contactname: cl.ContactName,
      contactemail: cl.ContactEmail,
      contactphone: cl.ContactPhone,
      editclientid: clientid,
      hideForm : false,
      clientnameInvalid:false,
      contactemailInvalid:false
    });
   }

  handleCancel = event => {
    this.setState({
      hideForm : true
    });
  }


  addClient = event => {
    event.preventDefault();
      this.setState({
        hideForm : false,
        clientname: '',
        contactname: '',
        contactemail: '',
        contactphone: '',
        editclientid: null,
        plan:'',
        clientnameInvalid:false,
        contactemailInvalid:false
      });
      this.validateForm();
    
  }


  	render() {

        const {isLoading} = this.state;
        let {dataSource} = this.state;

        const { classes } = this.props;

        const columns = [
          {
            label: 'Client ID',
            name: 'ClientID',
            options: {
              filter: false,
              setCellHeaderProps: (value) => {
                return {
                    style: {fontWeight: "bold"}
                };
              },              
              customBodyRender: (value, tableMeta, updateValue) => {
                // console.log(value)
                return (
                      // value 
                      // <span><Edit height={10} width={15} onClick={() => this.editClient(value)}/>{'' + value}</span>
                      <OverlayTrigger placement="right" delay={{ show: 150, hide: 300 }} overlay={<Tooltip> Edit </Tooltip>}>
                      <span><img alt="Edit" width="40" 
                        id={value} height={15} width={15} 
                        src={require('../images/edit.png')} 
                        onClick={() => this.editClient(value)}/>{' ' + value}</span>
                      </OverlayTrigger>           
                );              
            },                        
            },
          },
          {
            label: 'Client Name',
            name: 'ClientName',
            options: {
              sortDirection: "asc",
              setCellHeaderProps: (value) => {
                return {
                    style: {fontWeight: "bold"}
                };
              },                
              customFilterListOptions: { render: v => `Client: ${v}` },
             // filterType: 'textField', // set filterType's at the column level     !!!!         
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                      <Link onClick={()=>this.props.handleStateChange(tableMeta.rowData[0])} 
                      to = {{pathname: "ManageClient",  state: {clientid: tableMeta.rowData[0]}}}  >{value}</Link>                
                );              
            },
          },
        },
          {
            label: 'Contact Name',
            name: 'ContactName',
            options: {
              setCellHeaderProps: (value) => {
                return {
                    style: {fontWeight: "bold"}
                };
              },                
              //filterType:"select",
              customFilterListOptions: { render: v => `Name: ${v}` },             
            },
          },
          {
            label: 'Contact Email',
            name: 'ContactEmail',
            options: {
              setCellHeaderProps: (value) => {
                return {
                    style: {fontWeight: "bold"}
                };
              },                
              customFilterListOptions: { render: v => `Email: ${v}` },
            },
          },          
          {
            label: 'Contact Phone',
            name: 'ContactPhone',
            options: {
              setCellHeaderProps: (value) => {
                return {
                    style: {fontWeight: "bold"}
                };
              },  
              filterType:"custom",
              filterOptions: {
                logic: (listValue, filters) => {
                  //debugger;
                  if (filters.length) return !listValue.includes(filters);
                  return false;
                },                             
                display: (filterList, onChange, index, column) => (
                <div>
                    <TextField
                      label='Contact Phone'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '200px', marginRight: '5%' }}
                    />
                 </div>
              )},            
              customFilterListOptions: { render: v => `Phone: ${v}` },//chip display
            },
          },          
          {
            name: 'Active',
            options: {
              setCellHeaderProps: (value) => {
                return {
                    style: {fontWeight: "bold"}
                };
              },  
              filterOptions: {
                names: ['Active','Inactive'],
                logic(active, filterVal) {
                  const show =
                    (filterVal[0]==='Inactive' && active ===0 ) ||
                    (filterVal[0]==='Active' && active === 1 );
                  return !show;                
                },                
              },              
             // sort: false,
             //customFilterListOptions: { render: v => `Active: ${v===0?'Inactive':'Active'}` },
             customBodyRender: (value, tableMeta, updateValue) => {
                //console.log(tableMeta)
                return (
                  <Switch height={16} width={35} id={tableMeta.rowData[0]} onChange={this.handleSwitchChange} checked={value === 1  ? true : false} />        
                );              
            },              
            },
          },
        ];        

        const options = {
          filter: true,
          filterType: 'multiselect',
          responsive: 'stacked', //scroll, 'scrollMaxHeight'
          page: 0,
          rowsPerPage: 10,
          rowsPerPageOptions:  [5, 10, 20, 25],
          searchText: this.state.searchText,
          searchPlaceholder: 'Search...',
          selectableRows: false,    
          disableToolbarSelect: true,
          print: false,
          download: false,
          viewColumns: false,
          customToolbar: null,
        }


		return (
      <div>
        <LeftMenu selected="clients" confirmationUnsaved_LM={(tmp) => event => {return true}} clientid={this.state.clientid} props={this.props}/>

        <div className="MainInnerWrapperDiv">
        <PageHeader title="Clients" username={this.props.username}/>

            <div style={{float:"left",  paddingLeft:"30px", width : "95%"}}>
              <Link to = {{pathname: "clients",  state: {clientid: -1}}} onClick={this.addClient} style={{width:"200px", display: 'flex',fontSize:"15px",alignItems: 'center'}} >
                  <AddCircle style={{color: "#1782c5",paddingRight:"3px" }}/>Add New Client</Link>           
              {(!this.state.hideForm) && <div>{clientForm(this,classes)} </div>}
              <MUIDataTable className={classes.table} data={dataSource} columns={columns} options={options} />
            </div>      
        </div>  
      </div>

				 
		);
	  }
}

Clients.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Clients);

 const  clientForm = (caller,classes) => 
 <>
  <form className={classes.container} onSubmit={caller.handleSubmit} noValidate autoComplete="off">
  <div style={{width:"90%", backgroundColor: "#f7f7f7;"}}>
    <label class="title_add_section">New Client</label>
    </div> 
    <div>
        <FormLabel  class="add_section_title" />
        <FormGroup row>
        <TextField
          id="clientname"
          label="Company Name"
          className={classes.textField}
          style={{marginTop:"12px",width:"250px"}}
          error={caller.state.clientnameInvalid}
          helperText={caller.state.clientnameInvalid ?'Client Name exists. Please enter a unique name':''}  
          value={caller.state.clientname}
          onChange={caller.handleChange()}
          margin="normal"
          required
          variant="outlined"
          placeholder="[company name]"
          InputLabelProps={{shrink: true}}
        /> 
        </FormGroup>
        </div>
        <div>
        <FormLabel class="add_section_title" style={{paddingLeft:"8px"}}>Primary Contact Info</FormLabel>
        <FormGroup row>
        <TextField
        id="contactname"
        label="Name"
        required
        value={caller.state.contactname}
        onChange={caller.handleChange()}
        className={classes.textField}
        margin="normal"
        //helperText="hello"
        variant="outlined"
        placeholder="[first name last name]"
        InputLabelProps={{shrink: true}}
        />
        <TextField
          id="contactemail"
          label="Email"
          required
          error={caller.state.contactemailInvalid}
          helperText={caller.state.contactemailInvalid ?'Invalid email format':''}  
          className={classes.textField}
          value={caller.state.contactemail}
          onChange={caller.handleChange()}
          style={{width:"220px"}}
          margin="normal"
          placeholder="[email]"
          variant="outlined"
          InputLabelProps={{shrink: true}}
        />
        <TextField
          id="contactphone"
          label="Phone"
          value={caller.state.contactphone}
          onChange={caller.handleChange()}
          className={classes.textField}
          style={{width:"150px"}}
          margin="normal"
          type="phone"
          placeholder="[phone]"
          variant="outlined"
          InputLabelProps={{shrink: true}}
        />
        </FormGroup>
        </div>   
        <div>
        <FormLabel class="add_section_title" style={{marginLeft:"10px"}}>{caller.state.editclientid=== null ?'Packages':''}</FormLabel>
        <FormGroup row>
        {caller.state.editclientid=== null ?
        <TextField
          id="plan"
          select
          label="Package"
          className={classes.textField}
          required
          value={caller.state.plan}
          style={{width:"310px"}}
          onChange={caller.handleChange('plan')}
          margin="normal"
          variant="outlined"
         // InputLabelProps={{shrink: true}}
        >
          {pkgDataSource.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        :''}

        <Button
            //type="submit"
            style={{backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"}}
            variant="outlined" //color="secondary"     
            className={classes.submit}
            alignItems="flex-end"
            onClick = {caller.handleCancel}      
        >
          Cancel
        </Button>
        <Button
            type="submit"
            alignItems="flex-end"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!caller.validateForm()}
          >
          {(caller.state.editclientid === null ? "Add" : `Update Client #${caller.state.editclientid}`)}
        </Button>     
        </FormGroup>
    </div>    
</form>
</>