'use strict'
import React from 'react';
import "../css/rrr.css";
import LoaderButton from "../components/LoaderButton";
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Collapse from 'react-bootstrap/Collapse'
import getUsers, {getData} from "./SharedFunctions";
import * as moment from 'moment';
import config from '../config'
import AddCircle from '@material-ui/icons/AddCircle';
import { withStyles } from '@material-ui/core/styles';
import {TextField, MenuItem} from '@material-ui/core';
import {Tooltip as MUIToolTip, Fab, Grid, IconButton, InputAdornment } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import CalendarToday from "@material-ui/icons/CalendarToday";
import {FormGroup} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import {toast } from 'react-toastify';
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";

const styles = theme => ({
  formControl: {
    //margin: theme.spacing(1),
    color:"black",
    fontWeight: 500
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fab: {
    width: 35,
    height: 30 ,
    marginRight:5
  }
});

class CampaignSchedule extends React.Component {
  constructor () {
    super()
    this.state = { 
      isLoading: false,
      isFormLoading:true,
      isMessageTypesLoading:true,
      isSmsSendRangeLoading:true,
      hideAdd : 'block',
      clientname: "",
      clientid: 0,
      user:'admin@stericycle.com',
      campaignname:"",
      campaignid:'',
      clientid:'',       
      schedules: [{order: 0, scheduleDT: new Date('2000-01-01T00:00:00Z'), MessageType:null, nonRespondersOnly: false, isEmptyError:false, isInvalidDate:false, isInvalidTime:false, isHidden:false, isCheckBoxError:false}],
      messagetypes:[],
      DT:"",
      listID: 0,
      listName: "",
      countries: "",
      listmessagetype: "",
      smsSendStartTimeUtcMax: new Date().getTime(),
      smsSendEndTimeUtcMin: new Date().getTime(),
      isdirty:false,
      availableSends:[],
      maxLimits:[]
    }

  }

  addschedule = (e) => {
    //debugger;
    let {schedules} = this.state
    if(this.props.parentCampaign.launchstatus !== 'PE') return
    if(schedules.length===11)
    {
      toast.info('Max of 10 followup emails is allowed per campaign', {
        position: toast.POSITION.TOP_RIGHT}) 
        return;
    }

    let flag = false
    schedules.filter((e)=>{return ((e.scheduleDT===null || e.scheduleDT.length===0) && ((e.order <=1 && !e.nonRespondersOnly) || (e.order > 1))) }).forEach(x=>{
         flag = true; 
      return x.isEmptyError = true;
    }
    )

    schedules.filter((e)=>{return (e.order > 1 && e.nonRespondersOnly === null)}).forEach(x=>{
      flag = true;
      return x.isCheckBoxError = true;
    }
    )

    if(schedules.filter((e)=>{return (e.MessageType !== "Email" && e.isInvalidTime)}).length>0)
      flag = true;


    if(flag)
    {
      this.setState({schedules})
      return;
    }

    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
    this.setState((existingState) => ({
        schedules: [...existingState.schedules, {order: 0, scheduleDT:null, MessageType:null, nonRespondersOnly: null, isEmptyError:false, isInvalidDate:false, isInvalidTime:false, isCheckBoxError:false}]
    }));
  }  


  labelText= (i) =>{
    const ordinal = ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth'];
    return i===0 ? 'Initial Message (EST)' :  `${ordinal[i-1]} Follow Up Message (EST)` ;
  }

  handleDateChange = (ind,date) => {
    //debugger;

    let className = 'scheduleDT'
    //let schedules = [...this.state.schedules]   
    let {schedules,availableSends} = this.state

    //if not NULL, credit back to available counts 

    this.creditBackAvailableCountsForChangedDate(schedules[ind][className],schedules[ind].MessageType);

    schedules[ind][className]=null
    
    schedules[ind].isInvalidDate=false;
    schedules[ind].isInvalidTime=false;
    schedules[ind].isEmptyError=false;
    

    if(date===null) {
      if(ind===0)
      schedules[0].scheduleDT = null;
      schedules[0]["order"]=1
      this.setState({schedules, isdirty:true})
      return
    };

    //compare to the previous date
    if((ind===1 && !schedules[0]["nonRespondersOnly"]) || ind>1)
    {
      if(schedules[ind-1][className] !== null && moment(schedules[ind-1][className]).isSameOrAfter(moment(date)))
      {
        if(date.toDateString()===schedules[ind-1][className].toDateString())
          date = new Date(date.setTime(schedules[ind-1][className].getTime() + (config.scheduleIncrements * 60 * 1000)))
        else
        {
          schedules[ind][className]=null
          schedules[ind].isInvalidDate = true
          this.setState({schedules, isdirty:true})
          return;
        }
      }
    } 

    //compare to the next date
    if(schedules["length"] > ind+1 && (schedules[ind+1][className] !== null && (schedules[ind+1][className] <= date))) //schedules[ind+1][className].toString() <= date.toString() || 
    {
      let flag = false;
      if(date.toDateString()===schedules[ind+1][className].toDateString()) //same date shift 30 back
        {
          date = new Date(date.setTime(schedules[ind+1][className].getTime() - (config.scheduleIncrements * 60 * 1000)))
          flag = true;
        }
      //check if less than to prev date 
      if (ind>0 && (schedules[ind-1][className].toString() >= date.toString()))
          date = new Date(date.setTime(schedules[ind-1][className].getTime() + (config.scheduleIncrements * 60 * 1000))) //same date shift 30 back
      else if(!flag)
      {
        schedules[ind][className]=null
        schedules[ind].isInvalidDate = true
        this.setState({schedules, isdirty:true})
        return;
      }
    }
    const now10 = moment(moment()).add(10, 'm').toDate()
    if(moment(date).isSameOrBefore(now10)) //now
      date = now10;//new Date(now);
    
    let minTime = this.getCurrentDateWithSuppliedTime(this.state.smsSendStartTimeUtcMax);
    let maxTime = this.getCurrentDateWithSuppliedTime(this.state.smsSendEndTimeUtcMin);

    let minDate = new Date(date.toDateString() + " " + minTime);
    minDate.setTime(minDate.getTime() - 60000);
    let maxDate = new Date(date.toDateString() + " " + maxTime);
    maxDate.setTime(maxDate.getTime() + 60000);
        
    if(schedules[ind].MessageType !== "Email" && !moment(date).isBetween(minDate,maxDate))
    { 
      schedules[ind].isInvalidTime = true
      // if (moment(new Date(date.toDateString() + " " + maxTime)).isSameOrBefore(schedules[ind-1][className]))  
      //   date = new Date(moment(date).add(1,"days").format("YYYY-MM-DD") + " " + minTime);
      // else
      //   date = new Date(date.toDateString() + " " + maxTime)
      this.setState({schedules, isdirty:true})
    }
    //this.state.DT = date
    schedules[ind][className] = date
    schedules[ind]["order"]=ind+1
    //same as schedules[0][name] = typed value
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
    this.setState({schedules, isdirty:true})

    // if(schedules[ind].isInvalidTime)
    //   return;

    this.adjustAvailableCountsForDate(date,schedules[ind].MessageType);
  }

  adjustAvailableCountsForDate=(selectedDate,messagetype) =>
  {
    let {availableSends} = this.state;
    let remainingEmails = 0;
    let remainingTexts = 0;
    let {total, outedOut, unsubscribed} = this.props.parentCampaign;

    let totalSubscribied = total - unsubscribed;
    let totalSMSable = total - outedOut;

//deduct from available counts
     let obj=availableSends.find( x=>{ return new Date(x.scheduleDate).toLocaleDateString()===new Date(selectedDate).toLocaleDateString()})
     let availableEmails = !obj || obj===undefined? this.state.maxLimits[0].DailyLimitEmail : obj.AvailableEmails;
     let availableTexts = !obj || obj===undefined? this.state.maxLimits[0].DailyLimitSMS : obj.AvailableTexts; 
       switch(messagetype){
        case "Both": 
          remainingEmails = availableEmails - totalSubscribied;  
          remainingTexts = availableTexts - totalSMSable;  
          break;
        case "Email":
          remainingEmails = availableEmails - totalSubscribied;  
          remainingTexts = availableTexts;
          break;
        case "Sms":
          remainingEmails = availableEmails;  
          remainingTexts = availableTexts - totalSMSable;  
          break;         
      }
        
      if(!obj || obj===undefined){    
        this.setState((existingState) => ({
          availableSends: [...existingState.availableSends, {ClientID: 0, scheduleDate:selectedDate, AvailableTexts:remainingTexts, AvailableEmails: remainingEmails}]
        }));     
        return;
     }
     
    obj.AvailableEmails =  remainingEmails;
    obj.AvailableTexts = remainingTexts;
    this.setState({availableSends})   
    
  }

  creditBackAvailableCountsForChangedDate = (existingDate,messagetype) =>
  {

    //let limit=0;
    let availableEmails = 0;
    let availableTexts = 0;
    let {total, outedOut, unsubscribed} = this.props.parentCampaign;
    let {availableSends} = this.state;

    let totalSubscribied = total - unsubscribed;
    let totalSMSable = total - outedOut;
    

    if(existingDate!==null)
    {
      let obj=availableSends.find( x=>{ return new Date(x.scheduleDate).toLocaleDateString()===existingDate.toLocaleDateString()})
      if(obj!==null && obj!==undefined)
      {
        switch(messagetype){
         case "Both": 
           availableEmails = obj.AvailableEmails + totalSubscribied;  
           availableTexts = obj.AvailableTexts + totalSMSable;  
           break;
         case "Email":
           availableEmails = obj.AvailableEmails + totalSubscribied;  
           availableTexts = obj.AvailableTexts;
           break;
         case "Sms":
           availableEmails = obj.AvailableEmails;  
           availableTexts = obj.AvailableTexts + totalSMSable;
           break;  
       }
    
       obj.AvailableEmails =  availableEmails;
       obj.AvailableTexts = availableTexts;
       this.setState({availableSends})   
      //  console.log(availableSends)  
      }
    }  
  }

  handleChange = (e) => {
    //debugger;

 if (["nonRespondersOnly"].includes(e.target.className) ) {
      let schedules = [...this.state.schedules]   

      schedules[e.target.dataset.id].isInvalidDate=false;
      schedules[e.target.dataset.id].isEmptyError=false;
      schedules[e.target.dataset.id].isCheckBoxError=false;

      if(parseInt(e.target.dataset.id)===0)
      {
        this.creditBackAvailableCountsForChangedDate(schedules[0].scheduleDT,schedules[e.target.dataset.id].MessageType);  

        if(e.target.checked) {
          schedules[0].scheduleDT = null;
          schedules[e.target.dataset.id].isHidden =true
        }
        else  
        {
          schedules[0].scheduleDT = null;
          schedules[e.target.dataset.id].isEmptyError=false
          schedules[e.target.dataset.id].isInvalidDate=false
          schedules[e.target.dataset.id].isInvalidTime=false;
          schedules[e.target.dataset.id].isHidden =false

        }
      }
      
      schedules[e.target.dataset.id][e.target.className] = e.target.value === 'all' ? false : e.target.checked
      schedules[e.target.dataset.id]["order"]=parseInt(e.target.dataset.id)+1
      if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
      this.setState({schedules, isdirty:true})
    } 

    else {
      this.setState({ [e.target.name]: e.target.value, isdirty:true}) 
      if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
    }

  }

  removeSchedule = id => e =>{
    const {schedules} = this.state
    if(this.props.parentCampaign.launchstatus !== 'PE') return
    this.creditBackAvailableCountsForChangedDate(schedules[id].scheduleDT,schedules[id].MessageType);
    schedules.splice(id, 1);
    this.setState({schedules, isdirty:true})
  }

  validateForm()  {
    let {schedules} = this.state
    let me = moment.utc(schedules[0].scheduleDT);

    return (
      schedules.filter((e)=>
      {
        return (
          (
            (
              e.scheduleDT===null || 
              e.scheduleDT.length===0
            ) 
          ) 
          || 
          (
            e.order > 1 && 
            e.nonRespondersOnly === null
          )
        )
        || 
        (
          e.MessageType===null || e.MessageType.length===0
        )
        || 
        (
          // Here is where we check the scheduleDT time to see if Both/Sms is less than the min or greater than the max of allowable send time
          // Note: we are dedicatedly using Eastern Time for now. This will change upon international functionality enhancement.
            e.MessageType !== "Email" && e.isInvalidTime
        )
      }
      ).length===0
    );
 }


 handleSubmit = async e => {
    e.preventDefault() 

    if(!this.state.isdirty)
    {
      this.props.goto7();
      return
    }

    let {schedules} = this.state

    this.setState({isLoading:true})
//debugger;
    let body = {
      "procname" : "procEventMessagingSetupDelete",
      "paramcount": "2", 
      "param1": this.state.campaignid,
      "param2": this.state.user
    }

    await getData(body)
    .then(async ()=>{
      schedules.map(async (el) =>
        {
          let body = {
            "procname" : "procEventMessagingSetupInsert",
            "paramcount": "5", 
            "param1": this.state.campaignid,
            "param2": el.order===1 && el.nonRespondersOnly ? new Date('2000-01-01T00:00:00Z') : new Date(el.scheduleDT).toISOString(),
            "param3": el.MessageType,
            "param4": el.nonRespondersOnly,
            "param5": this.state.user

          }
          await getData(body) 
        })

        this.setState({ isLoading:false});
        this.props.updateDirtyProductFlag(false)
        this.props.goto7('Campaign Messaging Setup is saved');

    });
 }

  componentWillMount() {
  //debugger;
  // console.log(" in schedule",this.props);
  //console.log(this.props.parentCampaign)
  if(this.props.parentCampaign.campaignid!==undefined && this.props.parentCampaign.campaignid !== 0){
    this.setState({
      campaignname:this.props.parentCampaign.campaignname, 
      campaignid:this.props.parentCampaign.campaignid,
      clientid: this.props.parentCampaign.clientid,
      user : this.props.parentProps.username,
      launchstatus: this.props.parentCampaign.launchstatus
    });
  }
} 


  async componentDidMount() {
    //debugger;
    // console.log(" in schedule",this.props);
    //console.log(this.props.parentCampaign)
    if(this.props.parentCampaign.campaignid!==undefined && this.props.parentCampaign.campaignid !== 0){
      this.setState({
        campaignname:this.props.parentCampaign.campaignname, 
        campaignid:this.props.parentCampaign.campaignid,
        clientid: this.props.parentCampaign.clientid,
        user : this.props.parentProps.username,
        launchstatus: this.props.parentCampaign.launchstatus,
        availableSends:[]      
      });

      await this.getAvailableSendsPerClient()
      .then(async ()=> await this.getSchedulesForCampaign())

      await this.getSmsSendRange()
      .then(r => {
        this.getMessageTypes();
      })

    }
    window.scrollTo(0, 0);
  }

  async getAvailableSendsPerClient(){
    // debugger;
     let body = {
       "procname" : "procClientAvailableMessageSendsPerDaySelect",
       "paramcount": "1", 
       "param1": this.props.parentCampaign.clientid
     }
 
     await getData(body)
     .then(json => this.setState({ availableSends: json[0], maxLimits:json[1]}));  
    }

  async getSchedulesForCampaign()
  {
   // debugger;
    let body = {
      "procname" : "procEventMessagingSetupSelect",
      "paramcount": "1", 
      "param1": this.props.parentCampaign.campaignid

    }

    await getData(body)
    .then(json => {
      //let schedulesTmp  =  [...json[0]]; //, isEmptyError=false, isInvalidDate=false 
      let schedulesTmp= json[0].map(el => {
        return {
             ...el,
            isEmptyError:false,
            isInvalidDate:false,
            isInvalidTime:false,
            isHidden:false,
            isCheckBoxError:false
        };
    });

    
      if(schedulesTmp.length===0)
      {
        schedulesTmp = [{order: 1, scheduleDT: null, MessageType:null, nonRespondersOnly: false, isEmptyError:false, isDeliveryTypeEmpty:false, isInvalidDate:false,isInvalidTime:false,isHidden:false}]
      }
      else 
        schedulesTmp.forEach(e=>{
          this.adjustAvailableCountsForDate(e.scheduleDT,e.MessageType);           
          return e.scheduleDT = new Date(e.scheduleDT)
        })

      this.setState({schedules: schedulesTmp, isFormLoading:false})

    }


    );
  }

  async getMessageTypes()
  {
    let foo = this.state.listmessagetype;
    let body = {
      "procname" : "procMessageTypeSelect",
      "paramcount": "0" 
    }

    await getData(body)
    .then(json => {
      let messagetypesTmp= json[0];

       if (this.state.listmessagetype !== 'Both')
       {
         let o = {};
         o.MessageType = this.state.listmessagetype
         messagetypesTmp = [];
         messagetypesTmp.push(o);
       }

       this.setState({ messagetypes: messagetypesTmp, isMessageTypesLoading:false})
    });
  }

  async getSmsSendRange()
  {
    let body = {
      "procname" : "procListSelectSmsSendRange",
      "paramcount": "1",
      "param1": this.props.parentCampaign.campaignid 
    }

    await getData(body)
    .then(json => {
      let smsSendRangeTmp= json[0];

      this.setState({ 
        listID: smsSendRangeTmp[0].ListID,
        listName: smsSendRangeTmp[0].ListName,
        countries: smsSendRangeTmp[0].Countries,
        listmessagetype: smsSendRangeTmp[0].ListMessageType,
        smsSendStartTimeUtcMax: smsSendRangeTmp[0].SmsSendStartTimeUtcMax,
        smsSendEndTimeUtcMin: smsSendRangeTmp[0].SmsSendEndTimeUtcMin,
        isSmsSendRangeLoading:false
      })
    });
  }

  handleMessageTypeChange = (name='') => e => {
    let schedules = [...this.state.schedules]   

    if(schedules[name].MessageType!== null)
    {
      this.creditBackAvailableCountsForChangedDate(schedules[name].scheduleDT,schedules[name].MessageType);
      schedules[name].scheduleDT = null;
      schedules[name].isInvalidDate=false;
      schedules[name].isInvalidTime=false;    
    }
     
    schedules[name].MessageType=e.target.value;
    
    if (schedules[0] === schedules[name] && e.target.value !== 'Email') {
      schedules[name].nonRespondersOnly = 0;
      schedules[name].isHidden = false;
    }

    this.setState({ schedules: schedules, isdirty:true})
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
	}


  getCurrentDateWithSuppliedTime(mytime)
  {
    let pieces = mytime.split(":");
    var date = new Date().getDate(); //Current Date
    date = ('0' + date).slice(-2); // Prepend zero if needed
    var month = new Date().getMonth() + 1; //Current Month
    month = ('0' + month).slice(-2); // Prepend zero if needed
    var year = new Date().getFullYear(); //Current Year
    var hours = pieces[0];
    var min = pieces[1]; //Current Minutes
    var sec = pieces[2]; //Current Seconds

    let mydatestring = year + '-' + month + '-' + date + 'T' + hours + ':' + min + ':' + sec + ".000+00:00";
    let mydate = Date.parse(mydatestring);

    //let mydateformatted = mydate.toLocaleString("en-US", {timeZone:"America/New_York"})
    //let blah = new Date(mydateformatted);
    //var testdate = mydate.toLocaleString('en-US',{hour12:true}).split(" ");

    return moment(mydate).format("hh:mm A");
   
  }

  render () {

    let {
        schedules,
        isFormLoading,
        isMessageTypesLoading,
        isSmsSendRangeLoading,
        messagetypes,
        countries,
        listmessagetype,
        smsSendStartTimeUtcMax, 
        smsSendEndTimeUtcMin
      } = this.state
    const { classes } = this.props;

    if(this.props.parentCampaign.campaignid===undefined)
    {
      return (<div>...Campaign is not configured</div>)
    }
    if (isFormLoading || isMessageTypesLoading || isSmsSendRangeLoading)
    {
      return (<div>&nbsp;</div>)
    }

    return (
      <Form onSubmit={this.handleSubmit} onChange={this.handleChange}>
      <Collapse in={this.state.listmessagetype === 'Both' || this.state.listmessagetype === 'Sms'}>
      <div class="campaign-review-page" style={{visibility:this.state.listmessagetype === 'Both' || this.state.listmessagetype === 'Sms'?'visible':'hidden'}}>
              
              <table class="campaign-schedule-table">
                <tr>
                  <td width="30%">Destination Countries</td>
                  <td>
                    {countries}
                  </td>
                </tr>

                <tr>
                  <td>Text Notification Availability</td>
                  <td>
                    {this.getCurrentDateWithSuppliedTime(smsSendStartTimeUtcMax)} - {this.getCurrentDateWithSuppliedTime(smsSendEndTimeUtcMin)} Eastern Time
                  </td>
                </tr>
              </table>
              <br />
              <br />
      </div>
      </Collapse>
      {
          schedules.map((val, i)=> {
            let scheduleId = `schedule-${i}`, scheduleDTId = `scheduleDT-${i}`
            let {availableSends} = this.state;
            return (
                <div key={i}>
                <span>{this.state.DT}</span>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                <Grid item xs={"auto"}>
                  <TextField
                      id="messagetype"
                      name={i}
                      select
                      label="Delivery Type"
                      className="MessageType"
                      required
                      value={schedules[i].MessageType}
                      style={{width:"175px",marginRight:"20px"}}
                      helperText= 'Select Delivery Type'
                      onChange={this.handleMessageTypeChange(i)}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{shrink: true}}
                    >
                      {messagetypes.map(mt => (
                        <MenuItem selected={schedules[i].MessageType=== mt.MessageType} key={mt.MessageType} value={mt.MessageType}>
                          {mt.MessageType}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>                  
                <Grid item xs={3}>
                {/* <FormGroup row justify="flex-start" alignItems="center"> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <DateTimePicker   
                  autoOk
                    name={scheduleDTId}
                    id={i}
                    label={this.labelText(i)}
                    inputVariant="outlined"
                    clearable
                    style={{width:"350px",marginRight:"20px"}}
                    value={i===0 && schedules[i].nonRespondersOnly? null: schedules[i].scheduleDT}
                    minDate={i===0? moment(Date.now()).add(10, 'm').toDate() : schedules[i-1].scheduleDT !== null ?moment(schedules[i-1].scheduleDT).add(10, 'm').toDate():moment(Date.now()).add(10, 'm').toDate()}
                    maxDate={schedules["length"] > i+1 && schedules[i+1].scheduleDT!==null? moment(schedules[i+1].scheduleDT).add(-10, 'm').toDate():moment(Date.now()).add(1, 'y').toDate()}
                    onChange={date=>this.handleDateChange(i,date)}
                    renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                      // debugger;
                      let obj = availableSends.find( x=>{ return moment(day).isSameOrAfter(moment(new Date()).format("YYYYMMDD"))
                                      && new Date(x.scheduleDate).toLocaleDateString()===day.toLocaleDateString()
                                      && (schedules[i].scheduleDT === null || new Date(x.scheduleDate).toLocaleDateString() !== schedules[i].scheduleDT.toLocaleDateString())})
                      
                      let {total, outedOut, unsubscribed} = this.props.parentCampaign;
                  
                      let totalSubscribied = total - unsubscribed;
                      let totalSMSable = total - outedOut;                      
                      let isBelowListCount = false;
                      let isUnavailable=false;
                      let text="";
                      var limit = 0;

                      if(isInCurrentMonth && obj!==undefined){ 
                          switch(schedules[i].MessageType){
                           case "Both": 
                              limit = this.state.maxLimits[0].DailyLimitEmail >= this.state.maxLimits[0].DailyLimitSMS ? this.state.maxLimits[0].DailyLimitSMS : this.state.maxLimits[0].DailyLimitEmail;                              
                              isUnavailable = obj.AvailableEmails === 0 || obj.AvailableTexts === 0;
                              isBelowListCount = !isUnavailable && (obj.AvailableEmails < totalSubscribied || obj.AvailableTexts < totalSMSable);
                              limit = isBelowListCount ? obj.AvailableEmails < totalSubscribied ? obj.AvailableEmails : obj.AvailableTexts : limit;

                              if (isUnavailable) text = `Daily message limit of ${limit} has been reached`;
                              if (isBelowListCount) text = `List of ${totalSubscribied >= totalSMSable ? totalSubscribied : totalSMSable} exceeds remaining daily messages of ${limit}`;
                             break;
                           case "Email":
                              isUnavailable = obj.AvailableEmails === 0 ;
                              isBelowListCount = !isUnavailable && obj.AvailableEmails < totalSubscribied;

                              if (isUnavailable) text = `Daily Email limit of ${this.state.maxLimits[0].DailyLimitEmail} has been reached`;
                              if (isBelowListCount) text = `List of ${totalSubscribied} exceeds remaining daily Email messages of ${obj.AvailableEmails}`;                            
                             break;
                           case "Sms": 
                              isUnavailable = obj.AvailableTexts === 0;
                              isBelowListCount = !isUnavailable && obj.AvailableTexts < totalSMSable;
                              if (isUnavailable) text = `Daily SMS limit of ${this.state.maxLimits[0].DailyLimitSMS} has been reached`;
                              if (isBelowListCount) text = `List of ${totalSMSable} exceeds remaining daily SMS messages of ${obj.AvailableTexts}`;  
                             break;         
                         }  
                         if (isUnavailable || isBelowListCount)
                          return <MUIToolTip title={text}  placement="left-start" arrow><Fab style={{backgroundColor: isUnavailable ? red["400"]: yellow["400"]}} className={classes.fab}>{dayComponent}</Fab></MUIToolTip>;
                        //return <MUIToolTip title={text}  placement="left-start" arrow><span style={{backgroundColor: isUnavailable ? red["400"]: yellow["400"], borderRadius: "50%", width: "40", height:"40",paddingTop: "7px",paddingBottom: "8px"}}>{dayComponent}</span></MUIToolTip>;
   
                      }

                      return dayComponent;

                    }}   
                    shouldDisableDate= {(day) => {
                      //debugger;
                      let disable = false;
                      let {total, outedOut, unsubscribed} = this.props.parentCampaign;
                  
                      let totalSubscribied = total - unsubscribed;
                      let totalSMSable = total - outedOut;    

                      let obj = availableSends.find( x=>{ return day >= new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()) 
                                && new Date(x.scheduleDate).toLocaleDateString()===day.toLocaleDateString()
                                && (schedules[i].scheduleDT === null || new Date(x.scheduleDate).toLocaleDateString() !== schedules[i].scheduleDT.toLocaleDateString())})

                      if(obj!==undefined){
                          switch(schedules[i].MessageType){
                           case "Both": 
                              disable = obj.AvailableEmails - totalSubscribied < 0 || obj.AvailableTexts - totalSMSable < 0;
                             break;
                           case "Email":
                              disable = obj.AvailableEmails - totalSubscribied < 0
                             break;
                           case "Sms": 
                              disable = obj.AvailableTexts - totalSMSable < 0
                             break;         
                         }  
                      }
                      return disable;
                    }}              
                    allowKeyboardControl={true}
                    format="MM/dd/yyyy hh:mm a"
                    disablePast
                    helperText= {schedules[i].isInvalidTime ? `The SMS time range is between ${this.getCurrentDateWithSuppliedTime(this.state.smsSendStartTimeUtcMax)} and ${this.getCurrentDateWithSuppliedTime(this.state.smsSendEndTimeUtcMin)}`: schedules[i].isInvalidDate ? 'Date is out of range':(schedules[i].isEmptyError ? 'Date is required' :(schedules[i].isCheckBoxError ? 'Check distribution option' : (i===0 && schedules[i].nonRespondersOnly ? 'Upon Launch':'Select Date')))}
                    disabled = {schedules[i].isHidden || (i===0 && schedules[i].nonRespondersOnly) || schedules[i].MessageType===null}
                    error={schedules[i].isEmptyError || schedules[i].isInvalidDate || schedules[i].isInvalidTime || schedules[i].isCheckBoxError}
                    margin="normal"
                    initialFocusedDate = {i===0? moment(Date.now()).add(10, 'm').toDate() : schedules[i-1].scheduleDT !== null ?moment(schedules[i-1].scheduleDT).add(10, 'm').toDate():moment(Date.now()).add(10, 'm').toDate()}
                    //placeholder="Select Date"
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <CalendarToday />
                          </IconButton>
                        </InputAdornment>
                      ),          
                    }}
                  />
                  </MuiPickersUtilsProvider>      
                </Grid>
                <Grid item xs={6} >          
                {(i > 0 ?
                <span>
                <input
                type="radio"
                name={scheduleId}
                data-id={i}
                id={scheduleId}
                checked={schedules[i].nonRespondersOnly!== null && !schedules[i].nonRespondersOnly} 
                value="all" 
                className="nonRespondersOnly"
                style={{"margin-left": "20px",  width: "18px", height: "18px", border: "2px solid black", borderColor:"red", transition: "0.2s all linear", "margin-right": "5px", position: "relative", top: "4px"}}
              />
              <label style={{"margin-left": "5px"}} htmlFor={scheduleId}>All Contacts</label>  
                <input
                type="radio"
                name={scheduleId}
                data-id={i}
                id={scheduleId}
                checked={schedules[i].nonRespondersOnly} 
                value="nonResp"
                className="nonRespondersOnly"
                style={{"margin-left": "20px",  width: "18px", height: "18px", border: "2px solid #999",  transition: "0.2s all linear", "margin-right": "5px", position: "relative", top: "4px"}}
              />
                </span>
                
                :'')}
            
                <label style={{"margin-left": "5px"}} htmlFor={scheduleId}>{(i>0)  ? 'Non-Responders Only' : '' }</label>                   

                 {(i>0)  ?
                <OverlayTrigger placement="right" delay={{ show: 150, hide: 300 }} overlay={<Tooltip>Remove schedule </Tooltip>}>
                  {/* <img id={i} height={20} width={20} style={{"margin-left":"15px"}} src={require('../images/x.png')} onClick={this.removeSchedule} />    */}
                  <Delete style={{width:"23px", height:"23px", color: "#1782c5", marginLeft:"10px",paddingTop:"5px" }} onClick={this.removeSchedule(i)}  /> 
                </OverlayTrigger>
                :
                <span></span>}
        {/* </FormGroup> */}
        </Grid>
        </Grid>
              </div>
            )
          })
        }
        <p/>
        <div style={{textAlign: "left"}}>
        
        <label id='addSchedule' style={{width:"300px", verticalAlign:"middle",fontSize:"15px", display: this.state.hideAdd}} onClick={this.addschedule} >
        <AddCircle style={{verticalAlign:"middle", color: "#1782c5",paddingRight:"3px" }}/> <font color= "#1782c5">Add a Follow Up Email Date (up to <b>10</b>)</font>
         </label>        
         </div>
        <br/>
      <div>
        <LoaderButton
          block
          bsSize="small"
          type="submit"
          isLoading={this.state.isLoading}
          style={{marginLeft:"85%", width:"80px",bottom:"30px",position:"fixed"}}
          text="Next"
          //loadingText="Saving Campaign�"
           disabled={!this.validateForm() || this.props.parentCampaign.launchstatus !== 'PE'}
        />
        </div>         
      </Form>
    )
  }
}

CampaignSchedule.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CampaignSchedule);