import React from "react";
import {Link} from "react-router-dom";
import "../css/rrr.css";
import ReactTooltip from 'react-tooltip';
import Config from '../config'

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import Icon from '@material-ui/core/Icon';
import Grade from '@material-ui/icons/Grade';
import MaterialTable from 'material-table';
// import {tableIcons} from "./SharedFunctions";
//import OutlinedTextFields from '../components/Contact';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Store from '@material-ui/icons/Store';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import Person from '@material-ui/icons/Person';
import Web from '@material-ui/icons/Web';
import Build from '@material-ui/icons/Build';
import Input from '@material-ui/icons/Input';
import FilterVintage from '@material-ui/icons/FilterVintage';
import Copyright from '@material-ui/icons/Copyright';
import HelpOutline from '@material-ui/icons/HelpOutline';


const useStyles = makeStyles(theme => ({
  title: {
    color: "#007dbc",  /*#00956e  */
    marginTop: theme.spacing(2),
    fontWeight:"fontWeightBold",
    fontFamily: "AvenirNextLTPro",
    height: "24px",
    fontSize: "14px",
  }, 
  title_grey: {
    color:grey[400],
    fontWeight:"fontWeightLight",
    marginTop: theme.spacing(2),
    marginRight: "40px",
    fontFamily: "Ebrima",
    fontSize: "14px",
    fontStyle:"oblique",
    },
  }));

export default ({
    selected,
    clientid,
    backgroundColor,
    isproductdirty,
    props,
    confirmationUnsaved_LM,
    obj
    
    
  }) => {
//  console.log('lm props')
//  console.log(props)


    let clientDasbhoardLink = "/Dashboard/" + Config.quicksight.CLIENT_DASHBOARD_ID;
    if(props.clientName !== undefined && props.clientName != null && props.isSRCLUser) {
      clientDasbhoardLink += "?Client=" + props.clientName;
    }
    //if(backgroundColor === undefined)
    backgroundColor = Config.SDWK_Color //Config.SRCL_Color
    let isIncompletePassword= props.location.state!==undefined && props.location.state.passwordStatus!==undefined && props.location.state.passwordStatus==='IN'?true:false

    // console.log('props_menu', props);
    // console.log('dirty prop', isproductdirty)

return (
<div className="LeftMenu" style={{backgroundColor: backgroundColor, paddingTop:"10px"}} >
  {isIncompletePassword ? <span/> :
  props.isSRCLUser ? <span>
<p style={{height:"10px"}}/>
  <p data-tip='' data-for="React-tooltip-clients" >
      <Link onClick={()=>props.handleStateChange(-1)} to = {{pathname: "clients",  state: {clientid: -1}}}>
        {selected === 'clients'
            ? 
            <p class= {'SelectedMenuImgBG'} align="center"> 
              <Store style={{width:"27px", height:"27px", marginTop:"10px",color:"white"}}/>
              </p>
            : <Store style={{width:"37px", height:"37px", padding:"3px", color:Config.SDWK_color_light}}/>
        }
        <ReactTooltip id="React-tooltip-clients" class='tooltip-custom' place="right" type="light" effect="float">
            {'Clients'}
        </ReactTooltip>
    </Link>
  </p>
  {/* <div style={{height:"0.04em", backgroundColor:"white", marginBottom:"15px"}}></div> */}
  </span>
  : <div style={{marginTop:"30px"}}></div>
}
{props.isSRCLUser && props.clientid > 0 ? 
<div style={{height:"0.04em", backgroundColor:Config.SDWK_color_light, marginBottom:"15px"}}></div> 
  : ''}
{!isIncompletePassword && props.clientid !== null && props.clientid > 0 ?


<span >

<p data-tip='' data-for="React-tooltip-account" >
      <Link onClick={confirmationUnsaved_LM('account')} to = {{pathname: "ManageClient",  state: {clientid: clientid}}}>
        {selected === 'account'
            ? 
            <p class= {'SelectedMenuImgBG'} align="center">
              <Person style={{width:"27px", height:"27px", marginTop:"10px", color:"white"}}/>
              </p>
            : <Person style={{width:"37px", height:"37px", padding:"3px", color:Config.SDWK_color_light}}/>
        }
        <ReactTooltip id="React-tooltip-account" class='tooltip-custom' place="right" type="light" effect="float">
            {'My Account'}
        </ReactTooltip>
    </Link>
  </p>
  <p style={{height:"10px"}}/>
  <p data-tip='' data-for="React-tooltip-contacts">
        <Link onClick={confirmationUnsaved_LM('contacts')} to = {{pathname: "Contacts",  state: {clientid: clientid}}}>
        {selected === 'contacts'
            ? 
            <p class= {'SelectedMenuImgBG'} align="center">
              <PermContactCalendar style={{width:"27px", height:"27px", marginTop:"10px", color:"white"}}/>
              </p>
            : <PermContactCalendar style={{width:"37px", height:"37px", padding:"3px", color:Config.SDWK_color_light}}/>
        }
        <ReactTooltip id="React-tooltip-contacts" class='tooltip-custom' place="right" type="light" effect="float">
            {'My Contacts'}
        </ReactTooltip>
        </Link>        
  </p>
  <p style={{height:"10px"}}/>
  <p data-tip=""  data-for="React-tooltip-campaign">
      <Link onClick={confirmationUnsaved_LM('campaigns')} to = {{pathname: "Campaigns",  state: {clientid: clientid}}}>
        {selected === 'campaigns'
            ? 
            <p class= {'SelectedMenuImgBG'} align="center">
              <Web style={{width:"27px", height:"27px", marginTop:"10px", color:"white"}}/>
            </p>
            : <Web style={{width:"37px", height:"37px", padding:"3px", color:Config.SDWK_color_light}}/>
        }
        <ReactTooltip id="React-tooltip-campaign" class='tooltip-custom' place="right" type="light" effect="float">
            {'My Campaigns'}
        </ReactTooltip>
    </Link>
  </p>
  </span>
:''
}
{!isIncompletePassword && props.isSRCLUser && props.clientid > 0 ? 
<div style={{height:"0.1em", backgroundColor:Config.SDWK_color_light}}></div> 
  : ''}
  {!isIncompletePassword && props.isSRCLUser ? <span>
  <p style={{height:"10px"}}/>
  <p data-tip=""  data-for="React-tooltip-admin">

  <Link onClick={confirmationUnsaved_LM('admin')} to = {{pathname: "Admin",  state: {clientid: clientid}}}>
        {selected === 'admin'
            ? 
            <p class= {'SelectedMenuImgBG'} align="center">
              <Build style={{width:"27px", height:"27px", marginTop:"10px", color:"white"}}/>
              </p>
            : <Build style={{width:"37px", height:"37px", padding:"3px", color:Config.SDWK_color_light}}/>
        }
        <ReactTooltip id="React-tooltip-admin" class='tooltip-custom' class='tooltip-custom' place="right" type="light" effect="float">
          {'Admin'}
        </ReactTooltip>
    </Link>
  </p></span>
  : ''
  }
  <p style={{height:"10px"}}/>
  <p data-tip=""  data-for="React-tooltip-report">

  {isIncompletePassword ? <span/>:
  props.clientid === -1 ?
  <Link onClick={()=>{ window.open(`/Dashboard/${Config.quicksight.SRCL_DASHBOARD_ID}`) }} to="#">
    <img style={{width:"20", height:"20", paddingLeft:"1px"}}  src={require(`../images/report.png`)}/>
    <ReactTooltip id="React-tooltip-report" class='tooltip-custom' place="right" type="light" effect="float">
      {'Internal Reports'}
    </ReactTooltip>
  </Link>
  :
  <Link onClick={()=>{ window.open(`${clientDasbhoardLink}`) }} to="#">
    <img style={{width:"20", height:"20", paddingLeft:"1px"}}  src={require(`../images/report.png`)}/>
    <ReactTooltip id="React-tooltip-report" class='tooltip-custom' place="right" type="light" effect="float">
      {'Client Reports'}
    </ReactTooltip>
  </Link>
  }
  </p>

  <p style={{height:"10px"}}/>
  <p data-tip=""  data-for="React-tooltip-logout">
  <Link to = "#" onClick={()=>props.handleLogout()}>
   <Input style={{width:"37px", height:"37px", padding:"3px", color:Config.SDWK_color_light}} />
    <ReactTooltip id="React-tooltip-logout" class='tooltip-custom' place="right" type="light" effect="float">
          {'Log Out'}
    </ReactTooltip>
  </Link>      
  </p>
  {isIncompletePassword ? <span/>:<>
    <p style={{height:"10px"}}/>
    <p data-tip=""  data-for="React-tooltip-settings">
  <Link onClick={confirmationUnsaved_LM('changepassword')} to = {{pathname: "changepassword",  state: {clientid: clientid}}}>
      {selected === 'settings'
                ? 
                <p class= {'SelectedMenuImgBG'}  style={{position: "fixed",bottom: "112px"}} align="center">
                  <FilterVintage style={{width:"25px", height:"25px", marginTop:"10px", color:"white"}}/>
                  </p>
                : <FilterVintage style={{position: "fixed",bottom: "127px",width:"37px", height:"37px", padding:"3px", color:Config.SDWK_color_light}}/>//do not change dimentions
            }    
    <ReactTooltip id="React-tooltip-settings" class='tooltip-custom' place="right" type="light" effect="float">
          {'Change Password'}
        </ReactTooltip>
    </Link>
  </p>  


    <p style={{height:"10px"}}/>
  <p data-tip=""  data-for="React-tooltip-faq">
  <Link onClick={confirmationUnsaved_LM('faq')} to = {{pathname: "Faq",  state: {clientid: clientid}}}>
      {selected === 'faq'
                ? 
                <p class= {'SelectedMenuImgBG'}  style={{position: "fixed",bottom: "52px"}} align="center">
                  <HelpOutline style={{width:"30px", height:"30px", marginTop:"8px", color:"white"}}/>
                  </p>
                : <HelpOutline style={{position: "fixed",bottom: "67px",width:"40px", height:"40px", padding:"3px 3px 3px 1px", color:Config.SDWK_color_light}}/>//do not change dimentions
            }    
    <ReactTooltip id="React-tooltip-faq" class='tooltip-custom' place="right" type="light" effect="float">
          {'FAQs'}
        </ReactTooltip>
    </Link>
  </p> 


  {/* <p style={{height:"10px"}}/> */}
  <p data-tip=""  data-for="React-tooltip-copyright">
  <Link onClick={()=>{ window.open(`https://www.sedgwick.com/global-privacy-policy`) }} to="#">
    <Copyright style={{position: "fixed",bottom: "16px",width:"37px", height:"37px", color:Config.SDWK_color_light}} />
    <ReactTooltip id="React-tooltip-copyright" class='tooltip-custom' place="right" type="light" effect="float">
          {'Privacy Policy & Copyright'}
        </ReactTooltip>
    </Link>
  </p> 
  <div style={{position: "fixed", bottom: "0px", fontSize: "small"}}>v{Config.APP_VERSION}</div>
  </>}
</div>);
}

export function PageHeader (obj) {
  // console.log(obj)
  const classes = useStyles();
  return (
    <>
    <div style={{paddingLeft:"30px"}}>
    <Grid container>
      <Grid item xs>
      <Typography display="block"  className={classes.title_grey}>
        {/* // style={{paddingRight:"40px"}}> */}
          {obj.username!==null?`logged in as ${obj.username}`:''}
      </Typography>           
      </Grid>
      <Grid item>
      <label align="left" style={{marginTop: "18px", marginRight:"30px"}} class="title_page">Notiﬁcation Solutions™<br />(v{(Config.APP_VERSION).trim()})</label>
      </Grid>
    </Grid>
  </div>  
  <div style={{paddingLeft:"30px",paddingBottom:"15px"}}>
    <Grid container   
        direction="row"
        justify="space-between"
        alignItems="center">
      <Grid item xs>
        {obj.clientname !== undefined && obj.clientname.length > 0 ?
        <label align="left" class="Account">{`${obj.title} (${obj.clientname})`}</label> :
        <label align="left" class="Account">{`${obj.title}`}</label>        
        }
      </Grid>
      <Grid item>
      <img style={{width: "135px", height: "43px", marginRight:"40px", paddingTop:"5px"}} src={require(`../images/SDWK_PrimaryLogo_Black.png`)}/>
      </Grid>
    </Grid>
  </div>      
  </>
  )
}