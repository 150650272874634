import React, {forwardRef} from "react";
import {Auth, API } from "aws-amplify";
import config from "../config";

export default async function getUsers(clientid=0,userName=""){

    const body = {
        "procname" : "procUserSelect",
        "paramcount": "2", 
        "param1": clientid,
        "param2": userName
      }   

    return await getData(body);
    
  }

  export async function getData(Body, funcName='getdata'){
    // NOTE: This provides the OpenID token (not the Access Token) from Cognito to the API Gateway for User Pool authorization
    const user = await Auth.currentAuthenticatedUser();
    let localApiGatewayOBJ = {...config.apiGateway.OBJ}
    localApiGatewayOBJ.headers.Authorization = user.signInUserSession.idToken.jwtToken;

    let requestPayload = {
      body: Body,
      headers: localApiGatewayOBJ.headers
    }

    return await API.post('getDashboardEmbedURL', funcName, requestPayload);
  }

  export  async function getSubscriptionPlans(){
      //get packages
      
    const body = {
        "procname" : "procSubscriptionPlanSelect",
          "paramcount": "1", 
          "param1": true
      }   
       

      return await getData(body);
    
    }

    export const filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      if (row[id] !== null){
          return (
              row[id] !== undefined ?
                  String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                  :
                  true
          );
      }
  };      
  