import React, { Component } from "react";
import { Link, BrowserRouter as Router, Route } from "react-router-dom";
import {Label, Button, Form, FormControl, FormGroup, ColHelpBlock,ControlLabel} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
//import "./CreateUser.css";
import "../css/rrr.css";
import { Auth } from "aws-amplify";
import AWS from 'aws-sdk';
import config from "../config";
import ReactTable from "react-table";
import ReactTooltip from 'react-tooltip';
import "react-table/react-table.css";
import queryString from 'query-string';
import LeftMenu,{PageHeader} from '../components/leftmenu';
import { container } from "@aws-amplify/ui";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Switch from "react-switch";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ManageClient.css";
import getUsers, {getData} from "./SharedFunctions";
import Select from 'react-select';
import { join } from "path";
import Modal from 'react-awesome-modal';
import { s3Upload } from "../libs/awsLib";
import {Grid,MenuItem} from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles';
// import red from '@material-ui/core/colors/red';
// import grey from '@material-ui/core/colors/grey';
// import PropTypes from 'prop-types';
// import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';

 import {TextField} from '@material-ui/core';
//Button, MenuItem,
const styles = theme => ({
  textField: {
   // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: 170,
    color:"black"
   // backgroundColor: "white"
  },
  submit: {
    //padding: theme.spacing(3),
    backgroundColor:"#1782c5",//backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"
    borderColor:"#1782c5",
    color:"white",
    marginTop: "18px", 
    height:"45px",  
    width:" 120px", 
    //marginLeft:"1em"
    borderRadius: "6px",
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4)
  },  
});



class CreateContactList extends Component {
  
  constructor(props) {
    super(props);

      //const clientinfo = {clientid:null};

    this.state = {
      clientColor: "#ff6602",
      savedClientColor : "",
      checked: false,
      isLoading: false,
      isFormLoading : false,
      isPlanFormLoading : false,
      dataSource: [],
      usersDataSource: [],
      listname: "",
      logo: null,
      clientid: null,
      logoURN:'',
      visible : false,
      text: "my hair",
      file: null,
      filePath: null,
      fileName: null,
      uploadedFile: null,
      listid:0,
      hasChanged:false,
      isDisabled:true,
      selectedmessagetype:null,
      messagetypes: []
    }
       this.handleChange = this.handleChange.bind(this);
       this.createList = this.createList.bind(this);  
       this.handleButtonClick = this.handleButtonClick.bind(this);  
           
}

validateForm() {
  return (
    this.state.listname.length > 0
    && this.state.file!== null
  );
}

handleChangeFile = event => {
  if (event.target.files[0].size > config.MAX_ATTACHMENT_SIZE) {
    //alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
    toast.error(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`, {position: toast.POSITION.TOP_RIGHT})
    return;
  }

  if (!event.target.files[0].name.toLowerCase().endsWith('csv')) {
    //alert(`Only comma delimited CSV text files are allowed for upload.`);
    toast.error('Only comma delimited CSV text files are allowed for upload', {position: toast.POSITION.TOP_RIGHT})
    return;
  }


  this.setState({
    filePath: URL.createObjectURL(event.target.files[0]),
    file: event.target.files[0]
  });

  //console.log(this.state.file.name);
}


handleChange = event => {
  this.setState({
    hasChanged: true,
    [event.target.id]: event.target.value
  });
}

handleMessageTypeChange = event => {
  this.setState({
    hasChanged: true,
    selectedmessagetype: event.target.value
  });
}


handleCancel = event => {
  this.props.history.push({pathname: 'Contacts',state: {clientid: this.state.clientid}});
}

updateAttributs = async()=>{
//debugger;
  if(document.getElementById('edit').innerText==="Edit")
  {
    this.setState({isDisabled:false})

    document.getElementById('edit').innerText="Save"
    document.getElementById('cancelAttr').style.visibility = 'visible';
  }
  else if(this.state.hasChanged)
  {
  const body = {
    "procname" : "procClientLanguageSave",
    "paramcount": "9", 
    "param1": this.state.clientid,
    "param2": 'en',    
    "param3": this.state.refnum===undefined?'':this.state.refnum,
    "param4": this.state.attr1===undefined?'':this.state.attr1,    
    "param5": this.state.attr2===undefined?'':this.state.attr2,
    "param6": this.state.attr3===undefined?'':this.state.attr3,    
    "param7": this.state.attr4===undefined?'':this.state.attr4,
    "param8": this.state.attr5===undefined?'':this.state.attr5,    
    "param9": this.props.username
  }

  await getData(body)
  .then(()=>this.closeEdit());

  }
 

}

closeEdit = async()=>{
  this.setState({isDisabled:true})  
  document.getElementById('edit').innerText="Edit"
  document.getElementById('cancelAttr').style.visibility = 'hidden';

  await this.getClientlanguages()
}


handleButtonClick(e) {
  document.getElementById("file").click();
}

createList = async()=>{

  const body = {
    "procname" : "procListInsert",
    "paramcount": "4", 
    "param1": this.state.clientid,
    "param2": this.state.listname,
    "param3": this.state.selectedmessagetype,
    "param4": this.props.username
}

// debugger;
await getData(body)
.then(json => this.setState({listid: json[0][0].ListID}))
.then(this.setState({ isLoading: true }))
.then(async ()=>
{
  try {
    
    this.setState({fileName: this.state.listid > 0 ? 'client' + this.state.clientid + '/' + this.state.listid +'-contactlist.csv' : ''}) 
    // console.log(this.state); 
    const attachment = this.state.file && this.state.fileName.length > 0
    ? await s3Upload(this.state.file,this.state.fileName,true)
    : null;
  

  this.setState({isLoading: false });
  this.props.history.push({pathname: 'Contacts', state: {clientid: this.state.clientid}})
  } 
  catch (e) {
    alert(e);
    this.setState({ isLoading: false });
  }
});


}

	//method to handle signUp submit button
	async componentDidMount() {

    // console.log('props');
    // console.log(this.props);

    this.state.clientid = this.props.location.state.clientid;

  //   const body = {
  //     "procname" : "procClientSelect",
  //     "paramcount": "2", 
  //     "param1": this.state.clientid,
  //     "param2": ''
  // }

  // await getData(body)
  // .then(json => this.setState({dataSource: json[0]}))
  // .then(await this.getClientlanguages())    
  await this.getClientlanguages();
  await this.getMessageTypes();
  document.getElementById('cancelAttr').style.visibility = 'hidden';
  }


  async getClientlanguages(){

    const bodyLanguage = {
      "procname" : "procClientLanguageSelect",
      "paramcount": "1", 
      "param1": this.state.clientid
    }
    await getData(bodyLanguage)
    .then(json => this.setState({attrSource: json[0]}))
    .then(()=>{
      this.state.attrSource.length > 0 ?
        this.setState({refnum : this.state.attrSource[0].refnum,attr1 : this.state.attrSource[0].attr1,attr2 : this.state.attrSource[0].attr2,attr3 : this.state.attrSource[0].attr3,attr4 : this.state.attrSource[0].attr4,attr5 : this.state.attrSource[0].attr5})
       :  this.setState({attrSource : null})
    });

  }

  async getMessageTypes()
  {
   // debugger;
    let body = {
      "procname" : "procMessageTypeSelect",
      "paramcount": "0" 
    }

    await getData(body)
    .then(json => {
      let messagetypesTmp= json[0].map(el => {
        return {
             ...el
        };
      });

      this.setState({ messagetypes: messagetypesTmp, isMessageTypesLoading:false})
    });
  }

  render() {

    const {isLoading} = this.state;
    const {isFormLoading} = false;
    const { classes } = this.props;

    //  if(dataSource.length === 0)
    //   return <div />

            
    return (

     
      <div style={{width:"100%", borderStyle:"solid", borderColor:this.props.siteConfig.srclColor, borderWidth:"0px"}}>

        <LeftMenu selected="contacts" confirmationUnsaved_LM={(tmp) => event => {return true}} clientid={this.state.clientid} props={this.props}/>

        <div className="MainInnerWrapperDiv">
        <PageHeader title="Create Contact List" username={this.props.username} clientname={this.props.isSRCLUser?this.state.clientName:''}/>            

          
        <div style={{paddingLeft:"30px", width : "95%"}}>
           <div style={{width:"80%"}}>
           <Form> 
           {/* //noValidate validated={validated} */}
           <div >
           <Grid container direction="row" justify='flex-start' alignItems="center" spacing='5'>
           <Grid item xs={"auto"}>
        <TextField
          id="listname"
          label="List Name"
          className={classes.textField}
          style={{width:"300px"}}
          //error={caller.state.clientnameInvalid}
          //helperText={caller.state.clientnameInvalid ?'Client Name exists. Please enter a unique name':''}  
          value={this.state.listname}
          onChange={this.handleChange}
          margin="normal"
          required
          variant="outlined"
          placeholder="[list name]"
          InputLabelProps={{shrink: true}}
        />     
        </Grid>
        <Grid item xs={6}>
        <TextField
          id="messagetype"
          name="messagetype"
          select
          label="Intended Delivery Type"
          className={classes.textField}
          //required
          //helperText="&nbsp;"  //for spacing
          value={this.state.selectedmessagetype}
          style={{width:"175px"}}
          onChange={this.handleMessageTypeChange}
          margin="normal"
          variant="outlined"
          InputLabelProps={{shrink: true}}
        >
          {this.state.messagetypes.map(mt => (
            <MenuItem selected={this.state.selectedmessagetype=== mt.MessageType} key={mt.MessageType} value={mt.MessageType}>
              {mt.MessageType}
            </MenuItem>
          ))}
        </TextField>
        </Grid>
        </Grid>                 
        </div>        
        <div style={{float:"left", width:"95%", paddingTop:"0px", textAlign:"left"}}>
                <p>
                <label class="Product_Header" style={{marginTop:"50px"}} >Attributes</label>
                  <label id="edit" style={{width: "61px", height: "16px",  "font-family": 'Ariel',  "font-size": "18px",  "font-weight": "normal",
                  color: "#008bd9", "padding-left": "20px", "padding-top":"5px;" }} onClick = {this.updateAttributs}>Edit</label>                  
                  <label id="cancelAttr" style={{width: "61px", height: "16px",  "font-family": 'Ariel',  "font-size": "18px",  "font-weight": "normal",
                  color: "#008bd9", "padding-left": "20px", "padding-top":"5px;" }} onClick = {this.closeEdit}>Cancel</label>           
                </p>
             </div>  
              {/* <div style={{width :"38%", float:"left"}}> */}
                      {/* <Form.Group controlId="refnum" bsSize="small" style={{paddingRight: "5px"}}>
                        <Form.Label style={{fontSize:"14px"}}>Customer Ref Number</Form.Label>
                        <Form.Control 
                          required
                          autoFocus
                          disabled
                          
                          type="text"
                          value={this.state.refnum}
                          onChange={this.handleChange}  
                          />
                      </Form.Group>    */}
                              {/* <TextField
                                id="refnum"
                                label="Customer Ref Number"
                                className={classes.textField} 
                                value={this.state.refnum}
                                onChange={this.handleChange}
                                disabled={this.state.isDisabled}
                                margin="normal"
                                placeholder="[customer ref name]"
                                InputLabelProps={{shrink: true}}
                              />  
                </div>                 */}
                <div>
                {/* style={{width :"40%", float:"left"}} */}
                 
                          <TextField
                            id="attr1"
                            label="Attribute 1"
                            className={classes.textField}
                            value={this.state.attr1}
                            onChange={this.handleChange}
                            disabled={this.state.isDisabled}
                            margin="normal"
                            placeholder="[attribute name]"
                            InputLabelProps={{shrink: true}}
                          />  
                   
                      <TextField
                            id="attr2"
                            label="Attribute 2"
                            className={classes.textField}
                            value={this.state.attr2}
                            onChange={this.handleChange}
                            disabled={this.state.isDisabled}
                            margin="normal"
                            placeholder="[attribute name]"
                            InputLabelProps={{shrink: true}}
                          />                         
                     
                      <TextField
                            id="attr3"
                            label="Attribute 3"
                            className={classes.textField}
                            value={this.state.attr3}
                            onChange={this.handleChange}
                            disabled={this.state.isDisabled}
                            margin="normal"
                            placeholder="[attribute name]"
                            InputLabelProps={{shrink: true}}
                          />     
                     
                        <TextField
                            id="attr4"
                            label="Attribute 4"
                            className={classes.textField}
                            value={this.state.attr4}
                            onChange={this.handleChange}
                            disabled={this.state.isDisabled}
                            margin="normal"
                            placeholder="[attribute name]"
                            InputLabelProps={{shrink: true}}
                          />    
                    
                      <TextField
                            id="attr5"
                            label="Attribute 5"
                            className={classes.textField}
                            value={this.state.attr5}
                            onChange={this.handleChange}
                            disabled={this.state.isDisabled}
                            margin="normal"
                            placeholder="[attribute name]"
                            InputLabelProps={{shrink: true}}
                          />      
                      
               </div>                
      

          <div>
          {/* <div style={{float:"left", width:"95%", paddingTop:"0px", "margin":"5px", textAlign:"left"}}> */}
         
          <label class="Product_Header" style={{marginTop:"50px"}} >Contacts</label>
                
{/* </div> */}
            <input name="fileControl" id="file" type="file" onChange={this.handleChangeFile} style={{display: "none"}} />
            <br/>
            <FormGroup row alignItems="bottom">
            <Button
                //type="submit"
                style={{backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"}}
                variant="outlined" //color="secondary"     
                className={classes.submit}
                alignItems="flex-end"
                onClick={this.handleButtonClick}
            >
              Select a File
            </Button>  
            <label textAlign="bottom">{this.state.file !== null ? this.state.file.name : ''}  </label>
            </FormGroup>
           
            
          </div>

           <div width="40%">

              <div style={{float:"left"}}>
                  <Button
                      alignItems="flex-end"
                      variant="contained"
                      color="primary"
                      style={{with:"170px"}}
                      onClick={this.createList}
                      className={classes.submit}
                      disabled={!this.validateForm()}
                    >
                    Upload List
                  </Button>      
                  <Button
                      style={{backgroundColor:"white", color:"#1782c5",borderColor:"#1782c5"}}
                      variant="outlined" //color="secondary"     
                      className={classes.submit}
                      alignItems="flex-end"
                      onClick = {this.handleCancel}  
                  >
                    Cancel
                  </Button>                               
              </div>
            </div>              
           </Form>
           </div>
          </div>
        </div>  
        </div> 
     // </div> 
    );
  }
}

export default withStyles(styles)(CreateContactList);