'use strict'
import React from 'react';
//import {FormGroup,FormControl} from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import "../css/rrr.css";
import LoaderButton from "../components/LoaderButton";
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import {getData} from "./SharedFunctions";
import { withStyles } from '@material-ui/core/styles';
//import FormHelperText from '@material-ui/core/FormHelperText';
import {FormControlLabel,FormGroup,FormControl,FormLabel,RadioGroup,Radio} from '@material-ui/core';

import {Grid, Button, MenuItem, TextField} from '@material-ui/core';
import PropTypes from 'prop-types';
import config from '../config';
import { s3Upload, s3Remove } from "../libs/awsLib";
import { Link} from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';

const styles = theme => ({
  formControl: {
    //margin: theme.spacing(1),
    color:"black",
    fontWeight: 500
  }
});

class CampaignResponse extends React.Component {
  constructor () {
    super()
    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubjectChange = this.handleSubjectChange.bind(this);

    this.modules = {
      toolbar: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{ 'align': [] }],
          [{ 'color': [] }, { 'background': [] }],
          ['link'],
          ['clean']
        ]
    };
  
    this.formats = [
        'font',
        'size',
        'bold', 'italic', 'underline',
        'list', 'bullet',
        'align',
        'color', 'background',
        'link'
      ];

    this.state = {
      isLoading: false,
      clientname: "",
      clientid: 0,
      login:'admin@stericycle.com',
      campaignname:"",
      webheader: '',
      webdetail: '',
      webdetalHTML: '',
      webfooter : '',
      hotline: "",
      hasnoresponse:false,
      logoURN:'',
      siteColor:'',
      isdirty:false,
      hasLogo:false,
      contentMatchHeader:'',
      contentMatchDetail:'',
      contentMatchFooter:'',
      contentMatchHotLine:'',
      
    };

    this.file=''
  }

  componentWillMount() {
    //debugger;
    // console.log(" in wiz2",this.props.parentCampaign.campaignid);

    
    if(this.props.parentCampaign.campaignid !== 0){
      this.setState({
        campaignname:this.props.parentCampaign.campaignname, 
        campaignid:this.props.parentCampaign.campaignid,
        clientid: this.props.parentCampaign.clientid,
        webheader: this.props.parentCampaign.webhdrmsg,
        webdetail: this.props.parentCampaign.webdetailmsg,
        webfooter: this.props.parentCampaign.webfootermsg,
        hotline: this.props.parentCampaign.recallhotline,
        launchstatus: this.props.parentCampaign.launchstatus,
        login: this.props.parentProps.username,
        hasnoresponse: (this.props.parentCampaign.hasnoresponse ===1),
        logoURN: this.props.parentCampaign.logoURN!==undefined && this.props.parentCampaign.logoURN!==null && this.props.parentCampaign.logoURN.length > 0 ?  this.props.parentCampaign.logoURN : '0',
        siteColor: this.props.parentCampaign.siteColor!==undefined &&  this.props.parentCampaign.siteColor!==null && this.props.parentCampaign.siteColor.length > 0 ? this.props.parentCampaign.siteColor:config.SDWK_Color
      });
      

    }
    window.scrollTo(0, 0);
  }

  handleHeaderChange = (event) => {
    this.setState({ webheader: event.target.value, isdirty:true   })
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
  }
  
  handleDetailChange = (event) => {
    let {isdirty} = this.state
    isdirty = (event !== this.props.parentCampaign.webdetailmsg)   
    this.setState({ webdetail: event, isdirty:isdirty  });
    //debugger;
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(isdirty)
  }

  handleFooterChange = (event) => {
    let {isdirty} = this.state
    isdirty = (event !== this.props.parentCampaign.webfootermsg)       
    this.setState({ webfooter: event, isdirty:isdirty   })
  }

  handleHotlineChange = (event) => {
    let {isdirty} = this.state
    isdirty = (event !== this.props.parentCampaign.recallhotline)        
    this.setState({ hotline: event, isdirty:isdirty   })
  }

  changeHandler = colors => {
    if (this.props.parentCampaign.launchstatus !== 'PE') return
    this.setState({ siteColor: colors.color, isdirty:true  });
    if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
  }

  handleChangeFile = (event) => {
    //debugger;

    if(event.target.files[0].type !=='image/jpeg' && event.target.files[0].type !=='image/png')
    {
      toast.info("Please use .png or .jpeg/.jpg file format only", {
        position: toast.POSITION.TOP_RIGHT, autoClose: 5000, hideProgressBar: true  })   
      return;
    }

    this.file = event.target.files[0]
    document.getElementById("logo").src = URL.createObjectURL(event.target.files[0]);

     this.uploadFile();
  }


uploadFile =  async() =>{
//debugger;
const {campaignid} = this.state
    const fileName = 'RRRClientLogo/Event_' + campaignid + '_logo.png' ;
     
		try {
      
      const attachment = this.file 
      ? await s3Upload(this.file,fileName)
      .then(()=>{
        this.setState({isdirty:true,hasLogo:true }) //logoURN: campaignid, 
        if(!this.props.isproductdirty)  this.props.updateDirtyProductFlag(true)
      }
      )
      : null;
		} 
		catch (e) {
      toast.error( e , {position: toast.POSITION.TOP_RIGHT})
			this.setState({ isLoading: false });
    }    
    // console.log(this.state.isdirty)
    //console.log(this.state.file.name);
  }

  handleImgClick = e => {
    if (this.props.parentCampaign.launchstatus !== 'PE') return
    document.getElementById("file").click();
  }

  validateForm()  {
    //debugger;
    const {hasnoresponse, webdetail,webheader} = this.state
    return (
      hasnoresponse ||
      (!hasnoresponse &&
      webheader!= null && webheader.length > 0 &&
      webdetail!= null && webdetail !== '<p><br></p>')
   ); 
 }
 
  filterContent(level,text){
    if(text !== null && text.length>0)
    {
      var contentMatch="";
      this.props.parentCampaign.fwl.map(e => {
        contentMatch += text.match(new RegExp(`\\b${e}\\b`, 'gi'))!== null ? e + ", " : ''
        }
      )
      contentMatch = contentMatch.slice(0, -2);
      switch(level) {
        case 'webheader':
          this.setState({contentMatchHeader:contentMatch})
          break;        
        case 'webdetail':
          this.setState({contentMatchDetail:contentMatch})
          break;
        case 'webfooter':
          this.setState({contentMatchFooter:contentMatch})       
          break; 
        case 'hotline':
          this.setState({contentMatchHotLine:contentMatch})      
          break;  
      }        
      
      if(contentMatch.length>0)
        return false;
    } 
    return true;   
  }

    handleSubmit = async event => {

    event.preventDefault();
    const {hotline,webheader, webdetail,webfooter} = this.state
    var isHLCleared = this.filterContent("hotline",hotline)
    var isFooterCleared = this.filterContent("webfooter",webfooter)
    var isDetailCleared = this.filterContent("webdetail",webdetail)
    var isHeaderCleared = this.filterContent("webheader",webheader)
       

    if(!isHLCleared || !isFooterCleared || !isDetailCleared || !isHeaderCleared)
      return false;

    if(!this.state.isdirty)
    {
      this.props.updateDirtyProductFlag(false);
      if (this.state.hasnoresponse == true)
      {
        this.props.goto6('',true);
      } else
      {
        this.props.goto4('',true);
      }
      return
    }



    let body= {
      "procname" : "procEventSave_v1_3",
      "paramcount": "23", 
      "param1": this.state.campaignid,
      "param2": this.state.clientid,
      "param3" : 0, //campaignname
      "param4" : 0 , //listid 
      "param5": 0,  //inNotificationMessage
      "param6": this.state.webheader, //inWebHeaderMsg
      "param7": webdetail, //inWebDetailedMsg
      "param8": webfooter, //inWebFooterMessage
      "param9": hotline, //inRecallHotline
      "param10": 0, //inRemedyText
      "param11": 0, //inEventDescription
      "param12": 0, //inNotificationSubject
      "param13": this.state.login, //createdby
      "param14": this.state.login, //updatedby
      "param15": 0, //inEmail
      "param16": 0, //converted email body CSS
      "param17": this.state.hasLogo?this.state.campaignid:this.state.logoURN,
      "param18": this.state.siteColor, //inSiteColor   
      "param19": 0, //inSmsMessage 
      "param20": 0, //inAllowMessageType       
      "param21": 0, //inEventTypeCode       
      "param22": 0, //inEventTypeConfirmedBy       
      "param23": 0 //inEventTypeConfirmedOn       
      }

    await getData(body)
    .then(json => {
      if(json.errorMessage === undefined){
        this.props.updateDirtyProductFlag(false);
        this.props.goto4('Campaign Response Info is saved',true);
      }
      else  
        {
          this.setState({ isLoading: false})
          toast.error(json.errorMessage, {position: toast.POSITION.TOP_RIGHT, hideProgressBar: true  })         
        }
        });
    }

    handleResponse = async  (event) => {
      //debugger;

      let noRspFlag = event.target.value==='0' //no response
      this.setState({hasnoresponse:noRspFlag }) 
     
      let body= {
        "procname" : "procEventUpdateNoResponse",
        "paramcount": "3", 
        "param1": this.state.campaignid,
        "param2": noRspFlag ? 1 : 0,
        "param3" : this.state.login
        }

        await getData(body)
        .then(json => {
          if(json.errorMessage !== undefined)
              {
                  this.setState({ isLoading: false})
                  toast.error(json.errorMessage, {position: toast.POSITION.TOP_RIGHT, hideProgressBar: true  })      
              }     
              else if(noRspFlag)
              {
                this.props.updateDirtyProductFlag(false);
                this.props.goto6('No Response Site is configured');
                return
              }
              else 
              {
                this.setState({isdirty:true})
                this.props.updateCampaignStateIdOnly(this.state.campaignid);
                this.props.updateDirtyProductFlag(true)
              }
        });
    }



  render() {
    if(this.props.parentCampaign.campaignid===undefined)
    {
      return (<div>...Campaign is not configured</div>)
    }

    const { classes } = this.props;
    let src='https://' + config.s3.BUCKET + '.s3.us-east-2.amazonaws.com/public/RRRClientLogo/Event_' + this.state.logoURN + '_logo.png'

    return (
      <div  style={{width:"98%"}} >
      <form onSubmit={this.handleSubmit}>     
      <div >
      <FormControl component="fieldset" >
        <FormLabel component="legend" className={classes.formControl}>Do you have a response site to configure?</FormLabel>
        <RadioGroup aria-label="response" name="responseYN" value={this.state.hasnoresponse?'0':'1'} onChange={this.handleResponse}>
          <FormControlLabel value="1" control={<Radio color="black"/>} label="Yes" />
          <FormControlLabel value="0" control={<Radio color="black"/>} label="No" />
        </RadioGroup>
      </FormControl>          

        <div id='second' style={{visibility:this.state.hasnoresponse?'hidden':'visible'}}> 

        <FormLabel component="legend" style={{marginTop:"10px"}} className={classes.formControl}> Primary Branding Color</FormLabel>
        {/* <Tooltip title="Click to pick a color"> */}
        <ColorPicker animation="slide-up" color={this.state.siteColor} enableAlpha={false} mode={'RGB'} onChange={this.changeHandler} >     
        <div><font color={this.state.siteColor}>/\/\/\/\<br/>\/\/\/\/<br/></font></div> 
        </ColorPicker>
        {/* </Tooltip> */}

        <FormLabel component="legend" style={{marginTop:"30px"}} className={classes.formControl}> Logo</FormLabel>
        <Link to = {{pathname: "CampaignNew",  state: {clientid: this.state.clientid,  campaignid:this.state.campaignid}}}>
          <Tooltip title="Click to upload a new logo">
          <img id="logo" width="72" height="72" onClick={this.handleImgClick} src={src}/>
          </Tooltip>
        </Link>
        {/* <Tooltip title="Delete">
          <Delete style={{align:"bottom",  color: "#1782c5",paddingBottom:"3px" }} onClick={()=>{alert('why delete?');}}  /> 
        </Tooltip>         */}
         <input name="fileControl" id="file" type="file" onChange={this.handleChangeFile} style={{display: "none"}} />
      <FormLabel component="legend" />
      <TextField
          id="webheader"
          label="Header"
          //required   // this causes an error "Unable to focus on control" due to hidden input
          value={this.state.webheader}
          onChange={this.handleHeaderChange}
          margin="normal"
          style={{margin:"30px 50% 25px 0", width:"57%"}}
          helperText={this.state.contentMatchHeader.length > 0 ? `Words like ${this.state.contentMatchHeader} are NOT permitted` : ""}
          error={this.state.contentMatchHeader.length > 0}
          variant="outlined"
          placeholder="[response header]"
          InputLabelProps={{shrink: true}}
          />
        <div class="appDT" >
          <Form.Group controlId="webdetail" bsSize="small">
            <Form.Label class="add_section_title">Detail</Form.Label>
            { (this.state.contentMatchDetail.length>0) ?
                <span style={{marginLeft:"30px", width:"70%"}}> Words like <font color="red">{this.state.contentMatchDetail} </font> are NOT permitted</span>  
                : ''
              }              
            <ReactQuill 
                modules={this.modules}
                formats={this.formats}
                value={this.state.webdetail}
                style={{width:"57%"}}
                id = "webdetail"
                bounds={'.appDT'}
                onChange={this.handleDetailChange}
                />
          </Form.Group>
        </div>
        <div class="appFT">
          <Form.Group controlId="webfooter" bsSize="small">
            <Form.Label class="add_section_title">Footer</Form.Label>
            { (this.state.contentMatchFooter.length>0) ?
                <span style={{marginLeft:"30px", width:"70%"}}> Words like <font color="red">{this.state.contentMatchFooter} </font> are NOT permitted</span>  
                : ''
              }               
            <ReactQuill 
                modules={this.modules}
                formats={this.formats}
                value={this.state.webfooter}
                style={{width:"57%"}}
                id = "webfooter"
                bounds={'.appFT'}
                onChange={this.handleFooterChange}
                />
          </Form.Group>
        </div>              
        <div class="appHL">
        <Form.Group controlId="hotline" bsSize="small">
          <Form.Label class="add_section_title">Recall Hotline</Form.Label>
          { (this.state.contentMatchHotLine.length>0) ?
                <span style={{marginLeft:"30px", width:"70%"}}> Words like <font color="red">{this.state.contentMatchHotLine} </font> are NOT permitted</span>  
                : ''
              }            
          <ReactQuill 
              modules={this.modules}
              formats={this.formats}
              value={this.state.hotline}
              style={{width:"57%"}}
              id = "hotline"
              bounds={'.appHL'}
              onChange={this.handleHotlineChange}
              />
        </Form.Group>
      </div>  
      </div> 
      </div>
      <div>
        <LoaderButton
          block
          bsSize="small"
          type="submit"
          isLoading={this.state.isLoading}
          style={{marginLeft:"85%", width:"80px",bottom:"30px",position:"fixed"}}
          text="Next"
          //loadingText="Saving Campaign�"
          disabled={!this.validateForm() || this.props.parentCampaign.launchstatus !== 'PE'}
        />
        </div>        
        </form>        
      </div>
      
    );
  }

}

// CampaignResponse.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(CampaignResponse);